import { makeApiRequest } from "../../config/axiosService";


export const userRegister = async (data) => {
  try {
    // console.log('params for register--', params);
    console.log('data', data)
    let params = {
      url: "v1/registerApi",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const getAllUserRegisterList = async (parentId) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `v1/getRegisterData/${parentId}`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    return {
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const getRegisterDetailsForDashBoard = async () => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `v1/getRegisterDetailsForDashBoard`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    return {
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const findBuyerAddressAndParentId = async (parentIdAndAddress) => {
  try {
    let params = {
      url: `v1/findBuyerAddressAndParentId/${parentIdAndAddress}`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    // console.log('params for register--', response);

    return {
      data: response

    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const linkedIdsFromData = async (parentId) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `v1/linkedIdsFromData/${parentId}`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    return {
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const buyNewLevelApprovalStatus = async (data) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: "v1/buyNewLevelApprovalStatus",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('buyNewLevelOfMLMId error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const buyNewNextLevelOfMLMId = async (data) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: "v1/buyNewNextLevelOfMLMId",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);
    return {
      data: response,
      status: response.status,
      message: response.message
    }

  } catch (error) {
    console.log('buyNewNextLevelOfMLMId error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const getParentID = async (parentId) => {
  try {
    // console.log('parentAddress--', parentId);

    let params = {
      url: `getNewLevelBuyedData/${parentId}`, // Fix the URL construction
      method: "GET",
    }
    let response = await makeApiRequest(params);
    // console.log('getNewLevelBuyedData---', response.status);
    // console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getTheLevelOfCompletedTransactionsDetsils = async (transactionDetails) => {
  try {
    // console.log('parentAddress--', transactionDetails);

    let params = {
      url: `v1/getRegisterDetailsForDashBoard/${transactionDetails}`, // Fix the URL construction
      method: "GET",
    }
    let response = await makeApiRequest(params);
    // console.log('getNewLevelBuyedData---', response.status);
    // console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data,
      result2: response.data2
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}


export const showUserTransactionInDashBoard = async (parentId) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `v1/showUserTransactionInDashBoard`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    // console.log('response----response--', response);
    return {
      status: true,
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const showUserOwnerIdDetails = async (userid) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: `v1/showUserOwnerIdDetails/${userid}`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    // console.log('response----response--', response);
    return {
      data: response
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}

export const RankingAPI = async () => {
  try {
    // console.log('parentAddress--', RankId);

    let params = {
      url: `v1/RankingAPI`, // Fix the URL construction
      method: "GET"
    }
    let response = await makeApiRequest(params);
    // console.log('getNewLevelBuyedData---', response.status);
    // console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data,
      // result2: response.data2
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const RankingData = async (RankId) => {
  try {
    // console.log('parentAddress--', RankId);

    let params = {
      url: `v1/RankingData/${RankId}`, // Fix the URL construction
      method: "GET",
    }
    let response = await makeApiRequest(params);
    // console.log('getNewLevelBuyedData---', response.status);
    // console.log("response",response);
    return {
      status: response.status,
      // message: response.message,
      result: response.data,
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getTheRegistrationDmlmXcoreCompletedTransactionsDetsils = async (transactionDetails) => {
  try {
    // console.log('parentAddress--', transactionDetails);

    let params = {
      url: `v1/getTheRegistrationDmlmXcoreCompletedTransactionsDetsils`, // Fix the URL construction
      method: "POST",
      data: transactionDetails
    }
    let response = await makeApiRequest(params);
    // console.log('getNewLevelBuyedData---', response.status);
    // console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data,
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getTheBuyNextLevelDmlmXcoreCompletedTransactionsDetsils = async (transactionDetails) => {
  try {
    // console.log('parentAddress--', level);

    let params = {
      url: `v1/getTheBuyNextLevelDmlmXcoreCompletedTransactionsDetsils`, // Fix the URL construction
      method: "POST",
      data: transactionDetails
    }
    let response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      result: response.data,
      amount: response.amount
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getTheBuyNextLevelDmlmXcoreCompletedTransactions = async (transactionDetails, level) => {
  try {
    // console.log('parentAddress--', level);

    let params = {
      url: `v1/getTheBuyNextLevelDmlmXcoreCompletedTransactions`, // Fix the URL construction
      method: "POST",
      data: { transactionDetails, level }
    }
    let response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      result: response.data,
      amount: response.amount
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const rewardForRankIncome = async (rewardAmountOfRankIncome) => {
  try {
    // console.log('parentAddress--', parentId);

    let params = {
      url: `v1/getAllwithdrawTransactionDetails/${rewardAmountOfRankIncome}`, // Fix the URL construction
      method: "GET",
    }
    let response = await makeApiRequest(params);
    // console.log('getNewLevelBuyedData---', response.status);
    // console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getsocialmedialinks = async () => {
  try {
   
    let params={
      url: `getlinks`, // Fix the URL construction
      method: "GET",
    }
    let response= await makeApiRequest(params)

    return {
      status:response.status,
      message:response.message,
      result:response.datalinks
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getallpartnerdetails = async (locateUserId) => {
  try {
   
    let params={
      url: `v1/getallpartners`, // Fix the URL construction
      method: "POST",
      data: locateUserId
    }
    let response= await makeApiRequest(params)
     console.log("getallpartner",response);
    return {
      status:response.status,
      message:response.message,
      resultPartner:response.dataPartner,
      resultTeam:response.dataTeam
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      resultPartner: [],
      resultTeam: []
    }
  }
}

export const getloginid= async (useraddress) => {
  try {
   
    let params={
      url: `v1/loginaddress`, // Fix the URL construction
      method: "POST",
      data: useraddress
    }
    let response= await makeApiRequest(params)

    return {
      status:response.status,
      message:response.message,
      userId:response.datafetch,
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      userId:null
    }
  }
}


export const getFaqcontentdetails = async () => {
  try {
   
    let params={
      url: `get-faq`, // Fix the URL construction
      method: "GET",
    }
    let response= await makeApiRequest(params)

    return {
      status:true,
      message:response.message,
      result:response.list
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}


export const UserInfluencer = async (data) => {
  try {
   
    let params={
      url: `v1/InfluencerForm`, // Fix the URL construction
      method: "POST",
      data: data
    }
    let response= await makeApiRequest(params)

    return {
      status:true,
      message:response.message,
      result:response.Data
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}


export const RegcheckWhitelist = async (data) => {
  try {
   
    let params={
      url: `v1/RegcheckWhitelist`, // Fix the URL construction
      method: "POST",
      data: data
    }
    let response= await makeApiRequest(params)

    return {
      status:response.status,
      message:response.message,
      result:response.data
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const Addcareerdata = async (data) => {
  try {
   
    let params={
      url: `A1/Addcareerdata`, // Fix the URL construction
      method: "POST",
      data: data
    }

    let response= await makeApiRequest(params)

    return {
      status:response.status,
      message:response.message,
      result:response.data
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}


export const getAddressCareer = async (Address) => {
  try {
   
    let params={
      url: `A1/findAddresscareer/${Address}`, // Fix the URL construction
      method: "GET",
    }
    let response= await makeApiRequest(params)

    return {
      status:response.status,
      message:response.message,
      result:response.data
    }
  } catch (error) {
    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const regwithUsdt =async(data) =>{
  try {
    let params={
      url: `v1/registerwithusdt`, // Fix the URL construction
      method: "POST",
      data: data
    }
    // console.log('params', params)

    let response= await makeApiRequest(params)

    return {
      status:response.status,
      message:response.message,
      result:response.data
    }
  } catch (error) {
    console.log('regwithUsdt Error:', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getUserReedomCode =async(data) =>{
  try {
    let params={
      url: `v1/getRegisterTk`, 
      method: "POST",
      data:data
    }
    
    let response= await makeApiRequest(params)
    console.log('response make', response)
    return {
      status:response.status,
      message:response.message,
      result:response.data
    }
  } catch (error) {
    console.log('regwithUsdt Error:', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}


export const GenreateCoupon =async(data) =>{
  try {
    let params={
      url: `v1/Generate-code`, 
      method: "POST",
      data:data
    }
    // console.log('params', params)

    let response= await makeApiRequest(params)
    console.log('response===', response)
    return {
      status:response.status,
      message:response.message,
      result:response.data
    }
  } catch (error) {
    console.log('regwithUsdt Error:', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}