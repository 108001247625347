let config = {}

    config = {
        walletConnectText : 'Please connect your wallet',
        walletConnectionError : 'Wallet connection cancelled',
        NetworkChangeToast : 'Change your network to DCXCHAIN',
        AddNetworkSuccessToast : 'Custom network added successfully',
        WalletConnectionError : 'Wallet connection error',
        ReferralIdNotFound : 'User Not Found',
        UserAlreadyRegistered: "User Already Existed",
        UserNotRegistered: "User Not Existed",
        SomeThingWentWorng : "Transaction Rejected",
        SuccessfullyRegistered : 'Successfully Registered',
        RegistrationErrorToast : 'Transaction Failed',
        ConnectAnyOneAccount : 'Connect any one account',
        RegisterToLogin : 'Register To Login',
        SuccessfullyLogedIn : 'Successfully logged in',
        ConnectYourWallet : 'Connect Your Wallet',
        ReferralIdNotFound : "User Not Found",
        CopiedAddress : 'Address copied to clipboard',
        FailedToCopy : 'Failed to copy address to clipboard',
        ContractAddressCopied : 'Contract address Copied',
        RegisterLinkCopiedWithID : 'Link Copied',
        BuyPreviousLevel : "Buy previous level",
        SameUserIdOfWalletAddress: "Only Owner Can Buy",
        NextLevelPurchasedSuccess : " Level N purchased Successfully",
        EnterNumberOnly: "Please enter numbers only.",
        registerInputRequired: "Please enter referral ID",
        UserNotFound: "User Not Found",
        LevelNotBuy: 'Level not buy',
        PleaseInstallMetamask:'Please install metamask',
        FailedMessage:"Sorry, something went wrong"
    }


export default config;
