import "../Assets/Css/dashboard-style.css"
import React, { useState, useEffect } from "react";
import Topnav from "./Separate/Top-nav";
import Sidenav from "./Separate/Side-nav";

import imglogo from "../Assets/images/dusd.png";
import { formatDistanceToNow } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import {
    getAllUserRegisterList, buyNewLevelApprovalStatus,
    buyNewNextLevelOfMLMId, getParentID, linkedIdsFromData, findBuyerAddressAndParentId,
    getTheRegistrationDmlmXcoreCompletedTransactionsDetsils, getTheBuyNextLevelDmlmXcoreCompletedTransactions,
    showUserTransactionInDashBoard
} from '../Dashboard-pages/api/authapis';

import { Link } from 'react-router-dom';

import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import Circleanimation from "../Assets/images/cirlcleloader.gif"
import { Code } from 'react-content-loader'
import Loader from "react-js-loader";
import ModalProcess from "./Modal";

import dusd from "../Assets/images/dusd.png";
import usdt from "../Assets/images/USDT.png";

function Dmlmxcore() {

    const [connectedWallet, setConnectedWallet] = useState(null);

    const [dchainid, setDchainid] = useState(null)


    const [referredByUserId, setReferredByUserId] = useState();

    const [referralAddress, setReferralAddress] = useState();


    const [referralWalletAddress, setReferralWalletAddress] = useState();

    const [firstLoopStageOfIdNumber, setFirstLoopStageOfIdNumber] = useState([]);
    const [firstSeconderyLevelStatus, setFirstSeconderyLevelStatus] = useState([]);

    const [secondLoopStageOfIdNumber, setSecondLoopStageOfIdNumber] = useState([]);
    const [secondSeconderyLevelStatus, setSecondSeconderyLevelStatus] = useState([]);

    const [thirdLoopStageOfIdNumber, setThirdLoopStageOfIdNumber] = useState([]);
    const [thirdSeconderyLevelStatus, setThirdSeconderyLevelStatus] = useState([]);

    const [forthLoopStageOfIdNumber, setForthLoopStageOfIdNumber] = useState([]);
    const [forthSeconderyLevelStatus, setForthSeconderyLevelStatus] = useState([]);

    const [fifthLoopStageOfIdNumber, setFifthLoopStageOfIdNumber] = useState([]);
    const [fifthSeconderyLevelStatus, setFifthSeconderyLevelStatus] = useState([]);

    const [sixLoopStageOfIdNumber, setSixLoopStageOfIdNumber] = useState([]);
    const [sixSeconderyLevelStatus, setSixSeconderyLevelStatus] = useState([]);

    const [sevenLoopStageOfIdNumber, setSevenLoopStageOfIdNumber] = useState([]);
    const [sevenSeconderyLevelStatus, setSevenSeconderyLevelStatus] = useState([]);

    const [eigthLoopStageOfIdNumber, setEigthLoopStageOfIdNumber] = useState([]);
    const [eigthSeconderyLevelStatus, setEigthSeconderyLevelStatus] = useState([]);

    const [nineLoopStageOfIdNumber, setNineLoopStageOfIdNumber] = useState([]);
    const [nineSeconderyLevelStatus, setNineSeconderyLevelStatus] = useState([]);

    const [tenLoopStageOfIdNumber, setTenLoopStageOfIdNumber] = useState([]);
    const [tenSeconderyLevelStatus, setTenSeconderyLevelStatus] = useState([]);


    const [firstLevelStatus, setFirstLevelStatus] = useState(false);
    const [secondLevelStatus, setSecondLevelStatus] = useState(false);
    const [thirdLevelStatus, setThirdLevelStatus] = useState(false);
    const [forthLevelStatus, setForthLevelStatus] = useState(false);
    const [fifthLevelStatus, setFifthLevelStatus] = useState(false);
    const [sixthLevelStatus, setSixthLevelStatus] = useState(false);
    const [sevenLevelStatus, setSevenLevelStatus] = useState(false);
    const [egithLevelStatus, setEigthLevelStatus] = useState(false);
    const [nineLevelStatus, setNineLevelStatus] = useState(false);
    const [tenLevelStatus, setTenLevelStatus] = useState(false);



    const [checkLevelMatching, setCheckLevelMatching] = useState();

    const [partnerFirstLoopLength, setPartnerFirstLoopLength] = useState(0);
    const [partnerSecondLoopLength, setPartnerSecondLoopLength] = useState(0);
    const [partnerThirdLoopLength, setPartnerThirdLoopLength] = useState(0);
    const [partnerForthLoopLength, setPartnerForthLoopLength] = useState(0);
    const [partnerFifthLoopLength, setPartnerFifthLoopLength] = useState(0);
    const [partnerSixthLoopLength, setPartnerSixthLoopLength] = useState(0);
    const [partnerSevenLoopLength, setPartnerSevenLoopLength] = useState(0);
    const [partnerEightLoopLength, setPartnerEightLoopLength] = useState(0);
    const [partnerNineLoopLength, setPartnerNineLoopLength] = useState(0);
    const [partnerTenLoopLength, setPartnerTenLoopLength] = useState(0);

    const [visibleItems, setVisibleItems] = useState(config.REGISTER_HISTORY_SEE_MORE_LESS);

    const [getFirstCycles, setGetFristCycles] = useState(0);
    const [getSecondCycles, setGetSecondCycles] = useState(0);
    const [getThirdCycles, setGetThirdCycles] = useState(0);
    const [getForthCycles, setGetForthCycles] = useState(0);
    const [getFifthCycles, setGetFifthCycles] = useState(0);
    const [getSixthCycles, setGetSixthCycles] = useState(0);
    const [getSeventhCycles, setGetSeventhCycles] = useState(0);
    const [getEightCycles, setGetEightCycles] = useState(0);
    const [getNineCycles, setGetNineCycles] = useState(0);
    const [getTenCycles, setGetTenCycles] = useState(0);

    const [firstDisplayCount, setFirstDisplayCount] = useState(1);
    const [secondDisplayCount, setSecondDisplayCount] = useState(1);
    const [thirdDisplayCount, setThirdDisplayCount] = useState(1);
    const [forthDisplayCount, setForthDisplayCount] = useState(1);
    const [fifthDisplayCount, setFifthDisplayCount] = useState(1);
    const [sixthDisplayCount, setSixthDisplayCount] = useState(1);
    const [seventhDisplayCount, setSeventhDisplayCount] = useState(1);
    const [eigthDisplayCount, setEigthDisplayCount] = useState(1);
    const [ninethDisplayCount, setninethDisplayCount] = useState(1);
    const [tenDisplayCount, settenDisplayCount] = useState(1);

    const [registerTransactionDetails, setRegisterTransactionDetails] = useState([]);
    const [transactionDetailsInTable, setTransactionDetailsInTable] = useState([]);
    const [sumBuyLevelAmount, setSumBuyLevelAmount] = useState(0);
    const [sumRegisterAmount, setSumRegisterAmount] = useState(0);
    const [xCoreAmountFromTheUser, setxCoreAmountFromTheUser] = useState(0);
    const [levelIncomeAmountFromTheUser, setLevelIncomeAmountFromTheUser] = useState(0);
    const [lastTrueIndex, setLastTrueIndex] = useState(0);

    const [firstisloader, setFisrtIsloader] = useState(false);
    const [secondisloader, setSecondIsloader] = useState(false);
    const [threeisloader, setThreeIsloader] = useState(false);
    const [fourisloader, setFourIsloader] = useState(false);
    const [fiveisloader, setFiveIsloader] = useState(false);
    const [sixisloader, setSixIsloader] = useState(false);
    const [sevenisloader, setSevenIsloader] = useState(false);
    const [eigthisloader, setEigthIsloader] = useState(false);
    const [nineisloader, setNineeIsloader] = useState(false);
    const [tenisloader, setTenIsloader] = useState(false);


    const [isbuy2, setIsbuy2] = useState(0);
    const [isbuy3, setIsbuy3] = useState(0);
    const [isbuy4, setIsbuy4] = useState(0);
    const [isbuy5, setIsbuy5] = useState(0);
    const [isbuy6, setIsbuy6] = useState(0);
    const [isbuy7, setIsbuy7] = useState(0);
    const [isbuy8, setIsbuy8] = useState(0);
    const [isbuy9, setIsbuy9] = useState(0);
    const [isbuy10, setIsbuy10] = useState(0);

    const [modalshow, setmodalshow] = useState(false)


    var web3js = '';

    if (window.ethereum) {
        // web3js = new Web3(window.ethereum);

        if (connectedWallet != null && dchainid != config.DCXCHAIN_HEX_ID) {
            web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        } else if (connectedWallet == null) {
            web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        } else {
            web3js = new Web3(window.ethereum);
        }
    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }


    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);
    const xCoreContract = new web3js.eth.Contract(Plan1Abi, config.PLAN_1_CONTRACT_ADDRESS);

    const gasPriceWei = web3js.utils.toWei('4', 'gwei'); // Adjust the gas price as needed

    const locateUserId = window.location.pathname.split('/')[2];

    const checkConnectedWallet = async () => {
        try {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                setConnectedWallet(accounts?.[0] || null);

                const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
                setDchainid(chainId)
            }

        } catch (error) {
            console.error('Error checking connected wallet:', error);
        }
    };

    const getTheUserWalletAddress = async () => {
        try {
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();


            setReferralAddress(checkUserRegisteredExists);

            const checkReferralUserDetails = await DemoCorePlanContract.methods.users(checkUserRegisteredExists).call();

            const checkReferralIDWithAddressUserDetails = await DemoCorePlanContract.methods.users(checkReferralUserDetails.referrer).call();
            const objAddress = await convertBigIntToString(checkReferralIDWithAddressUserDetails);

            setReferredByUserId(objAddress.id);

            const xCoreAmount = await DemoCorePlanContract.methods.userxcoreincome(checkUserRegisteredExists).call();
            const levelIncomeAmount = await xCoreContract.methods.userlevelincome(checkUserRegisteredExists).call();

            const xCorefromWeiAmount = web3js.utils.fromWei(xCoreAmount, 'ether');
            const levelIncomeFromWeiAmount = web3js.utils.fromWei(levelIncomeAmount, 'ether');
            if (xCorefromWeiAmount && levelIncomeFromWeiAmount) {
                setxCoreAmountFromTheUser(xCorefromWeiAmount);
                setLevelIncomeAmountFromTheUser(levelIncomeFromWeiAmount)
            } else {
                setxCoreAmountFromTheUser(0);
                setLevelIncomeAmountFromTheUser(0)
            }
        } catch (error) {
            console.error('Error in getTheUserWalletAddress:', error);
        }
    };


    function isMobileDevice() {
        let check = false;
        (function (a) {
            if (
                "ontouchstart" in window ||
                "onmsgesturechange" in window ||
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);

        return check;
    }

    useEffect(() => {
        checkConnectedWallet();


    }, [locateUserId]);

    const convertBigIntToString = async (obj) => {
        if (typeof obj === 'bigint') {
            return obj.toString();
        } else if (typeof obj === 'object') {
            for (const key in obj) {
                obj[key] = await convertBigIntToString(obj[key]);
            }
        }
        return obj;
    };

    // Call the async function
    getTheUserWalletAddress();

    const getListOfRegisterData = async () => {
        try {
            // let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(connectedWallet);
            const resultOfRegisterData = await getAllUserRegisterList(locateUserId);

            setFisrtIsloader(true)
            setSecondIsloader(true)
            setThreeIsloader(true)
            setFourIsloader(true)
            setFiveIsloader(true)
            setSixIsloader(true)
            setSevenIsloader(true)
            setEigthIsloader(true)
            setNineeIsloader(true)
            setTenIsloader(true)

            const arrayOfArray = [];
            arrayOfArray.push(resultOfRegisterData.data.data)

            for (const registerDataItem of arrayOfArray) {
                if (registerDataItem) {
                    if (registerDataItem.New_User_Referral_Id == locateUserId) {
                        var levelOfData = await checkNextNewLevelStatus();

                        for (let i = 0; i < levelOfData.result.length; i++) {

                            var elements = levelOfData.result[i].Buy_Level_Status;
                            setCheckLevelMatching(elements)
                        }

                        const referralAddressesSet = [];
                        const secondReferralAddressesSet = [];
                        const thirdReferralAddressesSet = [];
                        const forthReferralAddressesSet = [];
                        const fifthReferralAddressesSet = [];
                        const sixthReferralAddressesSet = [];
                        const sevenReferralAddressesSet = [];
                        const eightReferralAddressesSet = [];
                        const nineReferralAddressesSet = [];
                        const tenReferralAddressesSet = [];

                        let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 1).call();
                        setFirstLevelStatus(FirstLevelStatus);
                        if (FirstLevelStatus) {

                            setFisrtIsloader(true)
                            const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 1).call();
                            const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 1, 0).call();
                            let checkTheUserLevel = 3 - firstUserlevelsl.toString();

                            setGetFristCycles(firstUserlevelsl.toString());

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.directreferral(referralAddress).call();
                            for (let i = 0; i < findTheGroupOfReferredID.length; i++) {
                                const element = findTheGroupOfReferredID[i];
                                const checkUserRegisteredExists = await DemoCorePlanContract.methods.users(element).call();

                                referralAddressesSet.push(checkUserRegisteredExists.id.toString());
                            }

                            // referralAddressesSet.forEach(async (element) => {
                            dmlmXcoreTransactionDetailsWithRegisteration(referralAddressesSet);

                            // });
                            setPartnerFirstLoopLength(referralAddressesSet.length);


                            let new1cycle = [...referralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setFirstLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - firstUserlevel2[2].length;

                            var new2cycle = [...referralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setFirstSeconderyLevelStatus(sliceElements);
                            if (referralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points cycle 
                                const groupsOfTwelve = Math.ceil(referralAddressesSet.length / 12);
                                setFirstDisplayCount(groupsOfTwelve);
                            }
                            setFisrtIsloader(false)

                        }

                        let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 2).call();
                        setSecondLevelStatus(TwoLevelStatus);
                        if (TwoLevelStatus) {
                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-2') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        secondReferralAddressesSet.push(elements);
                                    } else {


                                        secondReferralAddressesSet.push(elements);
                                    }
                                }

                            }
                            setPartnerSecondLoopLength(secondReferralAddressesSet.length);
                            // secondReferralAddressesSet.forEach(async (element) => {
                            dmlmXcoreBuyNextLevelTransactionDetails(secondReferralAddressesSet, 'Level-2');

                            // });

                            const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 2).call();
                            const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 2, 0).call();
                            let checkTheUserLevel = 3 - secondUserlevelsl.toString();

                            setGetSecondCycles(secondUserlevelsl.toString());

                            let new1cycle = [...secondReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSecondLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - secondUserlevel2[2].length;

                            var new2cycle = [...secondReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSecondSeconderyLevelStatus(sliceElements);
                            if (secondReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(secondReferralAddressesSet.length / 12);
                                setSecondDisplayCount(groupsOfTwelve);
                            }
                            setSecondIsloader(false)
                        } else if (TwoLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    secondReferralAddressesSet.push(elements);
                                } else {


                                    secondReferralAddressesSet.push(elements);
                                }


                            }

                            if (checkLevelMatching == 'Level-2') {
                                setPartnerSecondLoopLength(secondReferralAddressesSet.length);
                            }
                            const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 2).call();
                            const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 2, 0).call();
                            let checkTheUserLevel = 3 - secondUserlevelsl.toString();
                            setGetSecondCycles(secondUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSecondLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - secondUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSecondSeconderyLevelStatus(sliceElements);
                            setSecondIsloader(false)
                        }
                        else {
                            setSecondIsloader(false)
                        }


                        let ThreeLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 3).call();
                        setThirdLevelStatus(ThreeLevelStatus);
                        if (ThreeLevelStatus) {
                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-3') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;

                                    if (elements) {

                                        thirdReferralAddressesSet.push(elements);
                                    } else {


                                        thirdReferralAddressesSet.push(elements);
                                    }
                                }

                            }

                            setPartnerThirdLoopLength(thirdReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(thirdReferralAddressesSet, 'Level-3');

                            const thirdUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 3).call();
                            const thirdUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 3, 0).call();
                            let checkTheUserLevel = 3 - thirdUserlevelsl.toString();
                            setGetThirdCycles(thirdUserlevelsl.toString());

                            let new1cycle = [...thirdReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setThirdLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - thirdUserlevel2[2].length;

                            var new2cycle = [...thirdReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;


                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setThirdSeconderyLevelStatus(sliceElements);

                            if (thirdReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(thirdReferralAddressesSet.length / 12);
                                setThirdDisplayCount(groupsOfTwelve);
                            }
                            setThreeIsloader(false)

                        } else if (ThreeLevelStatus) {
                            for (let i = 0; i < levelOfData.result.length; i++) {
                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    thirdReferralAddressesSet.push(elements);
                                } else {


                                    thirdReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-3') {
                                setPartnerThirdLoopLength(thirdReferralAddressesSet.length);
                            }
                            const thirdUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 3).call();
                            const thirdUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 3, 0).call();
                            let checkTheUserLevel = 3 - thirdUserlevelsl.toString();
                            setGetThirdCycles(thirdUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setThirdLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - thirdUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setThirdSeconderyLevelStatus(sliceElements);
                            setThreeIsloader(false)
                        }
                        else {
                            setThreeIsloader(false)
                        }


                        let FourLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 4).call();
                        setForthLevelStatus(FourLevelStatus);
                        if (FourLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-4') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {
                                        forthReferralAddressesSet.push(elements);
                                    } else {
                                        forthReferralAddressesSet.push(elements);
                                    }
                                }
                            }
                            setPartnerForthLoopLength(forthReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(forthReferralAddressesSet, 'Level-4');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const forthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 4).call();
                            const forthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 4, 0).call();
                            let checkTheUserLevel = 3 - forthUserlevelsl.toString();
                            setGetForthCycles(forthUserlevelsl.toString());

                            let new1cycle = [...forthReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setForthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - forthUserlevel2[2].length;

                            var new2cycle = [...forthReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setForthSeconderyLevelStatus(sliceElements);

                            if (forthReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(forthReferralAddressesSet.length / 12);
                                setForthDisplayCount(groupsOfTwelve);
                            }
                            setFourIsloader(false)

                        } else if (FourLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {
                                    forthReferralAddressesSet.push(elements);
                                } else {
                                    forthReferralAddressesSet.push(elements);
                                }
                            }
                            if (checkLevelMatching == 'Level-4') {
                                setPartnerForthLoopLength(forthReferralAddressesSet.length);
                            }
                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const forthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 4).call();
                            const forthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 4, 0).call();
                            let checkTheUserLevel = 3 - forthUserlevelsl.toString();
                            setGetForthCycles(forthUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setForthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - forthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setForthSeconderyLevelStatus(sliceElements);
                            setFourIsloader(false)
                        }
                        else {
                            setFourIsloader(false)
                        }


                        let FiveLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 5).call();
                        setFifthLevelStatus(FiveLevelStatus);

                        if (FiveLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();
                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-5') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        fifthReferralAddressesSet.push(elements);
                                    } else {


                                        fifthReferralAddressesSet.push(elements);
                                    }

                                }
                            }

                            setPartnerFifthLoopLength(fifthReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(fifthReferralAddressesSet, 'Level-5');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const fifthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 5).call();
                            const fifthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 5, 0).call();
                            let checkTheUserLevel = 3 - fifthUserlevelsl.toString();
                            setGetFifthCycles(fifthUserlevelsl.toString());

                            let new1cycle = [...fifthReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setFifthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - fifthUserlevel2[2].length;

                            var new2cycle = [...fifthReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setFifthSeconderyLevelStatus(sliceElements);

                            if (fifthReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(fifthReferralAddressesSet.length / 12);
                                setFifthDisplayCount(groupsOfTwelve);
                            }
                            setFiveIsloader(false)

                        } else if (FiveLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    fifthReferralAddressesSet.push(elements);
                                } else {


                                    fifthReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-5') {
                                setPartnerFifthLoopLength(fifthReferralAddressesSet.length);
                            }
                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const fifthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 5).call();
                            const fifthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 5, 0).call();
                            let checkTheUserLevel = 3 - fifthUserlevelsl.toString();
                            setGetFifthCycles(fifthUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setFifthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - fifthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setFifthSeconderyLevelStatus(sliceElements);
                            setFiveIsloader(false)
                        }
                        else {
                            setFiveIsloader(false)
                        }


                        let SixLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 6).call();
                        setSixthLevelStatus(SixLevelStatus);

                        if (SixLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-6') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        sixthReferralAddressesSet.push(elements);
                                    } else {


                                        sixthReferralAddressesSet.push(elements);
                                    }
                                }

                            }

                            setPartnerSixthLoopLength(sixthReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(sixthReferralAddressesSet, 'Level-6');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const sixthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 6).call();
                            const sixthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 6, 0).call();
                            let checkTheUserLevel = 3 - sixthUserlevelsl.toString();
                            setGetSixthCycles(sixthUserlevelsl.toString());

                            let new1cycle = [...sixthReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSixLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - sixthUserlevel2[2].length;

                            var new2cycle = [...sixthReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSixSeconderyLevelStatus(sliceElements);

                            if (sixthReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(sixthReferralAddressesSet.length / 12);
                                setSixthDisplayCount(groupsOfTwelve);
                            }
                            setSixIsloader(false)

                        } else if (SixLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    sixthReferralAddressesSet.push(elements);
                                } else {


                                    sixthReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-6') {
                                setPartnerSixthLoopLength(sixthReferralAddressesSet.length);
                            }
                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const sixthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 6).call();
                            const sixthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 6, 0).call();
                            let checkTheUserLevel = 3 - sixthUserlevelsl.toString();
                            setGetSixthCycles(sixthUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSixLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - sixthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSixSeconderyLevelStatus(sliceElements);
                            setSixIsloader(false)
                        } else {
                            setSixIsloader(false)
                        }


                        let SevenLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 7).call();
                        setSevenLevelStatus(SevenLevelStatus);

                        if (SevenLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-7') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        sevenReferralAddressesSet.push(elements);
                                    } else {


                                        sevenReferralAddressesSet.push(elements);
                                    }
                                }

                            }

                            setPartnerSevenLoopLength(sevenReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(sevenReferralAddressesSet, 'Level-7');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const sevenUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 7).call();
                            const sevenUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 7, 0).call();
                            let checkTheUserLevel = 3 - sevenUserlevelsl.toString();
                            setGetSeventhCycles(sevenUserlevelsl.toString());

                            let new1cycle = [...sevenReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSevenLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - sevenUserlevel2[2].length;

                            var new2cycle = [...sevenReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSevenSeconderyLevelStatus(sliceElements);

                            if (sevenReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(sevenReferralAddressesSet.length / 12);
                                setSeventhDisplayCount(groupsOfTwelve);
                            }
                            setSevenIsloader(false)

                        } else if (SevenLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    sevenReferralAddressesSet.push(elements);
                                } else {


                                    sevenReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-7') {
                                setPartnerSevenLoopLength(sevenReferralAddressesSet.length);
                            }
                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const sevenUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 7).call();
                            const sevenUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 7, 0).call();
                            let checkTheUserLevel = 3 - sevenUserlevelsl.toString();
                            setGetSeventhCycles(sevenUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSevenLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - sevenUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSevenSeconderyLevelStatus(sliceElements);
                            setSevenIsloader(false)
                        } else {
                            setSevenIsloader(false)
                        }

                        let EightLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 8).call();
                        setEigthLevelStatus(EightLevelStatus);

                        if (EightLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-8') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        eightReferralAddressesSet.push(elements);
                                    } else {


                                        eightReferralAddressesSet.push(elements);
                                    }

                                }
                            }

                            setPartnerEightLoopLength(eightReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(eightReferralAddressesSet, 'Level-8');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const eigthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 8).call();
                            const eigthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 8, 0).call();
                            let checkTheUserLevel = 3 - eigthUserlevelsl.toString();
                            setGetEightCycles(eigthUserlevelsl.toString());
                            let new1cycle = [...eightReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setEigthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - eigthUserlevel2[2].length;

                            var new2cycle = [...eightReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setEigthSeconderyLevelStatus(sliceElements);

                            if (eightReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(eightReferralAddressesSet.length / 12);
                                setEigthDisplayCount(groupsOfTwelve);
                            }
                            setEigthIsloader(false)

                        } else if (EightLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    eightReferralAddressesSet.push(elements);
                                } else {


                                    eightReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-8') {

                                setPartnerEightLoopLength(eightReferralAddressesSet.length);
                            }

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const eigthUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 8).call();
                            const eigthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 8, 0).call();
                            let checkTheUserLevel = 3 - eigthUserlevelsl.toString();

                            setGetEightCycles(eigthUserlevelsl.toString());
                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setEigthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - eigthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setEigthSeconderyLevelStatus(sliceElements);
                            setEigthIsloader(false)
                        } else {
                            setEigthIsloader(false)
                        }


                        let NineLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 9).call();
                        setNineLevelStatus(NineLevelStatus);

                        if (NineLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();
                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-9') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        nineReferralAddressesSet.push(elements);
                                    } else {


                                        nineReferralAddressesSet.push(elements);
                                    }

                                }
                            }

                            setPartnerNineLoopLength(nineReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(nineReferralAddressesSet, 'Level-9');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const nineUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 9).call();
                            const nineUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 9, 0).call();
                            let checkTheUserLevel = 3 - nineUserlevelsl.toString();
                            setGetNineCycles(nineUserlevelsl.toString());

                            let new1cycle = [...nineReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setNineLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - nineUserlevel2[2].length;

                            var new2cycle = [...nineReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setNineSeconderyLevelStatus(sliceElements);

                            if (nineReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(nineReferralAddressesSet.length / 12);
                                setninethDisplayCount(groupsOfTwelve);
                            }
                            setNineeIsloader(false)

                        } else if (NineLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    nineReferralAddressesSet.push(elements);
                                } else {


                                    nineReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-9') {
                                setPartnerNineLoopLength(nineReferralAddressesSet.length);
                            }
                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const nineUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 9).call();
                            const nineUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 9, 0).call();
                            let checkTheUserLevel = 3 - nineUserlevelsl.toString();
                            setGetNineCycles(nineUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setNineLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - nineUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setNineSeconderyLevelStatus(sliceElements);
                            setNineeIsloader(false)
                        } else {
                            setNineeIsloader(false)
                        }

                        let TenLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 10).call();
                        setTenLevelStatus(TenLevelStatus);

                        if (TenLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {
                                if (levelOfData.result[i].Buy_Level_Status == 'Level-10') {
                                    var elements = levelOfData.result[i].Owner_MLM_ID;
                                    if (elements) {

                                        tenReferralAddressesSet.push(elements);
                                    } else {


                                        tenReferralAddressesSet.push(elements);
                                    }
                                }

                            }

                            setPartnerTenLoopLength(tenReferralAddressesSet.length);
                            dmlmXcoreBuyNextLevelTransactionDetails(tenReferralAddressesSet, 'Level-10');

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const tenUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 10).call();
                            const tenUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 10, 0).call();
                            let checkTheUserLevel = 3 - tenUserlevelsl.toString();
                            setGetTenCycles(tenUserlevelsl.toString());

                            let new1cycle = [...tenReferralAddressesSet];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setTenLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - tenUserlevel2[2].length;

                            var new2cycle = [...tenReferralAddressesSet];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setTenSeconderyLevelStatus(sliceElements);

                            if (tenReferralAddressesSet.length > 0) {

                                // Calculate the number of groups of 12 data points
                                const groupsOfTwelve = Math.ceil(tenReferralAddressesSet.length / 12);
                                settenDisplayCount(groupsOfTwelve);
                            }
                            setTenIsloader(false)

                        } else if (TenLevelStatus) {

                            var levelOfData = await checkNextNewLevelStatus();

                            for (let i = 0; i < levelOfData.result.length; i++) {

                                var elements = levelOfData.result[i].Owner_MLM_ID;
                                if (elements) {

                                    tenReferralAddressesSet.push(elements);
                                } else {


                                    tenReferralAddressesSet.push(elements);
                                }


                            }
                            if (checkLevelMatching == 'Level-10') {
                                setPartnerTenLoopLength(tenReferralAddressesSet.length);
                            }
                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                            const tenUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, 10).call();
                            const tenUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, 10, 0).call();
                            let checkTheUserLevel = 3 - tenUserlevelsl.toString();
                            setGetTenCycles(tenUserlevelsl.toString());

                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setTenLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - tenUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setTenSeconderyLevelStatus(sliceElements);
                            setTenIsloader(false)
                        } else {
                            setTenIsloader(false)
                        }


                    }
                } else if (registerDataItem == undefined) {


                    if (locateUserId) {
                        let idToAddressChecker = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
                        const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                        const referralAddress = findTheGroupOfReferredID.referrer

                        // const referralAddress = registerDataItem.New_User_Registered_Address.trim(); // Trim the address

                        // const findTheGroupOfReferredAddress = await DemoCorePlanContract.methods.getindirectref(referralAddress).call();
                        // if (referralAddress) {

                        let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 1).call();
                        setFirstLevelStatus(FirstLevelStatus);
                        if (FirstLevelStatus) {


                            const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 1).call();
                            const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 1, 0).call();
                            let checkTheUserLevel = 3 - firstUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setFirstLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - firstUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setFirstSeconderyLevelStatus(sliceElements);

                        }
                        let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 2).call();
                        setSecondLevelStatus(TwoLevelStatus);
                        if (TwoLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 2).call();
                            const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 2, 0).call();
                            let checkTheUserLevel = 3 - secondUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSecondLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - secondUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSecondSeconderyLevelStatus(sliceElements);

                        }

                        let ThreeLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 3).call();
                        setThirdLevelStatus(ThreeLevelStatus);
                        if (ThreeLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const thirdUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 3).call();
                            const thirdUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 3, 0).call();
                            let checkTheUserLevel = 3 - thirdUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setThirdLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - thirdUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setThirdSeconderyLevelStatus(sliceElements);

                        }

                        let FourLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 4).call();
                        setForthLevelStatus(FourLevelStatus);
                        if (FourLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const forthUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 4).call();
                            const forthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 4, 0).call();
                            let checkTheUserLevel = 3 - forthUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setForthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - forthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setForthSeconderyLevelStatus(sliceElements);

                        }

                        let FiveLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 5).call();
                        setFifthLevelStatus(FiveLevelStatus);

                        if (FiveLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const fifthUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 5).call();
                            const fifthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 5, 0).call();
                            let checkTheUserLevel = 3 - fifthUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setFifthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - fifthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setFifthSeconderyLevelStatus(sliceElements);

                        }

                        let SixLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 6).call();
                        setSixthLevelStatus(SixLevelStatus);

                        if (SixLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const sixthUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 6).call();
                            const sixthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 6, 0).call();
                            let checkTheUserLevel = 3 - sixthUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSixLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - sixthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSixSeconderyLevelStatus(sliceElements);

                        }

                        let SevenLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 7).call();
                        setSevenLevelStatus(SevenLevelStatus);

                        if (SevenLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const sevenUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 7).call();
                            const sevenUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 7, 0).call();
                            let checkTheUserLevel = 3 - sevenUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setSevenLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - sevenUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setSevenSeconderyLevelStatus(sliceElements);

                        }

                        let EightLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 8).call();
                        setEigthLevelStatus(EightLevelStatus);

                        if (EightLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const eigthUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 8).call();
                            const eigthUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 8, 0).call();
                            let checkTheUserLevel = 3 - eigthUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setEigthLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - eigthUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setEigthSeconderyLevelStatus(sliceElements);

                        }

                        let NineLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 9).call();
                        setNineLevelStatus(NineLevelStatus);

                        if (NineLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const nineUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 9).call();
                            const nineUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 9, 0).call();
                            let checkTheUserLevel = 3 - nineUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setNineLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - nineUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setNineSeconderyLevelStatus(sliceElements);

                        }

                        let TenLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(idToAddressChecker, 10).call();
                        setTenLevelStatus(TenLevelStatus);

                        if (TenLevelStatus) {

                            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
                            const tenUserlevelsl = await DemoCorePlanContract.methods.getcycles(idToAddressChecker, 10).call();
                            const tenUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(idToAddressChecker, 10, 0).call();
                            let checkTheUserLevel = 3 - tenUserlevelsl.toString();


                            let new1cycle = [];

                            for (let i = 1; i < checkTheUserLevel; i++) {
                                new1cycle.push(0)
                            }

                            while (new1cycle.length < 3) {
                                new1cycle.push(0);
                            }

                            const firstThreeElements = new1cycle.slice(0, 3);
                            setTenLoopStageOfIdNumber(firstThreeElements);

                            let userlen2 = 9 - tenUserlevel2[2].length;

                            var new2cycle = [];
                            for (let i = 4; i < userlen2; i++) {

                                new2cycle.push(0)
                            }

                            while (new2cycle.length < 9) {
                                new2cycle.push(0);
                            }

                            const sliceElements = new2cycle.slice(3, 12);
                            if (sliceElements.length !== 9) {
                                const elementsToAdd = 9 - sliceElements.length;

                                for (let i = 0; i < elementsToAdd; i++) {
                                    sliceElements.push(0);
                                }
                            }

                            setTenSeconderyLevelStatus(sliceElements);

                        }

                    }
                }

            }

        } catch (error) {
            console.error('Error fetching register data:', error);
        }

    };




    const checkNextNewLevelStatus = async () => {
        const nextLeveData = await getParentID(locateUserId);
        // const buyAmount = web3js.utils.toWei('1', 'ether');
        // const resultOfTransfer = await DUSD_Contract_address.methods.transfer('0xbfA944a0529c4849933a8D7F2A675f91B7856686', buyAmount).send({
        //     from: '0x4E3DDD4557b9e85FE50F702A098C57592F456D99',
        //     gasPrice: gasPriceWei,
        //     // gas: 21000
        // });
        return nextLeveData;

    }


    const genratelevel = (level) => {
        try {
            const toastmsg = ToastApi.NextLevelPurchasedSuccess
            const modifylel = toastmsg.replace("N", level)

            return modifylel;
        } catch (error) {

        }
    }



    const CheckBuylevel = (lev) => {

        try {
            switch (lev) {
                case 2:
                    setIsbuy2(2)
                    break;
                case 3:
                    setIsbuy3(3)
                    break;
                case 4:
                    setIsbuy4(4)
                    break;
                case 5:
                    setIsbuy5(5)
                    break;
                case 6:
                    setIsbuy6(6)
                    break;
                case 7:
                    setIsbuy7(7)
                    break;
                case 8:
                    setIsbuy8(8)
                    break;
                case 9:
                    setIsbuy9(9)
                    break;
                case 10:
                    setIsbuy10(10)
                    break;
                default:
                    console.log("level is not here!");
            }
        } catch (error) {

        }
    }

    function Buybuttonreset() {
        try {
            setIsbuy2(0)
            setIsbuy3(0)
            setIsbuy4(0)
            setIsbuy5(0)
            setIsbuy6(0)
            setIsbuy7(0)
            setIsbuy8(0)
            setIsbuy9(0)
            setIsbuy10(0)
        } catch (error) {
            setIsbuy2(0)
            setIsbuy3(0)
            setIsbuy4(0)
            setIsbuy5(0)
            setIsbuy6(0)
            setIsbuy7(0)
            setIsbuy8(0)
            setIsbuy9(0)
            setIsbuy10(0)
        }

    }

    const buyNewLevelOfMLM = async (userId, levels, amount) => {

        CheckBuylevel(levels)

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        if (chainId !== config.DCXCHAIN_HEX_ID) {
            await addCustomNetwork();
            Buybuttonreset();
            return
        }

        if (!connectedWallet) {
            // Show a toast when the wallet is not connected
            toast.error(ToastApi.walletConnectText);
            Buybuttonreset();
            return;
        }

        if (connectedWallet) {
            const resultOfRegisterData = await showUserTransactionInDashBoard();

            if (resultOfRegisterData.status === false) {
                toast.error("Something went wrong! please try again later");
                Buybuttonreset();
                return;
            }
        }

        try {
            let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(connectedWallet);
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();


            let checkFirstLevel = await DemoCorePlanContract.methods.usercompletelevel(checkSumMetamaskAddress, levels).call();

            const checkUplineIds = await DemoCorePlanContract.methods.finduplineReferrer(checkSumMetamaskAddress, levels).call();
            let lastlevel = levels - 1;



            let checkOwnerPreviousLevelOpenOrClose = await DemoCorePlanContract.methods.usercompletelevel(checkSumMetamaskAddress, lastlevel).call();

            if (checkUserRegisteredExists !== checkSumMetamaskAddress) {
                Buybuttonreset()
                toast.warning(ToastApi.SameUserIdOfWalletAddress);
                return;
            } else {

                if (checkOwnerPreviousLevelOpenOrClose == false) {
                    Buybuttonreset()
                    toast.error(ToastApi.BuyPreviousLevel);
                    return;
                } else {
                    // DUSD_Contract_address
                    if (checkFirstLevel == false) {

                        if (checkUplineIds) {
                            const findThePreviourUplineReferredAddress = await DemoCorePlanContract.methods.users(checkUplineIds).call();
                            let parentId = findThePreviourUplineReferredAddress.id;
                            let parentAddress = findThePreviourUplineReferredAddress.referrer;

                            const gasPriceWei = web3js.utils.toWei('4', 'gwei'); // Adjust the gas price as needed 


                            const allowance = await DUSD_Contract_address.methods.allowance(checkSumMetamaskAddress, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS).call();
                            let buyAmount = await web3js.utils.toWei(amount, "ether");
                            let allowancefromwei = await web3js.utils.fromWei(allowance, 'ether')
                            const approvedAmountString = allowance.toString();
                            setmodalshow(true)

                            if (Number(allowancefromwei) >= amount) {
                                const buyLevelsContract = await DemoCorePlanContract.methods.buyNewLevelFor(checkSumMetamaskAddress, levels).send({
                                    from: checkSumMetamaskAddress,
                                    gasPrice: gasPriceWei
                                });

                                const sendingAsJson = {
                                    buyLevelsContract,
                                    levels,
                                    locateUserId,
                                    buyAmount,
                                    parentId,
                                    parentAddress
                                }

                                const buyNewLevelWithoutApproval = await buyNewNextLevelOfMLMId(sendingAsJson);

                                if (buyNewLevelWithoutApproval.data.status === true) {
                                    if (sendingAsJson.buyLevelsContract.status === '1') {
                                        const ToastLevelMsg = await genratelevel(sendingAsJson.levels)
                                        toast(ToastLevelMsg)
                                        setmodalshow(false)
                                        // const intervalId = setInterval(() => {
                                        //     window.location.href = '';
                                        // }, 5000);
                                        Buybuttonreset()
                                        setTimeout(() => {
                                            window.location.href = '';
                                        }, 2000); // 30,000 milliseconds = 30 seconds
                                    }
                                } else {
                                    toast.error("level not saved")
                                    setmodalshow(false)
                                }

                            } else if (Number(allowancefromwei) < amount) {
                                const resultOfApprove = await DUSD_Contract_address.methods.approve(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS, buyAmount).send({
                                    from: checkSumMetamaskAddress,
                                    gasPrice: gasPriceWei
                                });

                                const sendingAsJson = {
                                    resultOfApprove,
                                    levels,
                                    buyAmount
                                };


                                const buyNewLevelDataSave = await buyNewLevelApprovalStatus(sendingAsJson);

                                if (resultOfApprove.transactionHash && resultOfApprove.status) {
                                    const buyLevelsContract = await DemoCorePlanContract.methods.buyNewLevelFor(checkSumMetamaskAddress, levels).send({
                                        from: checkSumMetamaskAddress,
                                        gasPrice: gasPriceWei
                                    });

                                    const sendNextLevelResponse = {
                                        buyLevelsContract,
                                        levels,
                                        locateUserId,
                                        buyAmount,
                                        parentId,
                                        parentAddress
                                    }

                                    const buyNewLevelWithoutApproval = await buyNewNextLevelOfMLMId(sendNextLevelResponse);

                                    if (buyNewLevelWithoutApproval.data.status === true) {
                                        if (sendNextLevelResponse.buyLevelsContract.status === '1') {
                                            const ToastLevelMsg = await genratelevel(sendingAsJson.levels)
                                            toast(ToastLevelMsg)
                                            setmodalshow(false)
                                            // const intervalId = setInterval(() => {
                                            //     window.location.href = '';
                                            // }, 5000);
                                            Buybuttonreset()
                                            setTimeout(() => {
                                                window.location.href = '';
                                            }, 2000); // 30,000 milliseconds = 30 seconds
                                        }
                                    }
                                    else {
                                        toast.error("level not saved")
                                        setmodalshow(false)
                                    }


                                }

                            }
                        } else {
                            const resultOfRegisterData = await findBuyerAddressAndParentId(checkSumMetamaskAddress);

                            let parentId = resultOfRegisterData.data.data.Referral_ID;
                            let parentAddress = resultOfRegisterData.data.data.Referral_Address

                            const gasPriceWei = web3js.utils.toWei('20', 'gwei'); // Adjust the gas price as needed
                            const allowance = await DUSD_Contract_address.methods.allowance(checkSumMetamaskAddress, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS).call();
                            let buyAmount = await web3js.utils.toWei(amount, "ether");
                            let allowancefromwei = await web3js.utils.fromWei(allowance, 'ether')

                            const approvedAmountString = allowance.toString();
                            setmodalshow(true)
                            if (Number(allowancefromwei) >= amount) {
                                const buyLevelsContract = await DemoCorePlanContract.methods.buyNewLevelFor(checkSumMetamaskAddress, levels).send({
                                    from: checkSumMetamaskAddress,
                                    gasPrice: gasPriceWei
                                });
                                const sendingAsJson = {
                                    buyLevelsContract,
                                    levels,
                                    locateUserId,
                                    buyAmount,
                                    parentId,
                                    parentAddress
                                }

                                const buyNewLevelWithoutApproval = await buyNewNextLevelOfMLMId(sendingAsJson);

                                if (buyNewLevelWithoutApproval.data.status === true) {
                                    if (sendingAsJson.buyLevelsContract.status === '1') {
                                        const ToastLevelMsg = await genratelevel(sendingAsJson.levels)
                                        toast(ToastLevelMsg)
                                        setmodalshow(false)
                                        const intervalId = setInterval(() => {
                                            window.location.href = '';
                                        }, 5000);
                                        Buybuttonreset()
                                        setTimeout(() => {
                                            clearInterval(intervalId);
                                        }, 30000); // 30,000 milliseconds = 30 seconds
                                    }
                                } else {
                                    toast.error("level not saved")
                                    setmodalshow(false)
                                }

                            } else if (Number(allowancefromwei) < amount) {
                                const resultOfApprove = await DUSD_Contract_address.methods.approve(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS, buyAmount).send({
                                    from: checkSumMetamaskAddress,
                                    gasPrice: gasPriceWei
                                });

                                const sendingAsJson = {
                                    resultOfApprove,
                                    levels,
                                    buyAmount
                                };


                                const buyNewLevelDataSave = await buyNewLevelApprovalStatus(sendingAsJson);

                                if (resultOfApprove.transactionHash && resultOfApprove.status) {
                                    const buyLevelsContract = await DemoCorePlanContract.methods.buyNewLevelFor(checkSumMetamaskAddress, levels).send({
                                        from: checkSumMetamaskAddress,
                                        gasPrice: gasPriceWei
                                    });
                                    const sendNextLevelResponse = {
                                        buyLevelsContract,
                                        levels,
                                        locateUserId,
                                        buyAmount,
                                        parentId,
                                        parentAddress
                                    }

                                    const buyNewLevelWithoutApproval = await buyNewNextLevelOfMLMId(sendNextLevelResponse);

                                    if (buyNewLevelWithoutApproval.data.status === true) {
                                        if (sendNextLevelResponse.buyLevelsContract.status === '1') {
                                            const ToastLevelMsg = await genratelevel(sendingAsJson.levels)
                                            toast(ToastLevelMsg)
                                            setmodalshow(false)
                                            const intervalId = setInterval(() => {
                                                window.location.href = '';
                                            }, 5000);
                                            Buybuttonreset()
                                            setTimeout(() => {
                                                clearInterval(intervalId);
                                            }, 30000); // 30,000 milliseconds = 30 seconds
                                        }
                                    } else {
                                        toast.error("level not saved")
                                        setmodalshow(false)
                                    }


                                }

                            }
                        }
                    }

                }
            }

        } catch (error) {
            Buybuttonreset()
            setmodalshow(false)
        }

    }



    const dmlmXcoreTransactionDetailsWithRegisteration = async (referralAddressesSet) => {
        const showTheTransactionDetails = await getTheRegistrationDmlmXcoreCompletedTransactionsDetsils(referralAddressesSet);

        const assignValue = [];
        showTheTransactionDetails.result.forEach(element => {
            const objectValue = {};
            var createdDate = new Date(element.Create_At);

            // Format date
            var formattedDate = createdDate.getDate() + " " +
                createdDate.toLocaleString('default', { month: 'short' }) + " " +
                createdDate.getFullYear();

            objectValue['userIds'] = element.New_User_Referral_Id;
            objectValue['tx_Hash'] = element.tx_Hash;
            objectValue['create_At'] = formattedDate; // Include the formatted date
            objectValue['userLevel'] = "Level-1";
            objectValue['amount'] = element.X_Core_Amount;
            objectValue['walletAddress'] = element.New_User_Registered_Address;

            assignValue.push(objectValue);
        });


        setTransactionDetailsInTable(prevState => [...assignValue, ...prevState]);
    };


    const dmlmXcoreBuyNextLevelTransactionDetails = async (ids, level) => {
        if (ids.length > 0) {
            let idsArray = [].concat(...ids);
            let uniqueIdsArray = [...new Set(idsArray)]; // Convert to Set to remove duplicates, then back to array

            const showTheTransactionDetails = await getTheBuyNextLevelDmlmXcoreCompletedTransactions(uniqueIdsArray, level);
            const assignValue = [];

            const seenIds = new Set();

            if (Array.isArray(showTheTransactionDetails.result)) {
                const promises = showTheTransactionDetails.result.map(async (element) => {
                    if (seenIds.has(element._id)) {
                        return;
                    }
                    seenIds.add(element._id); // Add ID to seenIds set

                    const objectValue = {};
                    let purchasedAmount = web3js.utils.fromWei(element.Level_Purchased_Amount, "ether");
                    var createdDate = new Date(element.Create_at);
                    var formattedDate = createdDate.getDate() + " " +
                        createdDate.toLocaleString('default', { month: 'short' }) + " " +
                        createdDate.getFullYear();
                    objectValue['id'] = element._id;
                    objectValue['userIds'] = element.Owner_MLM_ID;
                    objectValue['tx_Hash'] = element.Next_Level_Txhah;
                    objectValue['create_At'] = formattedDate;
                    objectValue['userLevel'] = element.Buy_Level_Status;
                    objectValue['walletAddress'] = element.Owner_From_Address;
                    objectValue['amount'] = parseFloat(element.X_Core_Amount);

                    assignValue.push(objectValue);
                });

                await Promise.all(promises);

            } else {
                // console.error('showTheTransactionDetails.result is not an array:', showTheTransactionDetails.result);
            }


            setTransactionDetailsInTable(prevState => [...prevState, ...assignValue]);
            return;
        }
    };

    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';

            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);

            return `${prefix}...${suffix}`;
        } catch (error) {

        }
    };

    const handleSeeMoreClick = () => {
        // Increase the number of visible items (you can adjust the step as needed)
        setVisibleItems((prevVisibleItems) => prevVisibleItems + config.REGISTER_HISTORY_SEE_MORE_LESS);
    };

    const handleSeeLessClick = () => {
        // Decrease the number of visible items (you can adjust the step as needed)
        setVisibleItems((prevVisibleItems) => Math.max(prevVisibleItems - config.REGISTER_HISTORY_SEE_MORE_LESS, config.REGISTER_HISTORY_SEE_MORE_LESS));
    };

    useEffect(() => {
        getListOfRegisterData();
        checkNextNewLevelStatus();


    }, [referredByUserId, connectedWallet, checkLevelMatching])


    const invitedIdRedirect = async (invitedId) => {
        try {

            window.location.href = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + `${invitedId}`;
        } catch (error) {

        }
    };

    let totalAmount = 0;


    useEffect(() => {
        async function checkLevels() {
            for (let index = 0; index <= 10; index++) {
                try {
                    const referralAdd = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
                    const checkval = await DemoCorePlanContract.methods.usercompletelevel(referralAdd, index).call();

                    if (checkval === true) {
                        setLastTrueIndex(index);
                    }
                } catch (error) {
                    console.log("error", error);
                }
            }
        }

        checkLevels();
    }, []);

    const addCustomNetwork = async () => {
        try {
            await window.ethereum.request({
                method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
                params: [{ chainId: config.DCXCHAIN_HEX_ID }]
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
        } catch (switchError) {
            if (isMobileDevice()) {
                if (switchError.data.originalError.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
                            params: [
                                {
                                    chainId: config.DCXCHAIN_HEX_ID,
                                    chainName: config.DCX_NETWORK_NAME,
                                    nativeCurrency: {
                                        name: config.DCX_NETWORK_NAME,
                                        symbol: config.DCX_SYMBOL,
                                        decimals: config.DCX_DECIMAL,
                                    },
                                    rpcUrls: [config.DCXRPC_URL],
                                    blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                                },
                            ],
                        });
                        toast.success(ToastApi.AddNetworkSuccessToast);
                    } catch (addError) {
                        console.log("error", addError);
                    }
                }
            }
            else {
                if (switchError.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
                            params: [
                                {
                                    chainId: config.DCXCHAIN_HEX_ID,
                                    chainName: config.DCX_NETWORK_NAME,
                                    nativeCurrency: {
                                        name: config.DCX_NETWORK_NAME,
                                        symbol: config.DCX_SYMBOL,
                                        decimals: config.DCX_DECIMAL,
                                    },
                                    rpcUrls: [config.DCXRPC_URL],
                                    blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                                },
                            ],
                        });
                        toast.success(ToastApi.AddNetworkSuccessToast);
                    } catch (addError) {
                        console.log("error", addError);
                    }
                }
            }
        }
    };


    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav userId={locateUserId} />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <p className="my-3 dashboard-text-6">
                                        <a className="" href={`${config.WINDOW_LOCATION_DASHBOARD_PAGE}/${locateUserId}`}>
                                            <span className="mb-0 dashboard-${config.WINDOW_LOCATION_LEVEL}text-4" style={{ color: "#daa520e9", textDecoration: "underline", marginRight: "5px" }} >ID {locateUserId} </span>
                                        </a> /
                                        PropelX xCore
                                        ({lastTrueIndex} out of 10 levels)</p>
                                    <div className="d-flex flex-lg-row flex-column">
                                        <div>
                                            <p className="dashboard-text-2 mb-0">PropelX xCore</p>
                                        </div>
                                        {/* {transactionDetailsInTable.length === 0 ? (
                                            <div className="ms-lg-auto">
                                                <p className="dashboard-text-2 mb-0">0 DUSD</p>
                                            </div>
                                        ) : (

                                            transactionDetailsInTable.length > 0 &&
                                            (() => {
                                                let totalAmount = 0;
                                                transactionDetailsInTable
                                                    .filter((item, index, self) =>
                                                        self.findIndex(t => t.userIds === item.userIds && t.userLevel === item.userLevel) === index
                                                    )
                                                    .forEach(item => {
                                                        totalAmount += item.amount;
                                                    });

                                                return ( */}
                                        <div className="ms-lg-auto">
                                            <p className="dashboard-text-2 mb-0">{xCoreAmountFromTheUser} DUSD</p>

                                        </div>
                                        {/* );
                                            })()

                                        )} */}



                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="card dashboard-x3-card-1 position-relative overflow-hidden">
                                        <div className="dashboard-user-programs-glow-2"></div>
                                        <div className="d-flex flex-row flex-wrap gap-3 justify-content-center justify-content-lg-center">
                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">
                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 1
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">15</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        firstisloader
                                                            ? (
                                                                // <div className="spin-parent">
                                                                //     <div class="spinner-border text-warning" role="status">
                                                                //         <span class="sr-only">Loading...</span>
                                                                //     </div>
                                                                // </div>                                                          
                                                                <Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                            : (
                                                                <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${1}`}>
                                                                    <a className="" onClick={() => { buyNewLevelOfMLM(locateUserId, 1, 15) }}>

                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {firstLoopStageOfIdNumber.length > 0 && firstLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>



                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {firstSeconderyLevelStatus.length >= 0 && firstSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerFirstLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {firstDisplayCount}
                                                                            </span>
                                                                        </div>


                                                                    </a>
                                                                </a>
                                                            )
                                                    }

                                                    {/* </>
                                                    ) : (
                                                        <>

                                                            <div class="x-core-buy-btn">
                                                                <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 1, 15) }}>Buy</button>
                                                            </div>
                                                        </>
                                                    )} */}
                                                </div>
                                            </div>



                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">
                                                    {/* href="dmlmxcoreinner" */}


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 2
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">30</span>
                                                        </div>
                                                    </div>
                                                    {secondisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            secondLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${2}`}>
                                                                        <div className="xcore-total-grid-overrall">
                                                                            <div className="xcore-total-grid-2">
                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {secondLoopStageOfIdNumber.length > 0 ? (
                                                                                        secondLoopStageOfIdNumber.map((item, index) => (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={`dashboard-user-xcore-all-level-2-programs ${item !== 0 ? "dashboard-user-xcore-programs-active" : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {item === 0 ? "" : parseInt(item)}
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        // Render empty circle images when the array is empty
                                                                                        <div className="empty-circle-images"> </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {secondSeconderyLevelStatus.length >= 0 &&
                                                                                        secondSeconderyLevelStatus.map((item, index) => (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={`dashboard-user-xcore-all-level-programs ${item !== 0 ? "dashboard-user-xcore-programs-active" : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {item === 0 ? "" : parseInt(item)}
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">{partnerSecondLoopLength}</span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">{secondDisplayCount}</span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy2 == 2) ?
                                                                            <button className="dashboard-preview-btn-2"  >
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 2, 30) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }

                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 3
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">100</span>
                                                        </div>
                                                    </div>
                                                    {threeisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            thirdLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${3}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {thirdLoopStageOfIdNumber.length >= 0 && thirdLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {thirdSeconderyLevelStatus.length >= 0 && thirdSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerThirdLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {thirdDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>

                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy3 == 3) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 3, 100) }}>Buy</button>}
                                                                    </div>
                                                                </>
                                                            ))
                                                    }


                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 4
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">200</span>
                                                        </div>
                                                    </div>

                                                    {fourisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            forthLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${4}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {forthLoopStageOfIdNumber.length > 0 && forthLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {forthSeconderyLevelStatus.length >= 0 && forthSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerForthLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {forthDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy4 == 4) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 4, 200) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))}

                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 5
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">300</span>
                                                        </div>
                                                    </div>

                                                    {fiveisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            fifthLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${5}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {fifthLoopStageOfIdNumber.length > 0 && fifthLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {fifthSeconderyLevelStatus.length >= 0 && fifthSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerFifthLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {fifthDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy5 == 5) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 5, 300) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }


                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 6
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">500</span>
                                                        </div>
                                                    </div>

                                                    {sixisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            sixthLevelStatus == true ? (
                                                                <>
                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${6}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {sixLoopStageOfIdNumber.length > 0 && sixLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {sixSeconderyLevelStatus.length >= 0 && sixSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerSixthLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {sixthDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy6 == 6) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 6, 500) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }


                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 7
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">1000</span>
                                                        </div>
                                                    </div>

                                                    {sevenisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            sevenLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${7}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {sevenLoopStageOfIdNumber.length > 0 && sevenLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {sevenSeconderyLevelStatus.length >= 0 && sevenSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerSevenLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {seventhDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy7 == 7) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 7, 1000) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }


                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 8
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">1500</span>
                                                        </div>
                                                    </div>

                                                    {eigthisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            egithLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${8}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {eigthLoopStageOfIdNumber.length > 0 && eigthLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {eigthSeconderyLevelStatus.length >= 0 && eigthSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerEightLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {eigthDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy8 == 8) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 8, 1500) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }



                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 9
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">2500</span>
                                                        </div>
                                                    </div>

                                                    {nineisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            nineLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${9}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {nineLoopStageOfIdNumber.length > 0 && nineLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {nineSeconderyLevelStatus.length >= 0 && nineSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerNineLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {ninethDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy9 == 9) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 9, 2500) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }



                                                </div>
                                            </div>

                                            <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">


                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                Lvl 10
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">5000</span>
                                                        </div>
                                                    </div>

                                                    {tenisloader
                                                        ? (<Loader type="spinner-circle" bgColor="#ffc853" size={100} />)
                                                        : (
                                                            tenLevelStatus == true ? (
                                                                <>

                                                                    <a href={`${config.WINDOW_LOCATION_DMLM_XCORE_INNER}${locateUserId}${config.WINDOW_LOCATION_LEVEL}${10}`}>
                                                                        <div className="xcore-total-grid-overrall">

                                                                            <div className="xcore-total-grid-2">

                                                                                <div className="grid-dashboard-xcore-l2-container">
                                                                                    {tenLoopStageOfIdNumber.length > 0 && tenLoopStageOfIdNumber.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`dashboard-user-xcore-all-level-2-programs ${item !== 0
                                                                                                        ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>

                                                                            <div className="xcore-total-grid-1">
                                                                                <div className="grid-dashboard-xcore-l1-container">
                                                                                    {tenSeconderyLevelStatus.length >= 0 && tenSeconderyLevelStatus.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={index} className={`dashboard-user-xcore-all-level-programs ${item !== 0
                                                                                                    ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    {item == 0
                                                                                                        ? ""
                                                                                                        : parseInt(item)}
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                        <div className="mt-4">
                                                                            <span className="mb-0 dashboard-text-4">
                                                                                <i className="fa-solid fa-user-group"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {partnerTenLoopLength}
                                                                            </span>
                                                                            <span className="mb-0 dashboard-text-4 ms-2">
                                                                                <i className="fa-solid fa-arrows-rotate"></i>
                                                                            </span>
                                                                            <span className="dashboard-x3-text-1 ms-1">
                                                                                {tenDisplayCount}
                                                                            </span>
                                                                        </div>
                                                                    </a>

                                                                </>
                                                            ) : (
                                                                <>

                                                                    <div class="x-core-buy-btn">
                                                                        {(isbuy10 == 10) ?
                                                                            <button className="dashboard-preview-btn-2">
                                                                                <div class="spinner-border " role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </button> :
                                                                            <button class="dashboard-preview-btn-2" onClick={() => { buyNewLevelOfMLM(locateUserId, 10, 5000) }}>Buy</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))
                                                    }



                                                </div>
                                            </div>



                                            {/* <div className="xcore-card-1">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">
                                                    <div className="d-flex flex-row mb-2">
                                                        <div>
                                                            <span className="mb-0 dashboard-text-4">
                                                                demo
                                                            </span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <span className="dashboard-x3-text-1">
                                                                <img
                                                                    src={imglogo}
                                                                    className="dashboard-gold-inner-sm-img-1"
                                                                    title="coin image"
                                                                    alt="coin-image"
                                                                />
                                                            </span>
                                                            <span className="dashboard-x3-text-1">00</span>
                                                        </div>
                                                    </div>


                                                    <div class="x-core-buy-btn-two">

                                                        <div className="d-flex align-items-center gap-2">
                                                            <div>
                                                                <button class="dashboard-preview-btn-2">
                                                                    <div>Buy with</div>
                                                                    <div><img src={dusd} alt='dusd' className='switch-coin' />DUSD</div>
                                                                </button>
                                                            </div>
                                                            <div>
                                                                <button class="dashboard-preview-btn-2">
                                                                    <div>Buy with</div>
                                                                    <div><img src={usdt} alt='dusd' className='switch-coin' />USDT</div>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div> */}

                                            <ModalProcess isOpens={modalshow} />


                                        </div>

                                        <div className="d-flex flex-row mt-5 gap-4 flex-wrap">
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle" style={{ color: "white" }}></i></span><span
                                                    className="dashboard-text-4 ms-2">Direct partner</span>
                                            </div>
                                            {/* <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"
                                                    style={{ color: "yellow" }}></i></span><span
                                                        className="dashboard-text-4 ms-2">Spillover from above</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"
                                                    style={{ color: "gold" }}></i></span><span
                                                        className="dashboard-text-4 ms-2">Spillover from below</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"
                                                    style={{ color: "aqua" }}></i></span><span
                                                        className="dashboard-text-4 ms-2">Gift</span>
                                            </div> */}
                                            <div>
                                                <span><i className="fa-solid fa-user-group icon-cl"></i></span><span
                                                    className="dashboard-text-4 ms-2">Partners on level</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-solid fa-arrows-rotate icon-cl"></i></span><span
                                                    className="dashboard-text-4 ms-2">Level Cycle</span>
                                            </div>
                                            {/* <div>
                                                <span><i className="fa-solid fa-circle-arrow-up icon-cl"></i></span><span
                                                    className="dashboard-text-4 ms-2">Send to upline</span>
                                            </div>
                                            <div>
                                                <button className="dashboard-button-2"><i
                                                    className="fa-solid fa-circle-question me-1"></i> Marketing legend
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="card dashboard-x3-card-2 mt-4">
                                        <table className="table">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">SI.no</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Level</th>
                                                    <th scope="col">Wallet</th>
                                                    <th scope="col">DUSD</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactionDetailsInTable.length === 0 ? (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td> Record not found</td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                ) : (
                                                    transactionDetailsInTable.length > 0 &&
                                                    transactionDetailsInTable
                                                        .sort((a, b) => new Date(b.create_At) - new Date(a.create_At)) // Sort by create_At in descending order
                                                        .filter((item, index, self) =>
                                                            self.findIndex(t => t.userIds === item.userIds && t.userLevel === item.userLevel) === index
                                                        )
                                                        .slice(0, visibleItems)
                                                        .map((item, index) => {
                                                            // const totalAmount = transactionDetailsInTable.reduce((acc, item) => acc + item.amount, 0);
                                                            totalAmount += item.amount;

                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                                    <td>{item.create_At}</td>
                                                                    <td><button className="dashboard-button-1" onClick={() => { invitedIdRedirect(item.userIds) }}> ID {item.userIds}</button></td>
                                                                    <td>{item.userLevel}</td>
                                                                    <td>
                                                                        <div className="ms-auto d-flex align-items-center gap-2" style={{ fontsize: "13px" }}>
                                                                            <span>
                                                                                {sliceAddress(item.walletAddress)}
                                                                            </span>
                                                                            <button className="border-0 bg-transparent textwhite">
                                                                                <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.tx_Hash}`} target='_blank'>
                                                                                    <i className="fa-solid fa-link"></i>
                                                                                </a>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.amount} DUSD</td>

                                                                </tr>
                                                            );
                                                        })
                                                )}

                                            </tbody>

                                        </table >
                                        {transactionDetailsInTable.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                                            <button
                                                className="btn dashboard-activity-seemore-btn2 my-2 m-2 py-2" type="button"
                                                onClick={handleSeeMoreClick}
                                            >
                                                <IoIosArrowDown /> {'See More'}
                                            </button>

                                        )}

                                        {/* {transactionDetailsInTable.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                                            <button
                                                className="btn dashboard-activity-seemore-btn mt-2 py-2" type="button"
                                                onClick={handleSeeLessClick}
                                            >
                                                <IoIosArrowUp /> {'See Less'}
                                            </button>

                                        )} */}

                                    </div >


                                </div >
                            </div >


                        </div>
                    </div>
                </div>

            </section>

        </div>
    );
}

export default Dmlmxcore;