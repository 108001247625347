let config = {};
let environment = "live";
if (environment === "local") {
  config = {
    BACKEND_URL: "http://localhost:5001/",
    FRONTEND_URL: "http://localhost:3000",
    REGISTRATION_PAGE_URL: "http://localhost:3000/registration/",
    DB: "mongodb+srv://D-MLM:qSMkxClv7zhqxwxy@cluster0.e66dyaf.mongodb.net/",
    DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
    DCXCHAIN_ID: 5272,
    DCXCHAIN_HEX_ID: "0x1498",
    DCX_SYMBOL: "DCX",
    DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
    DCX_NETWORK_NAME: "D-Chain",
    DCX_DECIMAL: 18,
    // DUSD_TOKEN_ADDRESS: '0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705',
    // PLAN_1_CONTRACT_ADDRESS: '0x5bAa0171EF2342D5965eDc5956ab126a4C369174',
    // DEMO_CORE_PLAN_CONTRACT_ADDRESS: '0x0Bb1335847D5413845159e367954460325388D0c',

    // -----------------------------Local Contract Address--------------------------
    DUSD_TOKEN_ADDRESS: "0x1E773a1a300F255d03FA48D60e8B3dE6089FB984",
    PLAN_1_CONTRACT_ADDRESS: "0x3a51355cC326B320fCF4C111D3E28eA58C54B2D6",
    DEMO_CORE_PLAN_CONTRACT_ADDRESS: "0x6dF7c8482143d9D5D5Db5b23428b8764040Cca69",
    D_REWARD_STORED_ADDRESS: "0x4c9213B3e7CCCAE00a021eaB36C1585c6CC28FD4",
    ADMIN_WALLET_ADDRESS: "0x4c9213B3e7CCCAE00a021eaB36C1585c6CC28FD4",
    // -----------------------------Local Contract Address--------------------------

    YOUR_NODE_RPC_URL: "https://mainnetcoin.d-ecosystem.io/",
    REGISTRATION_ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    WINDOW_LOCATION_LOGIN_PAGE: "/login",
    WINDOW_LOCATION_DASHBOARD_PAGE: "/dashboard",
    D_CHAIN_EXPLORER_TXHASH_URL: "https://mainnet-explorer.d-ecosystem.io/tx/",
    REGISTER_HISTORY_SEE_MORE_LESS: 7,
    WINDOW_LOCATION_LINK_PAGE: "/links?userid=",
    WINDOW_LOCATION_DMLMXXXXGOLD_PAGE: "/PropelXxcore/",
    WINDOW_LOCATION_INSTRUCTIONS: "/instructions",
    WINDOW_LOCATION_CALCULATOR: "/calculator",
    D_CHAIN_EXPLORER_ADDRESS_URL:
      "https://mainnet-explorer.d-ecosystem.io/address/",
    D_CHAIN_OVER_ALL_HISTORY_API_URL:
      "https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&address=",
    D_CHAIN_OVER_ALL_HISTORY_API_KEY: "&apikey=",
    USER_IDS_COUNT_NUMBERS: 1,
    WINDOW_LOCATION_LEVEL_INCOME_PAGE: "/levelincome/",
    WINDOW_LOCATION_DMLM_XCORE_INNER: "/PropelXxcoreinner?user=",
    WINDOW_LOCATION_LEVEL: "&level=",
    WINDOW_LOCATION_REGISTRATION_ID: "/registration",
    WINDOW_LOCATION_RANKINCOME_ID: "/rankincome/",
    SALESPAD_DUSD_URL: "https://dcx.d-ecosystem.io/",
    BaniMiniApp:"https://t.me/TaskRewardBot_bot",

    // bsc test
    BSCRPC_URL: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
    BSCCHAIN_ID: 97,
    BSCCHAIN_HEX_ID: "0x61",
    BSC_SYMBOL: "tBNB",
    BSC_BLOCKEXPLORER: "https://testnet.bscscan.com",
    BSC_NETWORK_NAME: "Binance Smart Chain Testnet",
    BSC_DECIMAL: 18,
    REGISTRATION_ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    BSC_EXPLORER_TXHASH_URL: "https://testnet.bscscan.com/tx/",
    BSC_EXPLORER_ADDRESS_URL: "https://testnet.bscscan.com/address/",
    Deposit_Contract: "0xDF590c7e74f12485CAFf2E73529a510D99Cc4b65",
    Usdt_contract: "0x29998Cd584d313aA93F495a59357853d70E36F8b",
  };
} else if (environment === "demo") {
  config = {
    // BACKEND_URL: "https://staging-mlm-api.d-ecosystem.io/",
    BACKEND_URL: "https://staging-mlm-api.d-ecosystem.io/",
    FRONTEND_URL: "https://staging-mlm.d-ecosystem.io",
    REGISTRATION_PAGE_URL: "https://staging-mlm.d-ecosystem.io/registration/",
    DB: "mongodb+srv://D-MLM:qSMkxClv7zhqxwxy@cluster0.e66dyaf.mongodb.net/",
    DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
    DCXCHAIN_ID: 5272,
    DCXCHAIN_HEX_ID: "0x1498",
    DCX_SYMBOL: "DCX",
    DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
    DCX_NETWORK_NAME: "D-Chain",
    DCX_DECIMAL: 18,
    // DUSD_TOKEN_ADDRESS: '0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705', // Token Contract Address
    // PLAN_1_CONTRACT_ADDRESS: '0x5bAa0171EF2342D5965eDc5956ab126a4C369174', // Plan Contract Address
    // DEMO_CORE_PLAN_CONTRACT_ADDRESS: '0x0Bb1335847D5413845159e367954460325388D0c', // xCore Contract Address
    YOUR_NODE_RPC_URL: "https://mainnetcoin-two.d-ecosystem.io/",
    REGISTRATION_ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    WINDOW_LOCATION_LOGIN_PAGE: "/login",
    WINDOW_LOCATION_DASHBOARD_PAGE: "/dashboard",
    D_CHAIN_EXPLORER_TXHASH_URL: "https://mainnet-explorer.d-ecosystem.io/tx/",
    REGISTER_HISTORY_SEE_MORE_LESS: 7,
    WINDOW_LOCATION_LINK_PAGE: "/links?userid=",
    WINDOW_LOCATION_DMLMXXXXGOLD_PAGE: "/PropelXxcore/",
    WINDOW_LOCATION_INSTRUCTIONS: "/instructions",
    WINDOW_LOCATION_CALCULATOR: "/calculator",
    D_CHAIN_EXPLORER_ADDRESS_URL:
      "https://mainnet-explorer.d-ecosystem.io/address/",
    D_CHAIN_OVER_ALL_HISTORY_API_URL:
      "https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&address=",
    D_CHAIN_OVER_ALL_HISTORY_API_KEY: "&apikey=",
    USER_IDS_COUNT_NUMBERS: 1,
    WINDOW_LOCATION_LEVEL_INCOME_PAGE: "/levelincome/",
    WINDOW_LOCATION_DMLM_XCORE_INNER: "/PropelXxcoreinner?user=",
    WINDOW_LOCATION_LEVEL: "&level=",
    WINDOW_LOCATION_REGISTRATION_ID: "/registration",
    WINDOW_LOCATION_RANKINCOME_ID: "/rankincome/",
    SALESPAD_DUSD_URL: "https://dcx.d-ecosystem.io/",
    BaniMiniApp:"https://t.me/TaskRewardBot_bot",

    // BSC TEST
    BSCRPC_URL: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
    BSCCHAIN_ID: 97,
    BSCCHAIN_HEX_ID: "0x61",
    BSC_SYMBOL: "tBNB",
    BSC_BLOCKEXPLORER: "https://testnet.bscscan.com",
    BSC_NETWORK_NAME: "Binance Smart Chain Testnet",
    BSC_DECIMAL: 18,
    REGISTRATION_ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    BSC_EXPLORER_TXHASH_URL: "https://testnet.bscscan.com/tx/",
    BSC_EXPLORER_ADDRESS_URL: "https://testnet.bscscan.com/address/",
    Deposit_Contract: "0xDF590c7e74f12485CAFf2E73529a510D99Cc4b65",
    Usdt_contract: "0x29998Cd584d313aA93F495a59357853d70E36F8b",

    // -----------------------------Testing Contract Address--------------------------
    // DUSD_TOKEN_ADDRESS: "0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705",
    // PLAN_1_CONTRACT_ADDRESS: "0x6585c4d684A335927089670f1D1Cc1e02A3e7DF6",
    // DEMO_CORE_PLAN_CONTRACT_ADDRESS: "0x4b9b0cBDE45F8223D36BaE2CE156B46BCd7171D2",
    // D_REWARD_STORED_ADDRESS: "0x7f216358dd6Dde598B0d546AF0f4E2b65C5f24bC",
    // ADMIN_WALLET_ADDRESS: "0xAf1c1fB8C1a1B365177E559483295a7d461FC976",
    // -----------------------------Testing Contract Address--------------------------

    // -----------------------------Testing Contract Address--------------------------
    DUSD_TOKEN_ADDRESS: "0x1E773a1a300F255d03FA48D60e8B3dE6089FB984",
    PLAN_1_CONTRACT_ADDRESS: "0x3a51355cC326B320fCF4C111D3E28eA58C54B2D6",
    DEMO_CORE_PLAN_CONTRACT_ADDRESS: "0x6dF7c8482143d9D5D5Db5b23428b8764040Cca69",
    D_REWARD_STORED_ADDRESS: "0x4c9213B3e7CCCAE00a021eaB36C1585c6CC28FD4",
    ADMIN_WALLET_ADDRESS: "0x4c9213B3e7CCCAE00a021eaB36C1585c6CC28FD4",
    // -----------------------------Testing Contract Address--------------------------
  };
} else {
  config = {
    // --------------------   LIVE CONFIG   -------------------------

    // BACKEND_URL: "https://staging-mlm-api.d-ecosystem.io/",
    BACKEND_URL: "https://api.propelx.io/",
    FRONTEND_URL: "https://propelx.io/",
    REGISTRATION_PAGE_URL: "https://propelx.io/registration/",
    DB: "mongodb+srv://D-MLM:qSMkxClv7zhqxwxy@cluster0.e66dyaf.mongodb.net/",
    DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
    DCXCHAIN_ID: 5272,
    DCXCHAIN_HEX_ID: "0x1498",
    DCX_SYMBOL: "DCX",
    DCX_BLOCKEXPLORER: "https://mainnetcoin-two.d-ecosystem.io/",
    DCX_NETWORK_NAME: "D-Chain",
    DCX_DECIMAL: 18,
    // DUSD_TOKEN_ADDRESS: '0x9dA31A57Dc41C548147F3bF07e0cb1d6a4b7C705', // Token Contract Address
    // PLAN_1_CONTRACT_ADDRESS: '0x5bAa0171EF2342D5965eDc5956ab126a4C369174', // Plan Contract Address
    // DEMO_CORE_PLAN_CONTRACT_ADDRESS: '0x0Bb1335847D5413845159e367954460325388D0c', // xCore Contract Address
    YOUR_NODE_RPC_URL: "https://mainnetcoin.d-ecosystem.io/",
    REGISTRATION_ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    WINDOW_LOCATION_LOGIN_PAGE: "/login",
    WINDOW_LOCATION_DASHBOARD_PAGE: "/dashboard",
    D_CHAIN_EXPLORER_TXHASH_URL: "https://mainnet-explorer.d-ecosystem.io/tx/",
    REGISTER_HISTORY_SEE_MORE_LESS: 7,
    WINDOW_LOCATION_LINK_PAGE: "/links?userid=",
    WINDOW_LOCATION_DMLMXXXXGOLD_PAGE: "/PropelXxcore/",
    WINDOW_LOCATION_INSTRUCTIONS: "/instructions",
    WINDOW_LOCATION_CALCULATOR: "/calculator",
    D_CHAIN_EXPLORER_ADDRESS_URL:
      "https://mainnet-explorer.d-ecosystem.io/address/",
    D_CHAIN_OVER_ALL_HISTORY_API_URL:
      "https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&address=",
    D_CHAIN_OVER_ALL_HISTORY_API_KEY: "&apikey=",
    USER_IDS_COUNT_NUMBERS: 1,
    WINDOW_LOCATION_LEVEL_INCOME_PAGE: "/levelincome/",
    WINDOW_LOCATION_DMLM_XCORE_INNER: "/PropelXxcoreinner?user=",
    WINDOW_LOCATION_LEVEL: "&level=",
    WINDOW_LOCATION_REGISTRATION_ID: "/registration",
    WINDOW_LOCATION_RANKINCOME_ID: "/rankincome/",
    SALESPAD_DUSD_URL: "https://dcx.d-ecosystem.io/",
    BaniMiniApp:"https://t.me/Realbani_bot",
    
    // BSC MAINNET
    BSCRPC_URL: "https://bsc-dataseed1.binance.org/",
    BSCCHAIN_ID: 56,
    BSCCHAIN_HEX_ID: "0x38",
    BSC_SYMBOL: "BNB",
    BSC_BLOCKEXPLORER: "https://bscscan.com",
    BSC_NETWORK_NAME: "Binance Smart Chain Mainnet",
    BSC_DECIMAL: 18,
    REGISTRATION_ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    BSC_EXPLORER_TXHASH_URL: "https://bscscan.com/tx/",
    BSC_EXPLORER_ADDRESS_URL: "https://bscscan.com/address/",
    Deposit_Contract: "0xcF5C03ea539f7370dec53700445E9DB5A2429A37",
    Usdt_contract: "0x55d398326f99059fF775485246999027B3197955",

    // -----------------------------Live  Contract Address--------------------------
    DUSD_TOKEN_ADDRESS: "0x31bb32680709907b4a2cd0f0baf718d73c4d6b62",
    PLAN_1_CONTRACT_ADDRESS: "0x793ce2977825f3e14608836cD7ef3d7d8138830f",
    DEMO_CORE_PLAN_CONTRACT_ADDRESS:"0x75F2D5f4f12d267a62f67c6dF223fF572258E599",
    D_REWARD_STORED_ADDRESS: "0x2d6461E591415C263fb8B89D974181ED24Fbf04a",
    ADMIN_WALLET_ADDRESS: "0x4a3555Eb8fb9D4FCcFaA9c3aa546C14EEd540538",
    // -----------------------------Live  Contract Address--------------------------
  };
}

export default config;