import React, { useState, useEffect } from "react";
import $ from "jquery";
import { BsFillSunFill } from "react-icons/bs";
import { BsFillMoonFill } from "react-icons/bs";

export default function DarkLight() {

  useEffect(() => {
    var darkMode;

    if (localStorage.getItem('dark-mode')) {
        darkMode = localStorage.getItem('dark-mode');
    } else {
        darkMode = 'light';
    }

    localStorage.setItem('dark-mode', darkMode);


    if (localStorage.getItem('dark-mode') === 'dark') {
        $('body').addClass('dark-theme');
        $('.dark-button').hide();
        $('.light-button').show();
    }



    $('.dark-button').on('click', function () {
        $('.dark-button').hide();
        $('.light-button').show();
        $('body').addClass('dark-theme');
        localStorage.setItem('dark-mode', 'dark');
    });

    $('.light-button').on('click', function () {
        $('.light-button').hide();
        $('.dark-button').show();
        $('body').removeClass('dark-theme');
        localStorage.setItem('dark-mode', 'light');
    });

});
  return (
    <div>
                <button class="mode-toggler-button dark-button bg-transparent border-0">
                  <BsFillSunFill className="dark-light-icon" />
                </button>
                <button
                  class="mode-toggler-button light-button bg-transparent border-0 m-lg-0 m-2"
                  style={{ display: "none" }}
                >
                <BsFillMoonFill className="dark-light-icon"/>
                </button>
    </div>
  )
}
