import "../Assets/Css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import unknownuser from "../Assets/images/UnknownUser.webp";
import bnb from "../Assets/images/dusd.png";
import Platinum from "../Assets/images/ranking1/Platinum.png";
import diamond from "../Assets/images/ranking1/diamond.png";
import bronze from "../Assets/images/ranking1/bronze.png";
import silver from "../Assets/images/ranking1/silver.png";
// import slide from "../Assets/images/ranking1/slide.png";
import gold from "../Assets/images/ranking1/Layer 1 copy.png";
import { useEffect, useState } from "react";
import Web3 from 'web3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import { showUserTransactionInDashBoard, RankingAPI, RankingData } from "../Dashboard-pages/api/authapis";
import { formatDistanceToNow } from 'date-fns';

function Rankincome() {

    const [referralAddress, setReferralAddress] = useState();
    const [thePartnersLength, setThePartnersLength] = useState(0);
    // const [teamMemberLength, setTeamMemberLength] = useState([0]);
    const [referredByUserId, setReferredByUserId] = useState();
    const [invitedDate, setInvitedDate] = useState();
    const [registerDataList, setRegisterDataList] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    // const [rankingDisplayHere, setRankingDisplayHere] = useState();
    // const [rankingName, setRankingName] = useState();

    const [rankingName, setRankingName] = useState(null);
    const [rankingDisplayHere, setRankingDisplayHere] = useState(null);
    const [error, setError] = useState(null);

    var web3js = '';

    if (window.ethereum) {
        // web3js = new Web3(window.ethereum);
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }
    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);

    const locateUserId = window.location.pathname.split('/')[2];


    const getTheUserWalletAddress = async () => {
        try {
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
            setReferralAddress(checkUserRegisteredExists);

            const checkReferralUserDetails = await DemoCorePlanContract.methods.users(checkUserRegisteredExists).call();

            const checkReferralIDWithAddressUserDetails = await DemoCorePlanContract.methods.users(checkReferralUserDetails.referrer).call();
            const objAddress = await convertBigIntToString(checkReferralIDWithAddressUserDetails);

            setReferredByUserId(objAddress.id);
        } catch (error) {
            console.error('Error in getTheUserWalletAddress:', error);
        }
    };

    const convertBigIntToString = async (obj) => {
        if (typeof obj === 'bigint') {
            return obj.toString();
        } else if (typeof obj === 'object') {
            for (const key in obj) {
                obj[key] = await convertBigIntToString(obj[key]);
            }
        }

        return obj;
    };

    // Call the async function
    getTheUserWalletAddress();

    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';

            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);

            return `${prefix}...${suffix}`;
        } catch (error) {
            return '';
        }
    };


    const getListOfRegisterData = async () => {
        try {


            const resultOfRegisterData = await showUserTransactionInDashBoard();
            const modifiedDataArray = [];
            const referralAddressesSet = [];
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
            const findTheGroupOfDirectReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredExists).call();
            setThePartnersLength(findTheGroupOfDirectReferredID.length);
            console.log('resultOfRegisterData---', findTheGroupOfDirectReferredID);

            // const findTheGroupOfIndirectReferredID = await DemoCorePlanContract.methods.getindirectref(findTheGroupOfDirectReferredID[0]).call();

            // setTeamMemberLength(findTheGroupOfIndirectReferredID.length);
            // console.log('resultOfRegisterData.data.data--',resultOfRegisterData.data.data)
            for (const registerDataItem of resultOfRegisterData.data.data) {
                const createdAtDate = new Date(registerDataItem.Create_At);

                const checkTheNewUserRefferIdAndOldOne = registerDataItem.Referral_ID == locateUserId;


                if (checkTheNewUserRefferIdAndOldOne) {
                    const directReferral = await getTeamAnalysisData(registerDataItem.Referral_Address);
                    referralAddressesSet.push(directReferral);

                }
                // Check if the user matches the condition
                if (registerDataItem.New_User_Referral_Id == locateUserId) {
                    const formattedDate = new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true, 
                        timeZone: "UTC"
                    }).format(createdAtDate);
                    setInvitedDate(formattedDate);

                    // Add the item to the modifiedDataArray if needed
                    modifiedDataArray.push({ ...registerDataItem, formattedDate });
                }
            }

        } catch (error) {
            console.error('Error fetching register data:', error);
        }

        try {
            const resultOfRegisterData = await showUserTransactionInDashBoard();
            // console.log('resultOfRegisterData---', resultOfRegisterData);
            const modifiedDataArray = [];

            for (const registerDataItem of resultOfRegisterData.data.data) {
                // Convert the Create_At timestamp to a JavaScript Date object
                const createdAtDate = new Date(registerDataItem.Create_At);

                const newItem = {
                    newUserReferralId: registerDataItem.New_User_Referral_Id,
                    newUserRegisteredAddress: registerDataItem.New_User_Registered_Address,
                    referralAddress: registerDataItem.Referral_Address,
                    referralId: registerDataItem.Referral_ID,
                    status: registerDataItem.status,
                    txHash: registerDataItem.tx_Hash,
                    dateTime: formatDistanceToNow(createdAtDate, { addSuffix: true }),
                };

                modifiedDataArray.push(newItem);
            }

            setRegisterDataList(modifiedDataArray);
        } catch (error) {
            console.error('Error fetching register data:', error);
        }

    };



    // Helper function to get direct referral details
    const getDirectReferralDetails = async (referralDirectAddress) => {
        const details = await DemoCorePlanContract.methods.directreferral(referralDirectAddress).call();

        return details;
    };

    const getTeamAnalysisData = async (referralTeamAddress) => {
        try {
            // Adjust this line to use the correct method
            const details = await DemoCorePlanContract.methods.directreferral(referralTeamAddress).call();

            return details;
        } catch (error) {
            console.error('Error fetching team analysis data:', error);
            throw error; // Propagate the error for better error handling
        }
    };

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(referralAddress);
            setIsCopied(true);
            toast.success(ToastApi.CopiedAddress, { autoClose: 2000 });
        } catch (error) {
            toast.error(ToastApi.FailedToCopy);
        }
    };



    const getRankDetails = async () => {
        try {
            const details = await RankingData(locateUserId);
            if (details.result !== null) {
                switch (details.result.Rank_Status) {
                    case "BRONZE":
                        setRankingName(details.result.Rank_Status);
                        setRankingDisplayHere(bronze);
                        break;
                    case "SILVER":
                        setRankingName(details.result.Rank_Status);
                        setRankingDisplayHere(silver);
                        break;
                    case "GOLD":
                        setRankingName(details.result.Rank_Status);
                        setRankingDisplayHere(gold);
                        break;
                    case "PLATINUM":
                        setRankingName(details.result.Rank_Status);
                        setRankingDisplayHere(Platinum);
                        break;
                    case "DIAMOND":
                        setRankingName(details.result.Rank_Status);
                        setRankingDisplayHere(diamond);
                        break;
                    default:
                        setRankingName(null);
                        setRankingDisplayHere(null);
                }
            }
        } catch (error) {
            setError(error.message || error);
        }
    };

    

    useEffect(() => {
        getTheUserWalletAddress();
        getListOfRegisterData();
        getRankDetails();

    }, []);

    const invitedIdRedirect = async (invitedId) => {
        try {
            // console.log('invitedId---', invitedId);
            window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + `${invitedId}`;
        } catch (error) {

        }
    };

    return (
        <div className="rank-income">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 mt-2 g-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-lg-row flex-column align-items-center">
                                        <div className="me-lg-4">
                                            <div className="d-flex flex-row align-items-end">
                                                <img className="dashboard-user-profile" src={unknownuser}
                                                    alt="User-profile" />
                                                <img className="dashboard-user-coin-logo" src={bnb}
                                                    alt="coin-logo" />
                                            </div>

                                        </div>
                                        <div>

                                            <p className="mb-0 dashboard-text-2">ID {locateUserId}</p>
                                            <p className="mb-1 dashboard-text-3">{sliceAddress(referralAddress)}
                                                <button
                                                    className="ms-2 bg-transparent border-0 " onClick={handleCopyClick}
                                                    disabled={isCopied}><i
                                                        className="fa-solid fa-copy rank-copy"></i>
                                                </button>
                                            </p>
                                            {referredByUserId > 0 ? (
                                                <>
                                                    <span className="mb-0 dashboard-text-4">invited {invitedDate} </span>
                                                    <button className="dashboard-button-1" onClick={() => { invitedIdRedirect(referredByUserId) }}>ID {referredByUserId}</button>
                                                </>
                                            ) : (
                                                <>
                                                    {/* <span className="mb-0 dashboard-text-4">invited {invitedDate} by
                                                        <button className="dashboard-button-1" onClick={() => { invitedIdRedirect(referredByUserId) }}>ID {referredByUserId}</button>
                                                    </span> */}
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex  whole-box-content my-3 justify-content-center align-items-center">

                                    <div className="dollar-images d-flex flex-column justify-content-center align-items-center ">
                                        {error ? (
                                            <h1>Error: {error}</h1>
                                        ) : (
                                            <>
                                                {rankingDisplayHere ? (
                                                    <>
                                                        <div className="dollar-images-hold0">
                                                            <img src={rankingDisplayHere} alt="Rank Image" />
                                                        </div>
                                                        <div className="small-icons-images d-flex mt-3">
                                                            <div className="small-icons-images d-flex mt-3 gap-3">
                                                                <img src={bronze} className="" />
                                                                <img src={silver} className="" />
                                                                <img src={gold} className="" />
                                                                <img src={Platinum} className="" />
                                                                <img src={diamond} className="" />

                                                            </div>
                                                        </div>
                                                        <div className="small-icons-images-heading mt-3">
                                                            <p className="fs-1 fw-bold">{rankingName}</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="fs-1 fw-bold">Not Eligible</p>

                                                        <div className="small-icons-images d-flex mt-3">
                                                            <div className="small-icons-images d-flex mt-3 gap-3">
                                                                <img src={bronze} className="" />
                                                                <img src={silver} className="" />
                                                                <img src={gold} className="" />
                                                                <img src={Platinum} className="" />
                                                                <img src={diamond} className="" />

                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Rankincome;