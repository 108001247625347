import React, { useState, useEffect } from 'react'
import Claimnavbar from './Claimnavbar'
import Web3 from 'web3';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import { getUserReedomCode, GenreateCoupon } from "../Dashboard-pages/api/authapis";
import CryptoJS from 'crypto-js'
import { FaCopy } from 'react-icons/fa';
import ToastApi from '../config/toastApi';
import { ToastContainer, toast } from 'react-toastify';
import baniapp from "../Assets/images/bani.png";



function Dailyclaim() {
    const [connectwallet, setConnectedWallet] = useState(null);
    const [ReedomData, setReedomData] = useState(null);
    const [state, setState] = useState(false);
    const [Show, setShow] = useState(false);
    const toastCheck = React.useRef(null);
    var web3js = '';

    if (window.ethereum) {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }
    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);
    const xCoreContract = new web3js.eth.Contract(Plan1Abi, config.PLAN_1_CONTRACT_ADDRESS);

    const checkConnectedWallet = async () => {
        try {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                setConnectedWallet(accounts?.[0] || null);

                const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(accounts[0]).call();
                if (!checkUserRegisteredExists) {
                    return  window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
                } 

            }
            else {
                console.log("Metamask not install");
            }
        } catch (error) {
            console.error('Error checking connected wallet:', error);
        }
    };

    const Getuserdata = async () => {
        try {
            const ADate = { Address: connectwallet }
            console.log('ADate', ADate)
            const Response = await getUserReedomCode(ADate);
            console.log('Response', Response)

            if (Response.status === true) {
                const res = Response.result.Status;
                setShow(true)
            } else {
                setReedomData([])
                setShow(false)
                const coupon = Response.result.CouponCode;
                setReedomData(coupon)
                console.log('coupon', coupon)
            }
        } catch (error) {
            console.log('Getuserdata Error:', error);
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(ReedomData)
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
        toast.success("Coupon Code Copied!")
    };

    const GenreateCode = async () => {
        try {
            const ADate = { Address: connectwallet }
            const Response = await GenreateCoupon(ADate);

            if (Response.status === true) {
                const result = Response.result
                setReedomData(result.CouponCode)
                Getuserdata()
            } else {
                setReedomData([])
            }
            console.log('Response', Response);

        } catch (error) {
            console.log('Getuserdata Error:', error);
        }
    }

    useEffect(() => {
        checkConnectedWallet()
    }, [connectwallet])

    useEffect(() => {
        if (connectwallet) {
            Getuserdata()
        }
    }, [connectwallet])



    return (
        <div className='Dailyclaim'>
            <Claimnavbar connectwallet={connectwallet} setConnectedWallet={setConnectedWallet} />
            <ToastContainer />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 dashboard-split-right-section">
                            <div className='py-5'>
                                <h3 className='text-center fs-1 pb-4'><span className='yellow-colour'>Claim </span>Your Daily Rewards</h3>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div class="card claim-card">
                                        <div class="card-body">
                                            <p class="card-text text-center">Claim your Daily Bani just by clicking/copying and pasting to Bani Miniapp propelx section</p>
                                            <p class="text-center yellow-colour">Click to copy your daily unique code</p>
                                            <div className='text-center d-flex justify-content-center'>
                                                {connectwallet === null ? <div className='link-style-one-colour'>"Please Connect Your Wallet"</div> :
                                                    Show ? <span className='link-style-one'>
                                                        <button type='button' className='bg-transparent border-0 link-style-one-colour' onClick={GenreateCode}>Get Couponcode</button>
                                                    </span> :
                                                        <span className='link-style'>{ReedomData}
                                                            <button type='button' onClick={handleCopy} className='bg-transparent border-0'><FaCopy className='tutorial-link ms-2' /></button>
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-center pt-3 text-center'>
                                    <a href={`${config.BaniMiniApp}`} target='_blank'>
                                        <button className='gobackapp d-flex align-items-center justify-content-center gap-1'>
                                            <div><img src={baniapp} className='baniapp-btn' alt='bani' /></div>
                                            <div>Go Back to Bani<br /> MiniApp</div>
                                        </button>
                                    </a>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Dailyclaim