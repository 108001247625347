import React, { useState, useEffect } from 'react'
import { BsTwitterX } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { getsocialmedialinks, UserInfluencer } from '../Dashboard-pages/api/authapis';
import { PiArrowRightBold } from "react-icons/pi";
import Select from 'react-select';
import selectContact from 'react-select';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import { IoLogoWechat, IoReturnUpBack } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebookMessenger } from "react-icons/fa";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Web3 from 'web3';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import config from '../config/apiConfig';
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import ToastApi from '../config/toastApi';
import { toast } from 'react-toastify';
import { valHooks } from 'jquery';
import $ from 'jquery';
import { AiOutlineArrowRight } from "react-icons/ai";

import black from "../Assets/images/Asset 2.png";
import white from "../Assets/images/Asset 1.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


var web3js = '';

if (window.ethereum) {
  web3js = new Web3(window.ethereum);
  // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
} else {
  web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
}
const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);

const Schema = yup.object().shape({
  name: yup.string().required("name is require"),
  address: yup.string().required("address is required").matches("^(0x)?[0-9a-fA-F]{40}$", "Wallet Address only required").test("Address-Check", "Already Registered Address", async (value) => {
      if (!value) return false;

      try {
          const Address = value;
          const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(Address).call();
          return !checkUserRegisteredExists;  // Return true if not registered, false if already registered
      } catch (error) {
          console.error("Error checking address registration", error);
          return false;
      }

  }),
  contactNumber: yup.string().required("contact Number is require").matches(/^\+?\d+$/, "Contact number must contain only digits")
  // .test('is-10-digits', 'Contact number must be exactly 10 digits', (value) => {

  //     if (!value) return false;

  //     const cleanedValue = value.replace(/\D/g, '')
  //     if (value.startsWith('+')) {
  //         // Check length for numbers starting with +
  //         return cleanedValue.length === 12;  // Example: +91 1234567890
  //     } else {
  //         // Check length for numbers without +
  //         return cleanedValue.length === 10;  // Example: 1234567890
  //     }
  // })
  ,
  // youtube: yup.string().required("youtube channel name is require"),
  // youtubefolCount: yup.number().typeError('must be a number').integer().positive().required("youtube follow count is require"),
  // Xtwitter: yup.string().required("twitter id is require"),
  // XfollowCount: yup.number().typeError('must be a number').integer().positive().required("twitter follow  number require"),
  // Discord: yup.string().url('Must be a valid URL').required("Discord channel link  is require"),
  // DiscortMemCount: yup.number().typeError('must be a number').integer().positive().required("Discord follow  number require"),
  // telegram: yup.string().required("telegram id is require"),
  // telegramFolCount: yup.number().typeError('must be a number').integer().positive().required("telegram follow  number require")
})

function Influencerform() {
  const [medialinks, setMedialinks] = useState([]);
  const [isloading, setIsloading] = useState(false);
  // const [userName, setUserName] = useState("");
  // const [userAddress, setUserAddress] = useState("")
  const [selectOption, setSelectedOption] = useState(null);
  const [contactNo, setContactNo] = useState(null)
  // const [youtube, setYoutube] = useState("");
  // const [instagram, setInstagram] = useState("");
  // const [telegram, setTelegram] = useState("");
  // const [facebook, setFacebook] = useState("");
  // const [ysubcount, setysubcount] = useState("");
  // const [iflcount, setiflcount] = useState("");
  // const [tflcount, settflcount] = useState("");
  // const [fbflcount, setFbflcount] = useState("");

  const [error, setErrors] = useState({})
  const [adderror, setAdderror] = useState("")
  const [selecterror, setSelectError] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [hideform, setHideform] = useState(true)


  const getsociallinks = async () => {
    try {
      const reponse = getsocialmedialinks();

      const getdata = (await reponse).result;

      if (Number(getdata.length) > 0) {
        setMedialinks(getdata)
      }
      else {
        console.log("data not found");
      }
    } catch (error) {

    }

  }

  useEffect(() => {
    getsociallinks();
  }, [])

  const handleClosed = () => {
    setisOpen(false);
    setTimeout(() => {
      window.location.href = "/"
    }, 500);
  };

  const { register, handleSubmit, control, formState: { errors }, setValue, reset, resetField } = useForm({
    resolver: yupResolver(Schema),
  })

  useEffect(() => {
    const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'), {
      backdrop: 'static',
      keyboard: false,
    });

    if (isOpen) {
      modal.show();
    } else {
      modal.hide();
    }

    return () => {
      modal.hide();
    };
  }, [isOpen]);

  // const handleClosed = () => {
  //     setisOpen(false);
  //     setTimeout(() => {
  //         window.location.href = "/"
  //     }, 500);
  // };

  const [show, setShow] = useState(false);

  const handledclosing = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = [
    { value: 'select contact', label: 'Select Contact' },
    { value: 'Whatsapp', label: <div className="d-flex align-items-center gap-2"><BsWhatsapp />Whatsapp</div> },
    { value: 'Telegram', label: <div className="d-flex align-items-center gap-2"><FaTelegramPlane />Telegram</div> },
    { value: 'Wechat', label: <div className="d-flex align-items-center gap-2"><IoLogoWechat />Wechat</div> }
  ]



  const customStyles = {
    menu: base => ({
      ...base,
      background: "#FFF",
      margin: "0px",
      padding: "0px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    control: (base, state) => ({
      ...base,
      background: "transparent",
      color: "#000",
      border: "0px solid transparent",
      boxShadow: state.isFocused ? "none" : base.boxShadow,
      fontSize: "13px",
      margin: "0px",
      padding: "0px",
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "var(--background-color-1)", // Change background color to red
        color: "var(--color-1)",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "gray",
          color: "var(--color-1)",
        }
      };
    },
  };




  const handleSelectChange = (val) => {
    console.log('val', val)
    setSelectedOption(val.value);
    setValue('contactMethod', val);
    resetField('contactNumber')
    setContactNo("")
    setSelectError("")
    // reset({ [val.value.toLowerCase()]: '' });
  };


  async function handlechangeAdd(val) {
    try {
      console.log("valll",val);
      
      const Invalue = val.target.value
      const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(Invalue).call();
      // console.log("checkUserRegistered=====", checkUserRegisteredExists, Invalue);
      // console.log("valueaddress", val.target.value);
      // if (checkUserRegisteredExists) {
      //     return setAdderror("Already is registered Address");
      // } else if (checkUserRegisteredExists === undefined) {
      //     setAdderror("");
      // } else {
      //     setAdderror("");
      // }
    } catch (error) {
      console.log("checkUserRegisterederror", error);
      setAdderror("")
    }

  }


  const handleClose = () => {
    reset();  // Reset form values
    setContactNo('');  // Clear phone number state
    setSelectedOption(options[0])
    setIsloading(false)
  };


  const OnSubmit = async (data) => {
    try {
      if (data) {
        setIsloading(true);
        const InfluencerDetails = {
          UserName: data.name,
          UserAddress: data.address,
          contactDetails: [{ contacttype: data.contactMethod.value, contactno: data.contactNumber }],
          Status: true,
          YoutubeName:(data.youtube.trim() === "" ? "N/A" :data.youtube.trim()),
          YoutubeSubcount: (data.youtubefolCount.trim() === "" ?  0 : data.youtubefolCount.trim()),
          Xtwitter: (data.Xtwitter.trim() === "" ? "N/A" : data.Xtwitter.trim()),
          XfollowCount: (data.XfollowCount.trim() === "" ?  0 : data.XfollowCount.trim()),
          Discord: (data.Discord.trim() === "" ? "N/A" : data.Discord.trim()),
          DiscortMemCount: (data.DiscortMemCount.trim() === "" ? 0 : data.DiscortMemCount.trim()),
          TelegramName: (data.telegram.trim() === "" ? "N/A" : data.telegram.trim()),
          TelegramSubcount: (data.telegramFolCount.trim() === "" ? 0  : data.telegramFolCount.trim())
        }
        console.log("InfluencerDetails", InfluencerDetails);
        if (InfluencerDetails.contactDetails[0].contacttype === "select contact") {
          setIsloading(false)
          return setSelectError("Contact type required")
        } else {
          const response = await UserInfluencer(InfluencerDetails)
          console.log("response", response);
          if (response.message === "User already registered") {
            setIsloading(false)
            return toast.warn("User Address Already Added")
          }
          if (response.status === true) {
            toast.success("Successfully Submitted")
            handleClose()
            handledclosing()
            setisOpen(true)
            setIsloading(false)
          } else {
            toast.error(ToastApi.FailedMessage)
          }

        }

      } else {
        toast.error(ToastApi.FailedMessage)
        setIsloading(false)
      }
    } catch (error) {
      console.log("OnSubmitError", error);
      setIsloading(false)
    }
  }


  return (
    <div className='influencer'>
      <div className='col d-flex justify-content-lg-end justify-content-center'>
        {/* form button */}

        <Button type="submit" className="influence-btn btn mb-3 mb-lg-0 rounded-4"
          onClick={handleShow}>
          Apply for Leader Card
          {/* <PiArrowRightBold className="influence-btn-icon" /> */}
          <AiOutlineArrowRight className="influence-btn-icon" />
        </Button>

        {/* form model*/}
        <Modal
          show={show}
          onHide={handledclosing}
          backdrop="static"
          keyboard={false}
          className=""
          id="affiliateinfluencers"
        >
          <Modal.Header closeButton className='d-flex flex-column-reverse' style={{ borderColor: "var(--background-color-5)" }} onClick={() => handleClose()}>
            <Modal.Title className='fs-5'>Apply Free Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="mb-3" onSubmit={handleSubmit(OnSubmit)}>
              <div className="">
                <div className="mb-2">
                  <p className="ido-active-text-2 w-100 mb-1">Name or Nickname<span className="text-danger">*</span></p>
                  <input
                    type="text"
                    className="form-control py-2 custom-placeholder"
                    name="name"
                    placeholder='Enter Your Name'
                    {...register('name')}
                  />
                  {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.name?.message}</small>}

                </div>
                <div className="mb-2">
                  <p className="ido-active-text-2 w-100 mb-1">Wallet Address<span className="text-danger">*</span></p>
                  <input
                    type="text"
                    className="form-control py-2 custom-placeholder"
                    name="address"
                    placeholder='Enter Your Wallet Address'
                    {...register('address', { onChange: (e) => handlechangeAdd(e) })}
                  />
                  {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.address?.message}</small>}
                  {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{adderror}</small>}

                </div>
                <div className="mb-2">
                  <p className="ido-active-text-2 w-100 mb-1">Social media contact<span className="text-danger">*</span></p>
                  <div className="input-group py-1 flex-lg-row flex-column" style={{ flexWrap: 'nowrap', border: '1px solid grey', borderRadius: '4px', display: 'flex', justifyContent: 'end' }}>
                    {
                      selectOption == "Whatsapp" ?
                        <Controller
                          name="contactNumber"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <PhoneInput
                              {...field}
                              value={field.value || ''}
                              international
                              className="px-0 text-white w-75"
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              onChange={(val) => {
                                field.onChange(val);
                                setContactNo(val);
                              }}
                            />
                          )}
                        />
                        :
                        selectOption == "Telegram" ?
                          <input
                            type="text"
                            name="telegram"
                            class="form-control border-0 w-75 custom-placeholder"
                            placeholder='Enter your number'
                            {...register("contactNumber")}
                          />
                          // :
                          // selectOption == "Messenger" ?
                          //     <input
                          //         type="text"
                          //         name="messenger"
                          //         class="form-control border-0"
                          //         {...register("contactNumber")}
                          //     />
                          :
                          selectOption == "Wechat" ?
                            <input
                              type="text"
                              name="wechat"
                              class="form-control border-0 w-75 custom-placeholder"
                              placeholder='Enter your number'
                              {...register("contactNumber")}
                            />
                            :
                            <input
                              type="text"
                              name="wechat"
                              class="form-control border-0 w-75 custom-placeholder"
                              placeholder='Select any one'
                            />
                    }
                    <Controller
                      name="contactMethod"
                      control={control}
                      defaultValue={options[0]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={options}
                          styles={customStyles}
                          defaultValue={options[0]}
                          onChange={(val) => {
                            field.onChange(val);
                            handleSelectChange(val);
                          }}
                        />
                      )}
                    />
                  </div>
                  {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.contactNumber?.message}</small>}
                  {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{selecterror}</small>}
                  <small className="invalid-email error password-text-33">

                  </small>


                  <small className="invalid-email error password-text-33">

                  </small>


                  <small className="invalid-email error password-text-33">

                  </small>


                  <small className="invalid-email error password-text-33">

                  </small>


                </div>
                <div className="row mb-2 align-items-end flex-lg-row flex-column">
                  <div className="col-lg-7 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1">
                        Youtube channel Name or Link <span className="text-danger"></span>
                      </p>
                      <input
                        type="text"
                        className="form-control custom-placeholder"
                        name="youtube"
                        placeholder=' YouTube Channel Name or URL'
                        {...register("youtube")}
                      />
                    </div>
                    {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.youtube?.message}</small>}
                  </div>


                  <div className="col-lg-5 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px" }}>Subscribers count</p>
                      <input
                        type="number"
                        className="form-control custom-placeholder"
                        name="youtubefolCount"
                        placeholder='Enter count'
                        {...register("youtubefolCount")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.youtubefolCount?.message}</small>}

                    </div>
                  </div>
                </div>
                <div className="row mb-2 align-items-end flex-lg-row flex-column">
                  <div className="col-lg-7 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1">X ID<span className="text-danger"></span></p>
                      <input
                        type="text"
                        className="form-control custom-placeholder"
                        name="Xtwitter"
                        placeholder=' Username or Profile URL'
                        {...register("Xtwitter")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.Xtwitter?.message}</small>}

                    </div>
                  </div>
                  <div className="col-lg-5 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px" }}>X Followers count</p>
                      <input
                        type="number"
                        className="form-control custom-placeholder"
                        name="XfollowCount"
                        placeholder='Enter count'
                        {...register("XfollowCount")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.XfollowCount?.message}</small>}

                    </div>
                  </div>
                </div>
                <div className="row mb-2 align-items-end flex-lg-row flex-column">
                  <div className="col-lg-7 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1">Discord Channel Link<span className="text-danger"></span></p>
                      <input
                        type="text"
                        className="form-control custom-placeholder"
                        name="Discord"
                        placeholder='Discord URL'
                        {...register("Discord")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.Discord?.message}</small>}

                    </div>
                  </div>
                  <div className="col-lg-5 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px" }}>Discord Members count</p>
                      <input
                        type="number"
                        className="form-control custom-placeholder"
                        name="DiscortMemCount"
                        placeholder='Enter count'
                        {...register("DiscortMemCount")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.DiscortMemCount?.message}</small>}

                    </div>
                  </div>
                </div>
                <div className="row mb-2 align-items-end flex-lg-row flex-column">
                  <div className="col-lg-7 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1">Telegram Group or channel Name<span className="text-danger"></span></p>
                      <input
                        type="text"
                        className="form-control custom-placeholder"
                        name="telegram"
                        placeholder='Telegram URL'
                        {...register("telegram")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.telegram?.message}</small>}

                    </div>
                  </div>
                  <div className="col-lg-5 col">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px" }}>Member / Subscribers count</p>
                      <input
                        type="number"
                        className="form-control custom-placeholder"
                        name="telegramFolCount"
                        placeholder='Enter count'
                        {...register("telegramFolCount")}
                      />
                      {<small className="invalid-email error password-text-33" style={{ color: "red" }}>{errors.telegramFolCount?.message}</small>}

                    </div>
                  </div>
                </div>

                <div className="text-center d-grid mt-4">
                  <button type="submit" className={isloading ? "banner-top-button-copy opacity-50 disabled" : "banner-top-button-copy "} disabled={isloading}>Submit</button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>


      </div>

      {/* modal success */}
      <div className='submit'>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center">
              <div className="modal-body m-auto w-100">
                <div className='d-flex justify-content-center align-items-center'>
                  <img className="banner-img1 black-logo" src={black} alt="logo" />
                  <img className="banner-img1 white-logo" src={white} alt="logo" />
                </div>
                <span className='pop-three-one my-1'>Thank You !</span>
                <p className='my-1'>Our team will get in touch with you soon to help you out further.</p>
                {Array.isArray(medialinks) ? (
                  medialinks.map((data, index) => (
                    <div className='d-flex align-items-center justify-content-center gap-3 py-3' key={index}>
                      <div className=''>
                        <a href={`${data.Telegram}`} target="_blank"><FaTelegramPlane className='submit-icon' /></a>
                      </div>
                      <div className=''>
                        <a href={`${data.Whatsapp}`} target="_blank"><FaWhatsapp className='submit-icon' /></a>
                      </div>
                      <div className=''>
                        <a href={`${data.Twitter}`} target="_blank"><BsTwitterX className='submit-icon' /></a>
                      </div>
                      <div className=''>
                        <a href={`${data.Instagram}`} target="_blank"><FaInstagram className='submit-icon' /></a>
                      </div>
                      <div className=''>
                        <a href={`${data.Youtube}`} target="_blank"><FaYoutube className='submit-icon' /></a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='d-flex align-items-center gap-2' >

                  </div>
                )}
                <div className='p-2'><button onClick={handleClosed} className='pop-btn-one'>Ok</button></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Influencerform