import { FaGripLines } from 'react-icons/fa';
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import Metalogo from "../../Assets/images/images-dash/meta-king-logo.png"

import $ from 'jquery'
import ToastApi from '../../config/toastApi';
import config from '../../config/apiConfig';
import { useState } from 'react';
import { FaRankingStar } from "react-icons/fa6";
import { SiLevelsdotfyi } from "react-icons/si";
import {  useNavigate } from 'react-router-dom';

var jq = $.noConflict();
jq(document).ready(function () {
    if (window.location.pathname === "/dashboard") {
        jq(".sidenavbar-active-dashboard").addClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/partners") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").addClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/links") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").addClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/stats") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").addClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/instructions") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").addClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/calculator") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").addClass("sidenavbar-active");
    }
});

function Sidenav() {
    const Navigate = useNavigate()
    const [locationState, setLocationState] = useState();

    const shiftPages = (urlpath) => {
        const condition = window.location.pathname.split('/')[1];


        if (urlpath == "dmlmxcoreinner") {
            const locateUserId = window.location.search.split("=")[1].split('&')[0];

            Navigate(config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + locateUserId)

            setLocationState(locateUserId)
        } else if (urlpath == 'instructions') {
            const locateUserId = window.location.pathname.split('/')[2];

            if (locateUserId == undefined) {
                const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
                window.location.href = config.WINDOW_LOCATION_INSTRUCTIONS + '/' + locateUserId1;

                // setLocationState(locateUserId)
            } else {
                window.location.href = config.WINDOW_LOCATION_INSTRUCTIONS + '/' + locateUserId;

                // setLocationState(locateUserId)
            }

        } else if (urlpath === 'Xcore') {
            const locateUserId = window.location.pathname.split('/')[2];
            if (locateUserId) {
                window.location.href = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + locateUserId;
            } else {
                const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
                window.location.href = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + locateUserId1;
            }

        } else if (urlpath === 'LevelIncome') {
            const locateUserId = window.location.pathname.split('/')[2];
            if (locateUserId) {
                window.location.href = config.WINDOW_LOCATION_LEVEL_INCOME_PAGE + locateUserId;
            } else {
                const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
                window.location.href = config.WINDOW_LOCATION_LEVEL_INCOME_PAGE + locateUserId1;
            }

        } else if (urlpath === 'RankIncome') {
            const locateUserId = window.location.pathname.split('/')[2];
            if (locateUserId) {
                window.location.href = config.WINDOW_LOCATION_RANKINCOME_ID + locateUserId;
            } else {
                const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
                window.location.href = config.WINDOW_LOCATION_RANKINCOME_ID + locateUserId1;
            }
        }  else if (urlpath == 'calculator') {
            const locateUserId = window.location.pathname.split('/')[2];
           
            if (locateUserId == undefined) {
                const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
                Navigate (config.WINDOW_LOCATION_CALCULATOR + '/' + locateUserId1)

                // setLocationState(locateUserId)
            } else {
                Navigate (config.WINDOW_LOCATION_CALCULATOR + '/' + locateUserId)

                // setLocationState(locateUserId)
            }

        } else {
            const locateUserId = window.location.pathname.split('/')[2];

            if (locateUserId == undefined) {
                const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
                Navigate(config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + locateUserId1)

                setLocationState(locateUserId)
            } else {
                Navigate(config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + locateUserId)


                setLocationState(locateUserId)
            }
        }
    }

    return (
        <div className='side-nav'>
            <ul className="navbar-nav pt-5">
                <li className="sidenavbar-nav-link sidenavbar-active sidenavbar-active-dashboard">
                    <a className="" onClick={() => shiftPages("dashboard")}>
                        <div className="d-flex flex-row align-items-center">
                            <div className="sidenav-icon-1">
                                <BsFillGrid1X2Fill />
                            </div>
                            <div>
                                <span className="sidenav-text-1">Dashboard</span>
                            </div>
                        </div>
                    </a>
                </li>

                <li className="sidenavbar-nav-link-2 ">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button accordion-button-2" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="sidenav-icon-1">
                                            <i className="fa-sharp fa-solid fa-people-group"></i>
                                        </div>
                                        <div>
                                            <span className="sidenav-text-1">Programs</span>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul className="navbar-nav">
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-partners">
                                            <a className="" onClick={() => shiftPages("Xcore")}>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-user-group"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">xCore</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-links">
                                            <a className="" onClick={() => shiftPages("LevelIncome")}>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <SiLevelsdotfyi className='mb-1' />
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Level Income</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-stats">
                                            <a className="" onClick={() => shiftPages("RankIncome")}>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <FaRankingStar className='mb-1' />
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Rank Income</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="sidenavbar-nav-link-2 ">
                    <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button  accordion-button-2" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="sidenav-icon-1">
                                            <i className="fa-solid fa-book-open"></i>
                                        </div>
                                        <div>
                                            <span className="sidenav-text-1">Information</span>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show"
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                <div className="accordion-body">

                                    <ul className="navbar-nav">
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-instructions">
                                            <a className="" onClick={() => shiftPages("instructions")}>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-shield-halved"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Instruction</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-calculator">
                                            <a className="" onClick={() => shiftPages("calculator")}>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-calculator"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Calculator</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Sidenav;
