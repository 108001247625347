import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarInfo from "./information/NavbarInfo";
import InstructionInfo from "./information/InstructionInfo";
import CalculatorInfo from "./information/CalculatorInfo";






function Information() {

    const [information, setInformation] = useState(true)
   


    return (
        <div className='information'>
            <ToastContainer/>
            <NavbarInfo/>
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <div className='side-nav'>
                                <ul className="navbar-nav pt-5">
                                    <li className="sidenavbar-nav-link-2 ">
                                        <div className="accordion" id="accordionExample1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button  accordion-button-2" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                        aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="sidenav-icon-1">
                                                                <i className="fa-solid fa-book-open"></i>
                                                            </div>
                                                            <div>
                                                                <span className="sidenav-text-1">Information</span>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse show"
                                                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                                    <div className="accordion-body">

                                                        <ul className="navbar-nav">
                                                            <li className="sidenavbar-nav-link-2 sidenavbar-active-instructions">
                                                                <a className="">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="sidenav-icon-1">
                                                                            <i className="fa-solid fa-shield-halved"></i>
                                                                        </div>
                                                                        <div>
                                                                            <span className="sidenav-text-1" onClick={() => setInformation(true)}>Instruction</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="sidenavbar-nav-link-2 sidenavbar-active-calculator">
                                                                <a className="">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="sidenav-icon-1">
                                                                            <i className="fa-solid fa-calculator"></i>
                                                                        </div>
                                                                        <div>
                                                                            <span className="sidenav-text-1" onClick={() => setInformation(false)}>Calculator</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            {information && information ? (<InstructionInfo/>)
                                : (<CalculatorInfo/>)}
                        </div>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default Information