import React, { useEffect, useState } from "react";
import "../../Assets/Css/dashboard-style.css";

import Topnav from "../../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../../Dashboard-pages/Separate/Side-nav";
import $ from "jquery";
import { BsInfoCircleFill } from "react-icons/bs";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import { FaUserCircle } from "react-icons/fa";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import NavbarInfo from './NavbarInfo';

function CalculatorInfo() {

    var jq = $.noConflict();

    const [slotval, setSlotval] = useState(0);
    const [Cycleval, setCycleval] = useState(0);
    const [activeId, setActiveId] = useState(null);
    const [onecard, setOnecard] = useState(0);

    const levelIncomePercentages = [10, 10, 10, 10, 10, 20, 30];
    const cardPrices = [6.75, 13.5, 45, 90, 135, 225, 450, 675, 1125, 2250];

    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedLevels, setSelectedLevels] = useState([]);

    const [selectedPercentages, setSelectedPercentages] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [checkxcore, setCheckxcore] = useState(true);

    jq(document).ready(function () {
        jq(".thirdbtn0").click(function () {
            jq(this).addClass("getting1");
            jq(this).prevAll().addClass("getting1");
            jq(this).nextAll().removeClass("getting1");
        });
    });

    $(document).ready(function () {
        $(".thirdbtn1").click(function () {
            $(".thirdbtn1").removeClass("getting");
            $(this).addClass("getting");
        });
    });

    function LevelRate(level) {
        let levelRate = [15, 30, 100, 200, 300, 500, 1000, 1500, 2500, 5000];
        let totalRate = 0;

        for (let index = 0; index <= level - 1; index++) {
            const element = levelRate[index];
            totalRate += element;
        }

        return totalRate;
    }

    function CycleResult(level) {
        let levelRate = [15, 30, 100, 200, 300, 500, 1000, 1500, 2500, 5000];
        let totalresult = 0;

        for (let i = 0; i <= level - 1; i++) {
            const value = levelRate[i];
            // console.log("value",value)
            const rewardper = (value * 45) / 100;

            const resultofcycle = (rewardper / 2) * 12;
            // console.log("resultexternal",resultexternal);

            totalresult += resultofcycle;
        }
        // console.log("totalresult", totalresult);

        return totalresult;
    }

    const XcoreCalculate = async (level) => {
        try {
            var lvlRs = 0;
            var cycleResult;

            switch (level) {
                case 1:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 2:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 3:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 4:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 5:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 6:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 7:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 8:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 9:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                case 10:
                    lvlRs = LevelRate(level);
                    cycleResult = CycleResult(level);

                    setSlotval(lvlRs);
                    setCycleval(cycleResult);
                    break;
                default:
                    console.log("level is not here");

                    setSlotval(0);
                    setCycleval(0);
                    break;
            }
        } catch (error) { }
    };

    function PerSlotCost(ind) {
        if (ind >= 0) {
            switch (ind) {
                case 0:
                    setOnecard(15);
                    break;
                case 1:
                    setOnecard(30);
                    break;
                case 2:
                    setOnecard(100);
                    break;
                case 3:
                    setOnecard(200);
                    break;
                case 4:
                    setOnecard(300);
                    break;
                case 5:
                    setOnecard(500);
                    break;
                case 6:
                    setOnecard(1000);
                    break;
                case 7:
                    setOnecard(1500);
                    break;
                case 8:
                    setOnecard(2500);
                    break;
                case 9:
                    setOnecard(5000);
                    break;
                default:
                    setOnecard(0);
                    break;
            }
        } else {
            console.log("value is not");
            setOnecard(0);
        }
    }

    const animateButtons = () => {
        let ids = ["id7", "id6", "id5", "id4", "id3", "id2", "id1", "0"];
        ids.forEach((id, index) => {
            setTimeout(() => {
                setActiveId(id);
            }, index * 500);
        });
    };
    const levelCalc = (e) => {
        console.log("levelcalc :>> ");
        console.log("e :>> ", e);
    };

    const handleCardClick = (index) => {
        setSelectedCard(index + 1);
        setSelectedAmount(cardPrices[index]);
        setSelectedPercentages(
            levelIncomePercentages.map(
                (percent) => (cardPrices[index] * percent) / 100
            )
        );
        setAnimate(!animate);
        PerSlotCost(index);
        setCheckxcore(false);
    };
    useEffect(() => {
        if (animate) {
            animateButtons();
        }
    }, [animate]);

    return (
        <div className='CalculatorInfo'>
            <NavbarInfo />
            <section className="dashboard-split-section">
                <div className="container calculator">
                    <div className="row">
                        
                        <div className="col-lg-12 dashboard-split-right-section dashboard-calculator-section">
                            <div className="row text-center">
                                <div className="col-lg-12 pt-5 dashbanner1">
                                    <h4>D-Chain Participant</h4>
                                    <h3 className="textwhite">calculator</h3>
                                    <p className="pt-3 m-auto mb-3 opacity-50 textwhite mb-lg-5 w-75">
                                        Calculate your potential result from participating in
                                        D-Chain by selecting levels to activate below. The results
                                        are calculated for 1 cycle of all selected levels. All
                                        calculations are for informational purposes only, and are
                                        not a public offer.
                                    </p>
                                </div>
                            </div>

                            <ul
                                class="nav nav-pills m-lg-3 gap-4"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link active"
                                        id="pills-xcore-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-xcore"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-xcore"
                                        aria-selected="true"
                                        onClick={() => setCheckxcore(true)}
                                    >
                                        xCore
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link"
                                        id="pills-levelincome-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-levelincome"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-levelincome"
                                        aria-selected="false"
                                        onClick={handleCardClick}
                                    >
                                        Level Income
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div
                                    class="tab-pane fade show active"
                                    id="pills-xcore"
                                    role="tabpanel"
                                    aria-labelledby="pills-xcore-tab"
                                    tabindex="0"
                                >
                                    <div className="row mt-5 bg-dark py-5 px-3  mx-auto dashrounding">
                                        <div className="col-lg-12">
                                            <div className="row d-flex align-items-center ">
                                                <div className="col-lg-4 col-md-4 col-12 d-flex align-items-center  dashbanner2">
                                                    <h2 className="logo px-2 py-3 mt-2">xCore</h2>
                                                    <h2 className="ps-3">PROPELX</h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 px-3">
                                                    <p className="dashbanner20 opacity-50 textwhite">
                                                        Program with enhanced team building and development
                                                        opportunities.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-5 mt-3 d-flex align-items-center gy-3">
                                                <div className="col-lg-4 col-md-5 col-12">
                                                    <div className="calculatingtab">
                                                        <button
                                                            className="thirdbtn0 me-2 mt-3"
                                                            id="unique20"
                                                            onClick={() => XcoreCalculate(1)}
                                                        >
                                                            1
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique21"
                                                            onClick={() => XcoreCalculate(2)}
                                                        >
                                                            2
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique22"
                                                            onClick={() => XcoreCalculate(3)}
                                                        >
                                                            3
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique23"
                                                            onClick={() => XcoreCalculate(4)}
                                                        >
                                                            4
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique24"
                                                            onClick={() => XcoreCalculate(5)}
                                                        >
                                                            5
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique25"
                                                            onClick={() => XcoreCalculate(6)}
                                                        >
                                                            6
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique26"
                                                            onClick={() => XcoreCalculate(7)}
                                                        >
                                                            7
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique27"
                                                            onClick={() => XcoreCalculate(8)}
                                                        >
                                                            8
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique28"
                                                            onClick={() => XcoreCalculate(9)}
                                                        >
                                                            9
                                                        </button>
                                                        <button
                                                            className="thirdbtn0  me-2 mt-3"
                                                            id="unique29"
                                                            onClick={() => XcoreCalculate(10)}
                                                        >
                                                            10
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 ">
                                                    <div className="dashbannerchild1">
                                                        <p className="m-auto opacity-50 textwhite">
                                                            Cost of all selected slots
                                                        </p>
                                                        <h5 className="fw-bold mt-2 dashbannerchild0">
                                                            {slotval} DUSD
                                                        </h5>
                                                    </div>
                                                    <div className="dashbannerchild2 pt-3">
                                                        <p className="m-auto opacity-50 textwhite">
                                                            Results in 1 cycle
                                                        </p>
                                                        <h3 className="dashbannerchild01 mt-3">
                                                            {Cycleval} DUSD
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="pills-levelincome"
                                    role="tabpanel"
                                    aria-labelledby="pills-levelincome-tab"
                                    tabindex="0"
                                >
                                    <div className="row mt-5 bg-dark py-5 px-3  mx-auto dashrounding">
                                        <div className="col-lg-12">
                                            <div className="row d-flex align-items-center ">
                                                <div className="col d-flex align-items-center  dashbanner2">
                                                    <h2 className="logo px-2 py-3 mt-2">Level   </h2>
                                                    <h2 className="ps-3">Level Income</h2>
                                                </div>
                                                <div className="col">
                                                    <div className="dashbannerchild1">
                                                        <p className="m-auto opacity-50 textwhite">
                                                            Cost of selected slots
                                                        </p>
                                                        <h5 className="fw-bold mt-2 dashbannerchild0">
                                                            {onecard} DUSD
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    {/* <div className="dashbannerchild2 pt-3">
                                                        <p className="m-auto opacity-50 textwhite">
                                                            Results in 1 cycle
                                                        </p>
                                                        <h5 className="dashbannerchild01">0 DUSD</h5>
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className="row mt-lg-2 mt-3 d-flex gy-3">
                                                <div className="col-12 col-lg-4">
                                                    <div className="d-flex flex-wrap">
                                                        {cardPrices.map((price, index) => (
                                                            <div className="calculatingtab" key={index}>
                                                                <button
                                                                    className={`thirdbtn1 me-2 mt-3 ${selectedCard === index + 1 ? "getting" : ""
                                                                        }`}
                                                                    id="unique"
                                                                    onClick={() => handleCardClick(index)}
                                                                >
                                                                    {index + 1}
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="info-section mt-lg-5 mt-3">
                                                        <p className="mb-1">
                                                            Note
                                                            <BsInfoCircleFill className="ms-1 info-btn" />
                                                        </p>
                                                        <p className="mb-1">
                                                            Let's Imagine for a user joining below
                                                        </p>
                                                        <p className="pt-3 mb-1 text-center">
                                                            <div className="calculatingtab">
                                                                <div className="d-flex align-items-center flex-column justify-content-start">
                                                                    <div><button className="id-btn" id="id6"><FaUserCircle className="info-icon" />7</button></div>
                                                                    <div className="down-icon"><FaArrowAltCircleDown className="" /></div>
                                                                    <div><button className="info-id-btn blink-soft" id="id7"><FaUserCircle className="info-icon" />New ID</button></div>
                                                                </div>
                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-8">
                                                    <div className="levelincome-table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Amount</th>
                                                                    <th>Level Income (Down Line)</th>
                                                                    <th>Percentage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {[...Array(7)].map((_, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {selectedPercentages[index]
                                                                                ? selectedPercentages[index]?.toFixed(3)
                                                                                : "0"}
                                                                            {"   "}
                                                                            DUSD
                                                                        </td>
                                                                        <td>
                                                                            <div className="calculatingtab d-flex align-items-center justify-content-center flex-column">
                                                                                <div><button
                                                                                    className={`id-btn ${activeId === `id${index + 1}`
                                                                                        ? "getting-level"
                                                                                        : ""
                                                                                        }`}
                                                                                    id={`id${index + 1}`}
                                                                                >
                                                                                    <FaUserCircle className="user-icon" />
                                                                                    {index + 1}
                                                                                </button></div>

                                                                                <div className="down-icon">
                                                                                    <FaArrowAltCircleUp className="" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {index < 5
                                                                                ? "10 %"
                                                                                : index === 5
                                                                                    ? "20 %"
                                                                                    : "30 %"}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    <td></td>
                                                                    <td>

                                                                        <button className="info-id-btn" id="id7">
                                                                            <FaUserCircle className="info-icon" />
                                                                            New ID
                                                                        </button>

                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {/* <div className="col-12 col-lg-4">
                                                    <h5 className="position fw-bold">Your Position</h5>
                                                    <div className="d-flex flex-wrap">

                                                        <div className="calculatingtab" >
                                                            <button className='thirdbtn1 me-2 mt-3'>1</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>2</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>3</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>4</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>5</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>6</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>7</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>8</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>9</button>
                                                            <button className='thirdbtn1 me-2 mt-3'>10</button>
                                                        </div>

                                                    </div>
                                                    <div className="d-flex align-items-center gap-5 pt-5">
                                                        <div className="">
                                                            <h5 className="">calculate</h5>
                                                            <div className="">10</div>
                                                        </div>
                                                        <div className="">
                                                            <h5 className="">calculate</h5>
                                                            <div className="">10</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {checkxcore ? (
                                <div className="row  mx-auto fourthbanner mt-5">
                                    <div className="col-lg-6 py-lg-5 py-1">
                                        <div className="row py-lg-3 py-1">
                                            <div className="col-lg-4 fourthbannerchild1 m-auto">
                                                <p>Total cost</p>
                                                <h4>{slotval} DUSD</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 py-lg-5 py-1">
                                        <div className="row py-lg-3 py-1">
                                            <div className="col-lg-4 fourthbannerchild2 m-auto">
                                                <p>Total profit</p>
                                                <h4>{Cycleval - slotval} DUSD</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CalculatorInfo