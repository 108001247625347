import "../Assets/Css/dashboard-style.css"

import Topnav from "./Separate/Top-nav";
import Sidenav from "./Separate/Side-nav";

import busd from "../Assets/images/dusd.png"

function Dmlmgoldinner() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <p className="mb-3 dashboard-text-6"><span className="mb-0 dashboard-text-4">ID 1 /
                                        D-MLM gold
                                        (10 out of 10 levels)</span> / level 1</p>
                                    <div className="d-flex flex-lg-row flex-column">
                                        <div>
                                            <p className="dashboard-text-2 mb-0">Level 1</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4">
                                <div className="col-lg-2 col-6 order-lg-1 order-1">
                                    <div className="card dashboard-gold-inner-card-1 h-100">
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </div>
                                </div>
                                <div className="col-lg-8 order-lg-2 order-3">
                                    <div className="card dashboard-gold-inner-card-2">
                                        <div className="d-flex flex-row justify-content-between">
                                            <p className="dashboard-text-2 mb-0" style={{ opacity: "0.6" }}>Lvl 1</p>
                                            <p className="dashboard-text-2 mb-0">ID 1</p>
                                            <p className="dashboard-text-2 mb-0"><img className="dashboard-gold-inner-img-1"
                                                src={busd} alt="logo" /> <span
                                                    style={{ opacity: "0.8" }}>1</span></p>
                                        </div>
                                        <div>
                                            <div className="grid-dashboard-gold-inner-l1-container mt-4">
                                                <div
                                                    className="dashboard-user-gold-inner-l1-programs dashboard-user-gold-inner-programs-active ">
                                                    1373219
                                                </div>
                                                <div className="dashboard-user-gold-inner-l1-programs"></div>
                                            </div>
                                            <div className="grid-dashboard-gold-l2-container mt-4">
                                                <div
                                                    className="dashboard-user-gold-inner-l2-programs dashboard-user-gold-inner-programs-active ">
                                                    1373219
                                                </div>
                                                <div
                                                    className="dashboard-user-gold-inner-l2-programs dashboard-user-gold-inner-programs-active">
                                                    1373219</div>
                                                <div className="dashboard-user-gold-inner-l2-programs"></div>
                                                <div className="dashboard-user-gold-inner-l2-programs"></div>
                                            </div>
                                            <div className="grid-dashboard-gold-inner-l3-container mt-4">
                                                <div
                                                    className="dashboard-user-gold-inner-l3-programs dashboard-user-gold-inner-programs-active ">
                                                    1373219</div>
                                                <div
                                                    className="dashboard-user-gold-inner-l3-programs dashboard-user-gold-inner-programs-active ">
                                                    1373219</div>
                                                <div className="dashboard-user-gold-inner-l3-programs"></div>
                                                <div className="dashboard-user-gold-inner-l3-programs"></div>
                                                <div className="dashboard-user-gold-inner-l3-programs"></div>
                                                <div className="dashboard-user-gold-inner-l3-programs"></div>
                                                <div className="dashboard-user-gold-inner-l3-programs"></div>
                                                <div className="dashboard-user-gold-inner-l3-programs"></div>
                                            </div>
                                            <div className="grid-dashboard-gold-inner-l4-container mt-4">
                                                <div
                                                    className="dashboard-user-gold-inner-l4-programs dashboard-user-gold-inner-programs-active ">
                                                    <span className="grid-dashboard-gold-inner-l4-user-icon"><i
                                                        className="fa-solid fa-user"></i></span>
                                                    <span className="grid-dashboard-gold-inner-l4-user-id">1373219</span>
                                                </div>
                                                <div
                                                    className="dashboard-user-gold-inner-l4-programs dashboard-user-gold-inner-programs-active ">
                                                    <span className="grid-dashboard-gold-inner-l4-user-icon"><i
                                                        className="fa-solid fa-user"></i></span>
                                                    <span className="grid-dashboard-gold-inner-l4-user-id">1373219</span>
                                                </div>
                                                <div
                                                    className="dashboard-user-gold-inner-l4-programs dashboard-user-gold-inner-programs-active ">
                                                    <span className="grid-dashboard-gold-inner-l4-user-icon"><i
                                                        className="fa-solid fa-user"></i></span>
                                                    <span className="grid-dashboard-gold-inner-l4-user-id">1373219</span>
                                                </div>
                                                <div
                                                    className="dashboard-user-gold-inner-l4-programs dashboard-user-gold-inner-programs-active ">
                                                    <span className="grid-dashboard-gold-inner-l4-user-icon"><i
                                                        className="fa-solid fa-user"></i></span>
                                                    <span className="grid-dashboard-gold-inner-l4-user-id">1373219</span>
                                                </div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                                <div className="dashboard-user-gold-inner-l4-programs"></div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-lg-row mt-5 gap-4">
                                            <div>
                                                <p className="mb-1">Partners</p>
                                                <p className="mb-0"><i className="fa-solid fa-user-group me-2"></i>3731</p>
                                            </div>
                                            <div>
                                                <p className="mb-1">Cycles</p>
                                                <p className="mb-0"><i className="fa-solid fa-arrows-rotate me-2"></i>3731</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <p className="mb-1">Total level revenue</p>
                                                <p className="mb-0"><i className="fa-solid fa-wallet me-2"></i>3731</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6 order-lg-3 order-2">
                                    <div className="card dashboard-gold-inner-card-1 h-100">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4 justify-content-center">

                                <div className="col-lg-8">
                                    <div className="card dashboard-gold-inner-card-3">
                                        <div className="d-flex flex-row justify-content-center gap-4">
                                            <div className="">
                                                <a href="#"><p className="mb-0 dashboard-text-4">1 <i className="fa-solid fa-chevron-down ms-1"></i>
                                                </p></a>
                                            </div>
                                            <div className="">
                                                <p className="mb-0 dashboard-text-4">Cycle: 2
                                                </p>
                                            </div>
                                            <div className="">
                                                <a href="#"><p className="mb-0 dashboard-text-4">2 <i className="fa-solid fa-chevron-up ms-1"></i>
                                                </p></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>






                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="card dashboard-x3-card-2 mt-4">
                                        <table className="table">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Level</th>
                                                    <th scope="col">Wallet</th>
                                                    <th scope="col">DUSD</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 DUSD</td>
                                                </tr>

                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 DUSD</td>
                                                </tr>


                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 DUSD</td>
                                                </tr>


                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 DUSD</td>
                                                </tr>


                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent textwhite"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 DUSD</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button className="btn dashboard-activity-seemore-btn mt-5 py-2" type="button"><i
                                            className="fa-solid fa-eye"></i> See more</button>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Dmlmgoldinner;