import React, { useState, useEffect } from "react";
import Web3 from 'web3';
import config from '../config/apiConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import $ from 'jquery';
import Plan1Abi from '../ABI/Plan1Abi.json';
import { MdSwapVert } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";


import { el } from "date-fns/locale";

$(function () {
    $('.genealogy-tree ul').hide();
    $('.genealogy-tree>ul').show();
    $('.genealogy-tree ul.active').show();
    $('.genealogy-tree li').on('click', function (e) {
        var children = $(this).find('> ul');
        if (children.is(":visible")) children.hide('fast').removeClass('active');
        else children.show('fast').addClass('active');
        e.stopPropagation();
    });
});

function Levelincome() {
    const [firstEightWalletAddress, setFirstEightWalletAddress] = useState([]);
    const [copyStatus, setCopyStatus] = useState({});
    const [userWalletAddress, setUserWalletAddress] = useState();
    const [isCopied, setIsCopied] = useState(false);
    const [xCoreAmountFromTheUser, setxCoreAmountFromTheUser] = useState(0);
    const [levelIncomeAmountFromTheUser, setLevelIncomeAmountFromTheUser] = useState(0);

    const locateUserId = window.location.pathname.split('/')[2];

    var web3js = '';

    if (window.ethereum) {
        // web3js = new Web3(window.ethereum);
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));

    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }

    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);
    const xCoreContract = new web3js.eth.Contract(Plan1Abi, config.PLAN_1_CONTRACT_ADDRESS);

    const getTheUserWalletAddress = async () => {
        try {

            const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
            //   setReferralAddress(checkUserRegisteredExists);



            const checkReferralUserDetails = await DemoCorePlanContract.methods.users(checkUserRegisteredExists).call();

            const checkReferralIDWithAddressUserDetails = await DemoCorePlanContract.methods.users(checkReferralUserDetails.referrer).call();
            const objAddress = await convertBigIntToString(checkReferralIDWithAddressUserDetails);
            console.log('objAddress.id----', objAddress.id);
            //   setReferredByUserId(objAddress.id);
            const xCoreAmount = await DemoCorePlanContract.methods.userxcoreincome(checkUserRegisteredExists).call();
            const levelIncomeAmount = await xCoreContract.methods.userlevelincome(checkUserRegisteredExists).call();
            console.log('xCoreAmount----', xCoreAmount, levelIncomeAmount);
            const xCorefromWeiAmount = web3js.utils.fromWei(xCoreAmount, 'ether');
            console.log("xCorefromWeiAmount", xCorefromWeiAmount);
            const levelIncomeFromWeiAmount = web3js.utils.fromWei(levelIncomeAmount, 'ether');
            console.log("levelIncomeFromWeiAmount", levelIncomeFromWeiAmount);
            if (xCorefromWeiAmount && levelIncomeFromWeiAmount) {
                setxCoreAmountFromTheUser(xCorefromWeiAmount);
                setLevelIncomeAmountFromTheUser(levelIncomeFromWeiAmount);
            } else {
                setxCoreAmountFromTheUser(0);
                setLevelIncomeAmountFromTheUser(0);
            }

        } catch (error) {
            console.error('Error in getTheUserWalletAddress:', error);
        }
    };

    const convertBigIntToString = async (obj) => {
        if (typeof obj === 'bigint') {
            return obj.toString();
        } else if (typeof obj === 'object') {
            for (const key in obj) {
                obj[key] = await convertBigIntToString(obj[key]);
            }
        }

        return obj;
    };

    // Call the async function
    getTheUserWalletAddress();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
                const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);

                const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
                if (config.REGISTRATION_ZERO_ADDRESS !== checkUserRegisteredExists) {
                    const findTheGroupOfReferredID1 = await DemoCorePlanContract.methods.getindirectref(checkUserRegisteredExists).call();

                    const findTheGroupOfReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredExists).call();
                    console.log("findTheGroupOfReferredID", findTheGroupOfReferredID.toString());
                    // let element = findTheGroupOfReferredID.toString();
                    // let arrayall = [];
                    // arrayall.push(element); // Push the initial element

                    // for (let i = 1; i <= 7; i++) { // Reduced to 6 iterations since you've already pushed one element
                    //     try {
                    //         const findTheGroupOfReferredIDs = await DemoCorePlanContract.methods.directreferral(element).call();
                    //         element = findTheGroupOfReferredIDs.toString(); // Convert to string if it's not already
                    //         if (element === "0x0000000000000000000000000000000000000000") {
                    //             break; // Break the loop if the element is empty (assuming it's a valid condition)
                    //         }
                    //         arrayall.push(element);
                    //         console.log("element", element);
                    //     } catch (error) {

                    //     }
                    // }

                    // console.log("arrayall", arrayall.length);
                    // arrayall.forEach((element, index) => {
                    //     const addresses = element.split(',');
                    //      console.log("forarrayall---",addresses);
                    // });
                    const firstEightAddresses = findTheGroupOfReferredID.slice(0, 7);

                    setFirstEightWalletAddress(firstEightAddresses);
                    setUserWalletAddress(checkUserRegisteredExists);
                } else {

                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // console.log('firstEightWalletAddress---', firstEightWalletAddress);
    }, []);

    const sliceAddress = (address, startLength = 6, endLength = 6) => {
        try {
            if (!address) return '';
            const prefix = address.slice(0, startLength);
            const suffix = address.slice(-endLength);
            return `${prefix}...${suffix}`;
        } catch (error) {

            // console.error('Error slicing address:', error);
            return '';
        }
    };

    const handleCopyClick = async (wallet) => {
        try {
            await navigator.clipboard.writeText(wallet);
            setCopyStatus((prevCopyStatus) => ({
                ...prevCopyStatus,
                [wallet]: true,
            }));
            toast.success(ToastApi.CopiedAddress, { autoClose: 2000 });
        } catch (error) {
            toast.error(ToastApi.FailedToCopy);
        }
    };

    const handleUserWalletCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(userWalletAddress);
            setIsCopied(true);
            toast.success(ToastApi.CopiedAddress, { autoClose: 2000 });
        } catch (error) {
            toast.error(ToastApi.FailedToCopy);
        }
    };



    return (
        <div className="level-income">
            {/* <ToastContainer limit={1} /> */}

            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12 text-center">
                                    {/* <p className="mb-3 dashboard-text-6"><span className="mb-0 dashboard-${config.WINDOW_LOCATION_LEVEL}text-4">ID {locateUserId} /</span>
                                        PropelX Level Income
                                        ( out of 10 )</p> */}
                                    <div className="d-flex flex-lg-row flex-column my-3 my-lg-0">
                                        <div>
                                            <p className="dashboard-text-2 mb-0">PropelX Level Income</p>
                                        </div>

                                        <div className="ms-lg-auto">
                                            <p className="dashboard-text-2 mb-0"> {Number(levelIncomeAmountFromTheUser)} DUSD</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className=" genealogy-body genealogy-scroll">
                                        <div className="genealogy-tree">
                                            {firstEightWalletAddress.length === 0 ? (
                                                <p>Record Not Found</p>
                                            ) : (
                                                <>
                                                    <ul className="active">
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <div className="member-view-box">
                                                                    <div className="member-image-top">
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div> <IoMdPerson className="fs-4"/></div>
                                                                            <div className="id-value">ID {locateUserId}</div>
                                                                        </div>
                                                                        <div className="d-flex flex-row">
                                                                            <div>
                                                                                <span>Address : </span><br />
                                                                            </div>
                                                                            <div className="ms-auto text-end">
                                                                                <span className="">
                                                                                    {sliceAddress(userWalletAddress)}
                                                                                    <button
                                                                                        className="bg-transparent border-0 ms-1"
                                                                                        onClick={handleUserWalletCopyClick}
                                                                                        disabled={isCopied}

                                                                                    >
                                                                                        <i className="fa-solid fa-copy ms-2 address-copy"></i>
                                                                                    </button>
                                                                                </span>
                                                                                <br />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    {firstEightWalletAddress.map((wallet, index) => (
                                                        wallet !== config.REGISTRATION_ZERO_ADDRESS ? (
                                                            <React.Fragment key={index}>
                                                                <MdSwapVert className="fs-5" />
                                                                <ul className="active">
                                                                    <li>
                                                                        <a href="javascript:void(0);">
                                                                            <div className="member-view-box">
                                                                                <div className="member-image">
                                                                                    <div className="d-flex flex-row">
                                                                                        <div>
                                                                                            <span>Address : </span><br />
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <span className="">
                                                                                                {sliceAddress(wallet)}
                                                                                                <button
                                                                                                    className="bg-transparent border-0 ms-1"
                                                                                                    onClick={() => handleCopyClick(wallet)}
                                                                                                    disabled={copyStatus[wallet]}

                                                                                                >
                                                                                                    <i className="fa-solid fa-copy ms-2 address-copy"></i>
                                                                                                </button>
                                                                                            </span>
                                                                                            <br />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </React.Fragment>
                                                        ) : null
                                                    ))}
                                                </>
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Levelincome;
