import { sub } from "date-fns";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Window_Ethereum_Config_Api from '../../config/windowEthereumConfig';
import Web3 from "web3";
import { Addcareerdata, getAddressCareer } from "../api/authapis"
import ToastApi from '../../config/toastApi';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../config/apiConfig';
import DemoCorePlanAbi from '../../ABI/DemoCorePlan.json';
import DUSDtokenAbi from '../../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../../ABI/Plan1Abi.json';
import { BsFillPersonPlusFill } from "react-icons/bs";
import fireone from "../../Assets/images/fireone.gif"


function Careerform({ address }) {

    const [show, setShow] = useState(false);

    const [userAddress, setUserAddres] = useState(null);
    const [position, setPosition] = useState('');
    const [showOtherField, setShowOtherField] = useState(false);
    const [email, setEmail] = useState('')
    const [category, setCategory] = useState('')
    const [salary, setSalary] = useState('')
    const [otherpositons, setOtherpositons] = useState('')
    const [errors, setErrors] = useState({});
    const [submit, setSubmit] = useState(false);
    const [exit, setExit] = useState(false)
    const [isdisble, setIsdisle] = useState(false)

    const submitClose = () => setSubmit(false);
    const submitShow = () => setSubmit(true);

    const ExitClose = () => setExit(false);
    const ExitShow = () => setExit(true);


    const handleClose = () => {
        setShow(false)
        setPosition("")
        setEmail("")
        setCategory("")
        setOtherpositons("")
        setSalary("")
        setErrors({})
    };
    const handleShow = async () => setShow(true)


    const Modelopen = async () => {
        try {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                setUserAddres(accounts?.[0] || null);
            }

            if (!userAddress) {
                // Show a toast when the wallet is not connected
                toast.error(ToastApi.walletConnectText);
                return;
            }

            const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(userAddress).call();
            const locateUserId = window.location.pathname.split('/')[2];
            if (checkUserRegisteredExists) {
                const checkRegisteredIds = await DemoCorePlanContract.methods.users(userAddress).call();
                if (locateUserId !== checkRegisteredIds.id.toString()) {
                    setInterval(() => {
                    window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + checkRegisteredIds.id.toString()
                    }, 500);
                    return;
                }
            } else {
                setInterval(() => {
                    window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
                }, 500);
                return
            }


            if (userAddress) {
                const resultData = await getAddressCareer(userAddress);
                if (resultData.status === true) {
                    //    model open already added
                    return ExitShow()
                }
            }
            await handleShow();

        } catch (error) {
            console.log("Modelopen Error:", error);
        }
    }





    var web3js = '';

    if (window.ethereum) {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    } else {
        web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
    }
    const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
    const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);
    const xCoreContract = new web3js.eth.Contract(Plan1Abi, config.PLAN_1_CONTRACT_ADDRESS);


    // const handlePositionChange = (event) => {
    //     const selectedPosition = event.target.value;
    //     setPosition(selectedPosition);
    //     setShowOtherField(selectedPosition === 'other');
    // };

    const validateForm = (inputValues) => {
        const newErrors = {};

        if (!inputValues.email) {
            newErrors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputValues.email)) {
            newErrors.email = 'Email is invalid';
        }

        if (!inputValues.position) {
            newErrors.position = 'Position is required';
        }

        if (position === 'other' && !inputValues.otherpositons) {
            newErrors.otherpositions = 'Please specify the position';
        }

        if (!inputValues.salary) {
            newErrors.salary = 'Salary is required';
        }

        if (!inputValues.category) {
            newErrors.category = 'Job category is required';
        }

        // setErrors(newErrors);
        return newErrors;
    };

    const handlechange = (event) => {
        try {
            const { name, value } = event.target;
            console.log("value", name, value);

            if (name === "email") {
                setEmail(value)
            }

            if (name === "position") {
                setShowOtherField(value === 'other');
                setPosition(value);
            }

            if (name === "otherpositons") {
                setOtherpositons(value)
            }

            if (name === "salary") {
                setSalary(value)
            }

            if (name === "flexRadiofulltime") {
                setCategory('Full time')
            } else if (name === "flexRadioparttime") {
                setCategory('Part time')
            }

            const inputValues = { email, position, category, salary, otherpositons, [name]: value };
            const validationErrors = validateForm(inputValues);
            console.log("validationErrors", validationErrors);

            setErrors((preverror) => ({
                ...preverror,
                ...validationErrors,
                [name]: validationErrors[name] || "",
                category: ""
            }))
        } catch (error) {
            console.log("handlechange Error:", error)
        }
    }


    const HandleSubmit = async (e) => {
        try {
            e.preventDefault();
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(userAddress).call();

            if (checkUserRegisteredExists) {
                const inputValues = { userAddress, email, position, category, salary, otherpositons }
                const fielderror = validateForm(inputValues)
                setErrors(fielderror);

                if (Object.keys(fielderror).length === 0) {
                    console.log("form submit successfully");
                    setIsdisle(true)
                    const Ondata = {
                        UserAddress: userAddress,
                        Email: email,
                        Position: position,
                        Salary: salary,
                        Jobtime: category,
                        Specifyposition: (otherpositons.trim() === "" ? "empty" : otherpositons.trim())
                    }

                    const response = await Addcareerdata(Ondata)
                    if (response.status === true) {
                        setIsdisle(false);
                        submitShow();
                        handleClose();
                    } else {
                        toast.warn("Error on Server");
                    }
                }
            } else {
                toast.error("must be Register ");
                setTimeout(() => {
                    window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
                }, 1000);
            }


        } catch (error) {
            console.log("HandleSubmit Error", error);
        }
    }

    const checkConnectedWallet = async () => {
        try {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
                // console.log("accounts", accounts?.[0]);

                setUserAddres(accounts?.[0] || null);
            }
            else {
                console.log("Metamask not install");
            }
        } catch (error) {
            console.error('Error checking connected wallet:', error);
        }
    };


    useEffect(() => {
        checkConnectedWallet()
    }, [userAddress])

    return (
        <div className='Careerform'>

            <Button className="career-btn" onClick={Modelopen}>
            {/* <BsFillPersonPlusFill className="me-1 mb-1"/> */}
            <img src={fireone} className="fire-css"/>Career
            </Button>

            {/* Career Modal */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="career-modal"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="mx-auto">Exciting Career Opportunities</Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0">
                    <div className="text-one pb-4">
                        Welcome to <span className="text-y-class">PropelX Career!</span> As a valued member of our network marketing team,
                        we are dedicated to supporting your growth in the crypto industry.
                        Priority will be given to members with a PropelX card. When our partners or we have job
                        openings, we will promptly email you about available positions that match your interests.
                        Please fill out the form below to help us identify the best job opportunities for you.
                    </div>
                    <form onSubmit={HandleSubmit}>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email address <span className="man-star">*</span></label>
                            <input type="email" class="form-control" id="email" placeholder="e.g sample@gmail.com" name="email" value={email} onChange={handlechange} />
                            {errors.email ? (<div className="error-text">{errors.email}</div>) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="position" className="form-label">Position <span className="man-star">*</span></label>
                            <select
                                className="form-select"
                                id="position"
                                value={position}
                                name="position"
                                onChange={handlechange}
                            >
                                <option value="" disabled hidden>Select a position</option>
                                <option value="moderator">Moderator</option>
                                <option value="ambassador">Ambassador</option>
                                <option value="other">Other</option>
                            </select>
                            {errors.position && <div className="error-text">{errors.position}</div>}
                        </div>

                        {showOtherField && (
                            <div className="mb-3">
                                <label htmlFor="otherPosition" className="form-label">Please specify <span className="man-star">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="otherPosition"
                                    placeholder="Enter your position"
                                    name="otherpositons"
                                    value={otherpositons}
                                    onChange={handlechange}
                                />
                                {errors.otherpositions && <div className="error-text">{errors.otherpositions}</div>}
                            </div>
                        )}

                        <div class="mb-3">
                            <label for="email" class="form-label">Expected Salary <span className="man-star">*</span></label>
                            <input type="number" class="form-control" id="email" placeholder="Enter Salary" name="salary" value={salary} onChange={handlechange} />
                            {errors.salary && <div className="error-text">{errors.salary}</div>}
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Job Category <span className="man-star">*</span></label>
                            <div className="d-flex align-items-center gap-5">
                                <div class="form-check" >
                                    <input class="form-check-input" checked={category === 'Full time'} type="radio" name="flexRadiofulltime" id="fulltime" onChange={handlechange} />
                                    <label class="form-check-label" for="fulltime">
                                        Full Time
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" checked={category === 'Part time'} type="radio" name="flexRadioparttime" id="parttime" onChange={handlechange} />
                                    <label class="form-check-label" for="parttime">
                                        Part Time
                                    </label>
                                </div>
                            </div>
                            {errors.category && <div className="error-text">{errors.category}</div>}
                        </div>
                        <div className="text-center"><Button className="career-submit" type="submit" >Submit</Button></div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="border-0 mx-auto">
                    {/* <Button className="career-submit" onClick={submitShow}>Submit</Button> */}
                </Modal.Footer>
            </Modal>
            {/* Career Modal */}

            {/* Submit Modal */}
            <Modal
                show={submit}
                onHide={submitClose}
                backdrop="static"
                keyboard={false}
                className="submit-modal"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="mx-auto">Exciting Career Opportunities</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Submitted Successfully
                </Modal.Body>
                <Modal.Footer className="mx-auto border-0">
                    <Button variant="primary" onClick={submitClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Submit Modal */}

            <Modal
                show={exit}
                onHide={ExitClose}
                backdrop="static"
                keyboard={false}
                className="submit-modal"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="mx-auto">Exciting Career Opportunities</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Already details added.
                </Modal.Body>
                <Modal.Footer className="mx-auto border-0">
                    <Button variant="primary" onClick={ExitClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Careerform;