import "../Assets/Css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";
import DataTable, { createTheme } from 'react-data-table-component';


createTheme('solarized', {
    text: {
        primary: 'white',
    },
    background: {
        default: '#131313',
    },
})


const columns = [
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Address',
        selector: row => row.address,
        sortable: true,
    },
    {
        name: 'ID',
        selector: row => row.idname,
        sortable: true,
    },
    {
        name: 'xXx',
        selector: row => row.xxx,
        sortable: true,
    },
    {
        name: 'xGold',
        selector: row => row.xgold,
        sortable: true,
    },
    {
        name: 'xCore',
        selector: row => row.xCore,
        sortable: true,
    },
    {
        name: 'Profit BUSD',
        selector: row => row.busd,
        sortable: true,
    },
    {
        name: 'Profit BNB',
        selector: row => row.bnb,
        sortable: true,
    },
    {
        name: 'New Partners',
        selector: row => row.newpatners,
        sortable: true,
    },
    {
        name: 'Partners',
        selector: row => row.partners,
        sortable: true,
    },
];

const data = [
    {
        id: 1,
        date: '10.07.2023 05:03',
        address: <div className="ms-auto d-flex align-items-center gap-2"
            style={{ fontsize: "13px" }}>
            <span>0x5ac...B97</span>
            <button className="border-0 bg-transparent text-white"><i
                className="fa-solid fa-copy"></i></button>
            <button className="border-0 bg-transparent text-white"><i
                className="fa-solid fa-link"></i></button>
        </div>,
        idname: <button className="dashboard-button-1">ID 133275</button>,
        xxx: 3,
        xgold: 2,
        xCore: 5,
        busd: '20busd',
        bnb: '20bnb',
        newpatners: '+2',
        partners: '-2'

    },

]

function Partners() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <span className="mb-0 dashboard-text-2">Partners</span><button
                                                className="ms-3 dashboard-button-1">ID 175</button>
                                        </div>
                                        {/* <!-- <div className="ms-auto">
                                            <button className="ms-3 dashboard-button-1"><i
                                                className="fa-solid fa-filter me-2"></i>Filters</button>
                                        </div> --> */}
                                    </div>

                                </div>
                            </div>
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="dashboard-overall-partners-table">
                                        <DataTable columns={columns} data={data} theme="solarized" />

                                        {/* <table id="datatable-example" className="" >
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Address</th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">xXx</th>
                                                    <th scope="col">xGold</th>
                                                    <th scope="col">xQore</th>
                                                    <th scope="col">Profit BUSD</th>
                                                    <th scope="col">Profit BNB</th>
                                                    <th scope="col">New Partners</th>
                                                    <th scope="col">Partners</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>10.07.2023 05:03</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>3</td>
                                                    <td>2</td>
                                                    <td>5 </td>
                                                    <td>20 BUSD</td>
                                                    <td>20 BNB</td>
                                                    <td>+2</td>
                                                    <td>2</td>
                                                </tr>


                                            </tbody>


                                        </table> */}

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Partners;