import "../../Assets/Css/dashboard-style.css";
import React, { useState, useEffect } from "react";

import decentralization from "../../Assets/images/Decentralization.png";
import xcoredark from "../../Assets/images/xcoredark.png";
import xcorewhite from "../../Assets/images/xcorewhite.png";
import leveldark from "../../Assets/images/leveldark.png";
import levelwhite from "../../Assets/images/levelwhite.png";
import rankdark from "../../Assets/images/rank-dark.png";
import rankwhite from "../../Assets/images/rank-white.png";
import coredark from "../../Assets/images/core-dark.png";
import corewhite from "../../Assets/images/core-white.png";
import regisdark from "../../Assets/images/reg-dark.png";
import regiswhite from "../../Assets/images/reg-white.png";
import cardsdark from "../../Assets/images/cards-dark.png";
import cardswhite from "../../Assets/images/cards-white.png";
import whitelevel from "../../Assets/images/white-level.png";
import darklevel from "../../Assets/images/dark-level.png";
import levelincome from "../../Assets/images/Level income.png";
import inter from "../../Assets/images/inter.png";
import exter from "../../Assets/images/exter.png";
import cycle1 from "../../Assets/images/cycle1.png";
import cycle2 from "../../Assets/images/cycle2.png";
import tencarddark from "../../Assets/images/tencard-dark.png";
import tencardwhite from "../../Assets/images/tencard-white.png";
import ridark from "../../Assets/images/ri-dark.png";
import riwhite from "../../Assets/images/ri-white.png";
import regstration from "../../Assets/images/Register.png";
import bnb from "../../Assets/images/dusd.png";
import config from "../../config/apiConfig";
import Web3 from "web3";
import DemoCorePlanAbi from "../../ABI/DemoCorePlan.json";
import DUSDtokenAbi from "../../ABI/DUSDtokenAbi.json";
import Window_Ethereum_Config_Api from "../../config/windowEthereumConfig";
import Plan1Abi from "../../ABI/Plan1Abi.json";
import instruction from "../../Assets/videos/sequence.mp4";
import poster1 from "../../Assets/images/Asset 2.png";
import poster2 from "../../Assets/images/Asset 1.png";
import $ from "jquery";
import NavbarInfo from "./NavbarInfo";

function InstructionInfo() {
  useEffect(() => {
    $(document).ready(function () {
      $(".banner-section1-textcolor2").on("click", function () {
        $("#collapseExample").removeClass("show");
      });
    });
  }, []);
  return (
    <div className="instructioninfo">
      <NavbarInfo />
      <section className="dashboard-split-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 dashboard-split-right-section">
              <div className="row p-2 g-4 px-lg-2">
                <div className="col-lg-12">
                  <span className=" dashboard-text-2">Instruction</span>
                  {/* <button
                    onClick={() => invitedIdRedirect(locateUserId)}
                    className="ms-3 dashboard-button-1"
                  >
                    ID {locateUserId}
                  </button> */}
                </div>
              </div>

              <div className="dashboard-instructions-section">
                <div className="row banner-section1 py-lg-3 px-lg-1 flex-lg-row-reverse">
                  <div className="col-lg-4 col-12">
                    <div className="stricky-notscroll">
                      <button
                        className="btn btn-primary d-lg-none"
                        data-bs-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        connect{" "}
                        <a className="text-end">
                          <i className="fa-solid fa-circle-chevron-down collapse-iconalign"></i>
                        </a>
                      </button>
                      <div className="collapse" id="collapseExample">
                        <div className="">
                          <div
                            id="simple-list-example"
                            className="d-flex flex-column gap-2 simple-list-example-scrollspy text-left"
                          >
                            <ul>
                              <li className="">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4 "
                                  href="#simple-list-item-14"
                                >
                                  Decentralization
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4 "
                                  href="#simple-list-item-1"
                                >
                                  PropelX marketing
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4 "
                                  href="#simple-list-item-2"
                                >
                                  PropelX programs
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-8"
                                >
                                  Registration
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-10"
                                >
                                  How PropelX xCore works
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-3"
                                >
                                  How levels work
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-4"
                                >
                                  How the automatic cycles work
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-5"
                                >
                                  Upgrade
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-11"
                                >
                                  How many levels should be activated at the
                                  start
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-12"
                                >
                                  Level Income
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-13"
                                >
                                  Rank Income
                                </a>
                              </li>
                              <li className="my-2">
                                <a
                                  className="banner-section1-textcolor2 dashboard-text-4"
                                  href="#simple-list-item-9"
                                >
                                  Glossary
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 ">
                    <div
                      data-bs-spy="scroll"
                      data-bs-target="#simple-list-example"
                      data-bs-offset="0"
                      data-bs-smooth-scroll="true"
                      class="scrollspy-example"
                      tabindex="0"
                    >
                      <div
                        className="card banner-section1-card"
                        id="simple-list-item-14"
                      >
                        <h5 className="card-title dashboard-text-9">
                          Decentralization
                        </h5>
                        <div className="card-body">
                          <img
                            src={decentralization}
                            className="card-img-top"
                            alt="..."
                          />
                          <div className="dashboard-text-4 banner-section1-textcolor1 mt-3">
                            What is{" "}
                            <b className="textwhite">decentralization?</b> What
                            are its benefits and advantages? Decentralized
                            marketing is created on an automated contract that
                            guarantees you maximum security and stability.
                          </div>
                          <div className="dashboard-text-4 banner-section1-textcolor1 mt-1">
                            A <b className="textwhite">smart contract </b>as an
                            algorithm that executes automatically within the
                            Smart Chain blockchain, a prominent cryptocurrency
                            platform. Similar to cryptocurrencies, smart
                            contracts operate in a decentralized manner. Once
                            initiated, they adhere strictly to a predefined
                            program, with no possibility of altering their
                            operational mode. The logic governing a smart
                            contract is encoded within the blockchain, where
                            numerous computers worldwide carry out computations.
                            This distributed process guarantees the immutability
                            and security of smart contracts, rendering them
                            impervious to hacking or disruption.
                          </div>
                          <div className="dashboard-text-4 banner-section1-textcolor1 mt-1">
                            Blockchain functions as an unalterable ledger of
                            transactions and data, shielded against tampering
                            through cryptographic techniques. It is
                            simultaneously maintained by thousands of
                            independent computers around the world.
                          </div>

                          <div className="card banner-section1-card2 mt-1">
                            <div className="dashboard-text-4 banner-section1-textcolor1">
                              <b className="textwhite">Blockchain</b> is an
                              immutable register of transactions and
                              information, protected from any further
                              manipulation by cryptographic methods. It is
                              simultaneously maintained by thousands of
                              independent computers around the world.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card banner-section1-card1 gap-3">
                        <h5 className="card-title dashboard-text-9">
                          DUSD token
                        </h5>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <b className="textwhite">DUSD</b>
                          presents a gold-backed stablecoin that combines the
                          stability of traditional finance with the innovation
                          of cryptocurrency. Offering a shield against the
                          volatility of the crypto market, DUSD provides a
                          secure and stable asset for transactions, savings, and
                          investments.
                        </div>
                        {/* <div className="dashboard-text-4 banner-section1-textcolor1">5 benefits of DUSD for
                                                PropelX:</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">1.
                                                Fixed
                                                Cost.</b> Your PropelX performance is no
                                                longer affected by rate fluctuations and market corrections.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">2.
                                                All
                                                the opportunities that cryptocurrency
                                                provides. </b> DUSD token can be exchanged for any cryptocurrency on DEX
                                                exchangers with negligible fees in a couple of clicks.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">3.
                                                Simple
                                                calculations.</b> Registration fees,
                                                upgrades, and cycles are now specified in US Dollar equivalents.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">4.
                                                Precise planning.</b> You can accurately track
                                                costs and estimate results without wasting time converting from one unit to
                                                another.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">5.
                                                Protection of participants' interests.</b> DUSD is
                                                fully backed by the US Dollar at a ratio of 1:1 and can always be exchanged
                                                for Dollars. It was created in a Paxos and Binance partnership.</div> */}
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3 propel-market"
                        id="simple-list-item-1"
                      >
                        <h5 className="card-title dashboard-text-9">
                          PropelX marketing
                        </h5>
                        {/* <iframe src="https://www.youtube.com/embed/YAYAKIp5JeE" title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    className="iframe-video" allowfullscreen></iframe> */}

                        <video
                          id=""
                          poster={poster1}
                          controls
                          className="rounded-4 poster-css poster-dark"
                        >
                          <source
                            src={instruction}
                            type="video/mp4"
                            className="video-part"
                          />
                        </video>
                        <video
                          id=""
                          poster={poster2}
                          controls
                          className="rounded-4 poster-css poster-white"
                        >
                          <source
                            src={instruction}
                            type="video/mp4"
                            className="video-part"
                          />
                        </video>

                        {/* <ReactPlayer
                                                    url={instruction}
                                                    light=""
                                                    width="100%"
                                                    height="300px"
                                                    controls 
                                                    playing 
                                                    className="rounded-4 poster-css poster-dark"
                                                />
                                                <ReactPlayer
                                                    url={instruction}
                                                    light=""
                                                    width="100%"
                                                    height="300px"
                                                    controls 
                                                    playing 
                                                    className="rounded-4 poster-css poster-white"
                                                /> */}

                        {/* <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                                className="textwhite">PropelX
                                                marketing</b> is implemented on the Smart
                                                Chain blockchain smart contract technology. The marketing employs the
                                                DUSD token in the BEP-20 format with a stable exchange rate of 1 USD. To
                                                send DUSD or any other token functioning in the Smart Chain blockchain,
                                                you will need a certain amount of BNB to pay the fee.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                                src={bnb} alt="bnb" className="bnb-logo" /><b
                                                    className="textwhite">BNB</b> -
                                                This is the internal BEP-20 format coin
                                                of the Smart Chain blockchain, which is required to pay the transaction
                                                fee.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                                src={bnb} alt="bnb" className="bnb-logo" /><b
                                                    className="textwhite">DUSD</b>
                                                - This is the BEP-20 format Smart Chain
                                                blockchain coin with a stable rate of 1 USD.</div> */}
                      </div>
                      <div
                        className="card banner-section1-card1 gap-3 "
                        data-target="simple-sticky1"
                      >
                        <h5 className=" dashboard-text-9">PropelX marketing</h5>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <b className="textwhite">PropelX marketing</b> is a
                          matrix system based on decentralized smart contract
                          technology. Profits come from inviting new partners to
                          your team, who once registered stay with you forever
                          since it is recorded in blockchain. The income is
                          distributed automatically and instantly to the
                          personal wallets of the participants.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <img src={bnb} alt="bnb" className="bnb-logo" />
                          <b className="textwhite">
                            The validity of the levels is not limited.{" "}
                          </b>
                          By activating any level of the program once, it is
                          assigned to you forever and does not require any
                          additional rewards.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <img src={bnb} alt="bnb" className="bnb-logo" />
                          <b className="textwhite">
                            Higher levels = more income.{" "}
                          </b>
                          Marketing income is generated from the value of the
                          level in which your partner took a spot. Therefore,
                          working at higher levels increases your results.
                          Marketing is built in such a way that income from one
                          cycle is enough to activate the next level.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <img src={bnb} alt="bnb" className="bnb-logo" />
                          <b className="textwhite">
                            Automatic cycles at all levels.{" "}
                          </b>
                          The levels of each program contain a fixed number of
                          spots. As soon as all the spots in the level are
                          filled, a new cycle automatically begins.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <img src={bnb} alt="bnb" className="bnb-logo" />
                          <b className="textwhite">
                            The number of cycles is not limited.{" "}
                          </b>
                          A level activated once allows you to invite an
                          unlimited number of new partners.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <img src={bnb} alt="bnb" className="bnb-logo" />
                          <b className="textwhite">Referral link. </b>All
                          personal partners whom you invite are assigned to you
                          forever, and this cannot be changed, since it is
                          recorded in a smart contract.
                        </div>
                      </div>

                      <div
                        className="card banner-section1-card1 pp-card gap-3"
                        id="simple-list-item-2"
                      >
                        <h5 className=" dashboard-text-9">PropelX programs</h5>
                        <div className="card-body">
                          {/* <div id="carouselExampleAutoplaying" className="carousel slide">
                                                        <div className="carousel-indicators">
                                                            <button type="button" data-bs-target="#carouselExampleAutoplaying"
                                                                data-bs-slide-to="0" className="active" aria-current="true"
                                                                aria-label="Slide 1"></button>
                                                            <button type="button" data-bs-target="#carouselExampleAutoplaying"
                                                                data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                            <button type="button" data-bs-target="#carouselExampleAutoplaying"
                                                                data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                        </div>
                                                        <div className="carousel-inner">
                                                            <div className="carousel-item active">
                                                                <img src={xcoredark}
                                                                    className=" w-100 xcore-dark" alt="logo" />
                                                                <img src={xcorewhite}
                                                                    className=" w-100 xcore-white" alt="logo" />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src={leveldark} className=" w-100 level-dark"
                                                                    alt="logo" />
                                                                <img src={levelwhite} className=" w-100 level-white"
                                                                    alt="logo" />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src={rankdark} className=" w-100 rank-dark"
                                                                    alt="logo" />
                                                                <img src={rankwhite} className=" w-100 rank-white"
                                                                    alt="logo" />
                                                            </div>
                                                        </div>
                                                        <button className="carousel-control-prev" type="button"
                                                            data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                        <button className="carousel-control-next" type="button"
                                                            data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                    </div> */}

                          <div
                            id="carouselExampleAutoplaying"
                            class="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img
                                  src={xcoredark}
                                  className="xcore-dark rounded-4"
                                  alt="logo"
                                />
                                <img
                                  src={xcorewhite}
                                  className="xcore-white rounded-4"
                                  alt="logo"
                                />
                              </div>
                              <div class="carousel-item">
                                <img
                                  src={leveldark}
                                  className=" w-100 level-dark rounded-4"
                                  alt="logo"
                                />
                                <img
                                  src={levelwhite}
                                  className=" w-100 level-white rounded-4"
                                  alt="logo"
                                />
                              </div>
                              <div class="carousel-item">
                                <img
                                  src={rankdark}
                                  className=" w-100 rank-dark rounded-4"
                                  alt="logo"
                                />
                                <img
                                  src={rankwhite}
                                  className=" w-100 rank-white rounded-4"
                                  alt="logo"
                                />
                              </div>
                            </div>
                            <button
                              class="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleAutoplaying"
                              data-bs-slide="prev"
                            >
                              <span
                                class="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button
                              class="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleAutoplaying"
                              data-bs-slide="next"
                            >
                              <span
                                class="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span class="visually-hidden">Next</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3"
                        id="simple-list-item-8"
                      >
                        <h5 className="card-title dashboard-text-9">
                          Registration
                        </h5>
                        <img
                          src={regstration}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <b className="textwhite">Smart Contract - </b>PropelX
                          platform is based on smart contract technology.
                          PropelX smart contracts are programmed in such a way,
                          that they never store participants' funds, their
                          balance is always zero. The purpose of the smart
                          contract is to automatically redirect funds from
                          incoming transactions to the wallets of other
                          participants, according to marketing program rules.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <b className="textwhite">Referral linking - </b>Also,
                          your referral linkage remains unchanged, you can't
                          change your upline partner, as well as your downline
                          partners are assigned to you in your structure
                          forever.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <b className="textwhite">Personal Wallet - </b>To
                          become a member, you need to create your personal
                          wallet.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          Only you have access to the funds. All rewards
                          according to marketing are instantly credited to your
                          personal wallet. All transactions are stored in the
                          public domain in a blockchain. You can easily check
                          each transaction and see where the funds have been
                          transferred.
                        </div>
                      </div>

                      <div className="card banner-section1-card1 register gap-3 ">
                        <h5 className="card-title dashboard-text-9">
                          Registration
                        </h5>
                        <img
                          src={regisdark}
                          className="card-img-top regis-dark rounded-4"
                          alt="logo"
                        />
                        <img
                          src={regiswhite}
                          className="card-img-top regis-white rounded-4"
                          alt="logo"
                        />
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <b className="textwhite">
                            Registration in PropelX DUSD -{" "}
                          </b>
                          Users must provide a Referral ID to register, and they
                          need to connect their MetaMask wallet. Upon
                          registration, each user will receive a Unique User ID
                          automatically.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          Registration in PropelX DUSD is the activation of
                          first levels in PropelX xCore by using a Referral ID,
                          which costs 15 DUSD. The first levels in xCore, Level
                          Income and Rank Income programs are always activated
                          together and cannot be accessed separately. All the
                          following levels in xCore can be purchased one by one,
                          in ascending order.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          Registration transaction is credited to the smart
                          contract. The smart contract records your wallet
                          number into the structure and redirects the funds to
                          the personal wallet of the person who invited you
                          (your upline partner). You occupy a free spot in their
                          first level of xCore program and Level 1 of xCore are
                          respectively opened for you, and now you can invite
                          partners through your personal referral link.
                        </div>
                      </div>

                      <div
                        className="card banner-section1-card1 xcore-pp gap-1 "
                        id="simple-list-item-10"
                      >
                        <h5 className="card-title dashboard-text-9">
                          How PropelX xCore works
                        </h5>
                        <p className="textwhite mb-0">
                          We use DUSD to register the xCore contract on D-chain
                        </p>
                        <h5 className="textwhite mb-4">Basic facts of xCore</h5>
                        <img
                          src={coredark}
                          className="card-img-top core-dark rounded-4"
                          alt="logo"
                        />
                        <img
                          src={corewhite}
                          className="card-img-top core-white rounded-4"
                          alt="logo"
                        />
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <div className="textwhite mt-4">
                            <ul
                              className="mt-3"
                              style={{ listStyleType: "initial" }}
                            >
                              <li>
                                Level activation and rewards are in D-chain
                                (DUSD).
                              </li>
                              <li>12 places, 2 lines (internal & external).</li>
                            </ul>
                            When User invest 100%, it will split into 3 , 10% ,
                            remaining 90% divided → (50%, 50%).
                          </div>
                          <ul
                            className="mt-3"
                            style={{ listStyleType: "initial" }}
                          >
                            <li>
                              10% → Rewards (Gold, Platinum & Diamond users)
                            </li>
                            <li>45% → xCore</li>
                            <li>45% → Level Income</li>
                          </ul>
                          <div className="textwhite mt-4">
                            For example: When User register with 15 DUSD (100%),
                            it will split by contract for the below mentioned,
                          </div>
                          <ul
                            className="mt-3"
                            style={{ listStyleType: "initial" }}
                          >
                            <li>Invested amount (100%) → 15 DUSD</li>
                            <li>
                              Ranking (10%) → 1.5 DUSD and remaining (90%) →
                              13.5 DUSD
                            </li>
                            <li>xCore (50%) → 6.75 DUSD</li>
                            <li>Level Income (50%) → 6.75 DUSD</li>
                          </ul>
                          <div className="textwhite mt-4">
                            Note : At the same time, if a user buys any level in
                            xCore the same calculation will repeat. Level
                            packages will vary. Reference image given below.
                          </div>
                          <div className="mt-4">
                            <img
                              src={cardsdark}
                              className="card-img-top cards-dark rounded-4"
                              alt="logo"
                            />
                            <img
                              src={cardswhite}
                              className="card-img-top cards-white rounded-4"
                              alt="logo"
                            />
                          </div>
                          <div className="textwhite mt-4">
                            In all Level cards, multiple cycles will be created
                            and Each cycle have two types,
                            <ul
                              className="mt-3"
                              style={{ listStyleType: "initial" }}
                            >
                              <li>Internal Line</li>
                              <li>External Line</li>
                            </ul>
                          </div>
                          <div className="dashboard-text-4 textwhite mt-4">
                            <b className="">Internal Line</b>
                            <div className="d-flex justify-content-center mt-4">
                              <img
                                src={inter}
                                className=" inter rounded-4"
                                alt="logo"
                              />
                            </div>
                          </div>
                          <div className="textwhite mt-4">
                            <b>Direct reward</b> → User of your direct referral
                            are denoted and you’ll receive the whole amount of
                            referral.
                          </div>
                          <ul
                            className="mt-3"
                            style={{ listStyleType: "initial" }}
                          >
                            <li>
                              For example: ID 1 is the first User in PropelX, ID
                              2 joined by ID 1. ID 1 will get the Direct reward
                              50%. Because ID1 has no Upline.
                            </li>
                          </ul>
                          <div className="textwhite mt-4">
                            <b>Upline reward</b> → If users have Upline 50% of
                            the reward will be Split into 2. One part for Direct
                            referrer & second part for referrer’s upline.
                          </div>
                          <ul
                            className="mt-3"
                            style={{ listStyleType: "initial" }}
                          >
                            <li>
                              For example: If ID 3 comes via ID2 now, ID3 reward
                              will split for ID2 (Direct referrer) & ID1 (ID 2’s
                              Upline).
                            </li>
                          </ul>

                          <div className="dashboard-text-4 textwhite mt-4">
                            <b className="">External Line</b>
                            <div className="d-flex justify-content-center mt-4">
                              <img
                                src={exter}
                                className=" inter rounded-4"
                                alt="logo"
                              />
                            </div>
                          </div>
                          <div className="textwhite mt-4">
                            <b>External Line reward</b> → When users join the
                            external line, the first 3 users' 50% rewards will
                            go to the internal line 1st user and their direct
                            referrer. The next 3 users' rewards will go to the
                            internal line 2nd user and their direct referrer.
                            The last 3 users' rewards will go to the internal
                            line 3rd user and their direct referrer.
                          </div>
                          <ul
                            className="mt-3"
                            style={{ listStyleType: "initial" }}
                          >
                            <li>
                              Auto-recycle occurs when the last place in the
                              external line is occupied, the 2nd cycle will open
                              for User. Cycle will expand whenever the current
                              cycle is filled.
                            </li>
                            <li>
                              Total 10 level, multiple cycle and each cycle has
                              12 Slot
                            </li>
                          </ul>
                        </div>

                        {/* <div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1">Distribution of
                                                    rewards when filling each spot in a level of the program is as
                                                    follows:</div>
                                                <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                    className="introduction-activity-1 ">1</b>The first partner you
                                                    invite is placed on the first spot below you. Reward of 100% goes to
                                                    your personal wallet.
                                                </div>
                                                <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                    100%</div>
                                            </div>
                                            <div>
                                                <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                    className="introduction-activity-1 ">2</b>The second partner is
                                                    placed on the second spot below you. You also get 100% reward to
                                                    your personal wallet.
                                                </div>
                                                <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                    100%</div>
                                            </div>

                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-2 ">3</b>The third partner is placed on
                                                the third spot below you and completes the cycle. 100% of the income
                                                goes to your upline 100% partner's wallet, and a new cycle immediately
                                                begins for you, and you can fill up the spots again by inviting new
                                                partners.
                                            </div>
                                            <div className="card banner-section1-card2 ">
                                                <div className="dashboard-text-4 banner-section1-textcolor1">
                                                    <b className="textwhite">Likewise with your partners.</b> Each time
                                                    your partner completes the cycle of his level, you receive a payment
                                                    to the wallet in 100% of the level cost, and the partner opens the
                                                    new cycle again. At the same time, this partner takes a new place
                                                    under you. Thus, the same partner can occupy several places in a row
                                                    in your levels.
                                                </div>
                                            </div> */}
                      </div>

                      {/* <div className="card banner-section1-card1 gap-3 ">
                                            <h5 className="card-title dashboard-text-9">
                                                How PropelX x4 works</h5>
                                            <img src={regstration} className="card-img-top" alt="..." />
                                            <div className="dashboard-text-4 banner-section1-textcolor1">In<b
                                                className="textwhite">PropelX x4</b> program you can invite personal partners,
                                                as well as
                                                receive spillovers from above and below.</div>
                                            <div>
                                                <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1">
                                                    <div className="d-flex gap-1"><b className="introduction-activity-3 ">1</b><b
                                                        className="introduction-activity-3 ">2</b></div>The partners who
                                                    occupy two spots below you in the first line are also in the second
                                                    line of your upline's level. Reward of 100% goes to upline's wallet.
                                                    By the same principle, you receive income 100% not from the first,
                                                    but from the second line.
                                                </div>
                                                <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                    100%</div>
                                            </div>

                                            <div>
                                                <div className="d-flex  dashboard-text-4 banner-section1-textcolor1"><b
                                                    className="introduction-activity-4 ">1</b><b
                                                        className="introduction-activity-4 ">2</b><b
                                                            className="introduction-activity-4 ">3</b>Rewards from partners who
                                                    occupy these spots instantly go to your wallet in the amount of
                                                    100%.
                                                </div>
                                                <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                    100%</div>
                                            </div>

                                            <div>
                                                <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                    className="introduction-activity-5 ">6</b>TThe partner completes the
                                                    cycle of the level, the reward of 100% 100% goes to your upline, and
                                                    the new cycle begins for you.
                                                </div>
                                            </div>
                                            <div className="card banner-section1-card2 ">
                                                <div className="dashboard-text-4 banner-section1-textcolor1">When a partner
                                                    on the 2nd line joins your upline, they take a place in your 1st
                                                    line — that is, you receive a
                                                    <b className="textwhite">spillover from above.</b> Similarly,<b
                                                        className="textwhite">
                                                        a spillover can come from below.
                                                    </b> When a partner comes to your downline on the 1st line, then
                                                    they will take a place on your 2nd line. Thus, places in the x4
                                                    levels can be occupied by people invited only by you, or there can
                                                    be none invited by you, or mixed.
                                                </div>
                                            </div>
                                            <div>
                                                <div className=" dashboard-text-4 banner-section1-textcolor1 gap-2"><img
                                                    src={bnb} alt="bnb" className="bnb-logo" />Do not forget
                                                    to activate the next level after the 1st cycle so as not to miss
                                                    payments on the previous level.</div>
                                            </div>
                                        </div>

                                        <div className="card banner-section1-card1 gap-3 ">
                                            <h5 className="card-title dashboard-text-9">
                                                How PropelX xXx works</h5>
                                            <img src={bnb} className="card-img-top" alt="..." />

                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">First
                                                line (2
                                                places): </b>rewards go to your upline partners.</div>

                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                                className="textwhite">Second
                                                line (4 places):</b>you get<b className="textwhite"> 30% </b>of
                                                the level cost from each of the four partners, and 70% goes to the
                                                upline, since for him they are partners of the 4th line.</div>

                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">Third
                                                line (8 places): </b> you get <b className="textwhite">70% </b> from
                                                each, 30% goes to the upline.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"> <b
                                                className="textwhite">xXx cycle: </b> The final reward from the 2nd
                                                line (30%) and the final reward from the 3rd line (70%) are summed up
                                                and distributed to the upline partners, and a new cycle opens for you.
                                            </div>
                                        </div>

                                        <div className="card banner-section1-card1 gap-3 ">
                                            <h5 className="card-title dashboard-text-9">
                                                How PropelX xGold works</h5>
                                            <img src={regstration} className="card-img-top" alt="..." />
                                            <div className="dashboard-text-4 banner-section1-textcolor1">The advantage of <b
                                                className="textwhite"> xGold program</b>is a larger number of partners
                                                and spillover opportunities, which generates an income of 1020% from one
                                                cycle of the level.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"> <b
                                                className="textwhite">First
                                                line (2 places):</b> rewards go to your <b className="textwhite">upline</b>
                                                partner.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                                className="textwhite">Second
                                                line (4 places): </b> you get <b className="textwhite">20%</b> from each spot
                                            </div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b className="textwhite">Third
                                                line (8
                                                places):</b> you get <b className="textwhite">30%</b> from each spot
                                            </div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                                className="textwhite">Fourth
                                                line (16 places):</b> you get <b className="textwhite">50%</b> from each
                                                participant (30% and 20% are
                                                distributed to upline partners).</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                                className="textwhite">
                                                xGoldcycle:
                                            </b>The last 2 spots from the 4th line (50% + 50%) are summed up and
                                                100% is distributed to upline partners, and a new cycle of the level
                                                begins automatically.</div>
                                        </div> */}

                      <div
                        className="card banner-section1-card1 l-work gap-lg-3 "
                        id="simple-list-item-3"
                      >
                        <h5 className="card-title dashboard-text-9">
                          How levels work
                        </h5>
                        <div className="mt-2">
                          <img
                            src={cardsdark}
                            className="card-img-top cards-dark rounded-4"
                            alt="logo"
                          />
                          <img
                            src={cardswhite}
                            className="card-img-top cards-white rounded-4"
                            alt="logo"
                          />
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">
                          Levels work on the principle that each level differs
                          in the cost of activation. Completing a full cycle of
                          the level brings enough profit to activate the next
                          level, and gives you the opportunity to earn 2 times
                          more.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          Activating several levels and inviting partners to
                          those levels at once speeds up your profit many times.
                          The levels do not have a validity period, so you
                          should not be afraid that they will expire. Each
                          active level constantly brings you income.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          How many levels can be activated at once?
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          You're free to open as many as you wish, but it's
                          important to note that levels can only be unlocked one
                          after the other, in a sequential manner
                          <ul
                            className="mt-1"
                            style={{ listStyleType: "initial" }}
                          >
                            <li>
                              For example : you can’t purchase the fourth level
                              without opening the third one.
                            </li>
                          </ul>
                        </div>
                        <div className="card banner-section1-card2 ">
                          <div className="dashboard-text-4 banner-section1-textcolor1">
                            <b className="textwhite">Be careful - </b>if you
                            have not yet activated a certain level and your
                            partner activates it earlier, then overtaking will
                            occur, and you will miss the reward.
                          </div>
                        </div>
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3 "
                        id="simple-list-item-4"
                      >
                        <h5 className="card-title dashboard-text-9">
                          How to automatic cycles work
                        </h5>
                        <div className="row d-flex gap-2 align-items-center">
                          <div className="col-12">
                            <img
                              src={cycle1}
                              className=" inter rounded-4"
                              alt="logo"
                            />
                          </div>
                          <div className="col-12">
                            <img
                              src={cycle2}
                              className=" inter rounded-4"
                              alt="logo"
                            />
                          </div>
                        </div>

                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">
                          Automatic cycles open the same level for you, and you
                          continue to receive income from it. This happens
                          automatically as soon as the last free spot under you
                          is taken by a partner. The current level completes its
                          cycle, and the next cycle begins.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          When a cycle is completed, you have your level
                          reopened with new free spots in next cycle for new
                          partners. Cycles work in the same way for your invited
                          partners.
                        </div>
                        {/* <div className="card banner-section1-card2 ">
                                                <div className="dashboard-text-4 banner-section1-textcolor1">
                                                    After the first cycle of a level, you will receive rewards for the new
                                                    partners at the same level only if the next level is activated in the
                                                    program. Therefore, it is important to make upgrades in advance so as
                                                    not to miss out on rewards.
                                                </div>
                                            </div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">
                                                <div><b className="dashboard-text-9 textwhite">How cycles in xXx work.</b>For
                                                    automatic activation of the level after a cycle in xXx program, you need
                                                    100% value of the level. The first amount of 30% from last partner on
                                                    the 2nd line is temporarily blocked on a smart contract, and as soon as
                                                    the second amount of 70% from last partner on the 3rd line arrives, the
                                                    cycle is automatically closed, and 100% for it instantly goes higher in
                                                    the structure.</div>
                                            </div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">
                                                <div><b className="dashboard-text-9 textwhite">How cycles in xGold work.</b>For
                                                    automatic activation of the level after a cycle in xGold program, you
                                                    need 100% cost of its activation. Rewards from the last two partners on
                                                    the 4th line are summed up (50% + 50%) for this activation.</div>
                                            </div> */}
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3 upgrade"
                        id="simple-list-item-5"
                      >
                        <p id="simple-list-item-5">
                          <h5 className="card-title dashboard-text-9">
                            Upgrade
                          </h5>
                          <div className="mt-4">
                            <img
                              src={cardsdark}
                              className="card-img-top cards-dark rounded-4"
                              alt="logo"
                            />
                            <img
                              src={cardswhite}
                              className="card-img-top cards-white rounded-4"
                              alt="logo"
                            />
                          </div>
                          <div className="dashboard-text-4 banner-section1-textcolor1 mt-3">
                            UPGRADE is the activation of the next more expensive
                            level. Income from 1 cycle of each level is enough
                            to upgrade to the next level. The reward for the
                            upgrade goes to your upline partner, provided that
                            they already have this level activated. You get to
                            decide whether to activate the next level or not.
                          </div>

                          <h5 className="card-title dashboard-text-9 my-3">
                            Overtake
                          </h5>
                          <div className="dashboard-text-4 banner-section1-textcolor1">
                            If you do not have the next level open, then the
                            rewards (xCore and Level Income) from your downline
                            level will be redirected to the upline partner who
                            activated that level.
                          </div>
                          <div className="card banner-section1-card2 mt-3">
                            <div className="dashboard-text-4 banner-section1-textcolor1">
                              If you miss a reward from new partners due to lack
                              of upgrade, then referral linking allows you to
                              get your partners back. When you upgrade to the
                              next level, your referral partner will take a
                              place below you in their next cycle, and will
                              follow you with each next cycle.
                            </div>
                          </div>
                        </p>
                      </div>

                      {/* <div className="card banner-section1-card1 gap-3 ">
                                                <h5 className="card-title dashboard-text-9">
                                                    How PropelX xGold works</h5>
                                                <img src={regstration} className="card-img-top" alt="..." />
                                                <div className="dashboard-text-4 banner-section1-textcolor1">Level freeze occurs if
                                                    the partners have taken all the spots in the level completing the first
                                                    cycle, but the next level is not activated (lack of upgrade).</div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1">When “frozen”, the
                                                    level remains inactive. Partners continue to occupy spots in your level but
                                                    you miss out on profits from this level until the next level is activated.
                                                </div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1">Exceptions include the
                                                    last levels in PropelX programs (12th or 15th in xGold ).</div>
                                            </div>

                                            <div className="card banner-section1-card1 gap-3 " id="simple-list-item-6">
                                                <h5 className="card-title dashboard-text-9">
                                                    Lost profit and extra profit</h5>
                                                <div className="dashboard-text-4 banner-section1-textcolor1">
                                                    <div>Lost profits are cases when you miss rewards for partners, and they are
                                                    </div>
                                                    <div>redirected to an upline partner.</div>
                                                    <div>Lost profits can occur for two reasons:
                                                    </div>
                                                </div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                                    src={bnb} className="bnb-logo" /><b
                                                        className="textwhite">Overtaking: </b> - This is the internal BEP-20 format
                                                    coin of the Smart Chain blockchain, which is required to pay the transaction
                                                    fee.</div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                                    src={bnb} className="bnb-logo" /><b
                                                        className="textwhite">Freezing:
                                                    </b>Your level is completely filled, and the 1st cycle has been completed,
                                                    but the next, more expensive level is still not activated.</div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1">After you activate the
                                                    level, your partner will come back to you again in his next cycle.</div>
                                                <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                                    src={bnb} className="bnb-logo" /><b className="textwhite">Extra
                                                        profit </b> is a reward that comes to you from
                                                    below, because one or more of the downline partners have lost their profits.
                                                    Thus, you can gain extra profits from the depth of your structure when your
                                                    downline is overtaken, and you are the first in your structure who has this
                                                    level activated. Therefore, activation of higher levels allows you to
                                                    receive such rewards for overtaking from downline partners.</div>

                                            </div>

                                            <div className="card banner-section1-card1 gap-3 ">
                                                <h5 className="card-title dashboard-text-9">
                                                    Spillovers</h5>
                                                <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">
                                                    <div>Spillovers is a process when spots in your levels are occupied not only
                                                        by your personally invited partners, but also by partners invited by the
                                                        other members of your team. PropelX marketing programs allow you to
                                                        receive spillovers from both your upline and downline partners in x4,
                                                        xXx and xGold.</div><img />
                                                </div>
                                                <div>
                                                    <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                        className="introduction-activity-6 "></b>
                                                        <div><b className="textwhite">Personal partner </b>is a person who
                                                            registered with your personal affiliate link. They take the closest
                                                            free spot in the level immediately after your position.</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                        className="introduction-activity-7 "></b>
                                                        <div><b className="textwhite">Spillover from above </b> is a personally
                                                            invited partner of your upline partner. They take the next free spot
                                                            in the level of your partner, and accordingly get in your level,
                                                            too, but on the higher line.</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                        className="introduction-activity-8 "></b>
                                                        <div><b className="textwhite">Spillover from below</b> is a personally
                                                            invited partner of your downline.</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                        className="introduction-activity-9"></b>
                                                        <div><b className="textwhite">Overtake</b> is a situation when a partner
                                                            overtakes their upline, and temporarily takes a spot directly below
                                                            you. If your downline partner has this level activated, in the next
                                                            cycle this partner will take a place under their direct upline
                                                            partner.</div>
                                                    </div>
                                                </div>
                                                <div className="gap-3 dashboard-text-4 banner-section1-textcolor1">You can overtake
                                                    your upline partner, acquiring levels which they have not yet activated. In
                                                    this case, your reward goes to their closest upline partner, who has such a
                                                    level activated, and the income goes to them. Referral linking allows you to
                                                    return everyone to their places. This means that when your upline activates
                                                    the required level, in the next cycle you will take the spot under them
                                                    again. Thus, the consolidation of personally invited partners is preserved
                                                    forever.</div>

                                            </div>

                                            <div className="card banner-section1-card1 gap-3">
                                                <h5 className=" dashboard-text-9">
                                                    Profit</h5>
                                                <div className="card-body">
                                                    <div id="carouselExampleIndicator" className="carousel slide">
                                                        <div className="carousel-indicators">
                                                            <button type="button" data-bs-target="#carouselExampleIndicators"
                                                                data-bs-slide-to="0" className="active" aria-current="true"
                                                                aria-label="Slide 1"></button>
                                                            <button type="button" data-bs-target="#carouselExampleIndicators"
                                                                data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                            <button type="button" data-bs-target="#carouselExampleIndicators"
                                                                data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                        </div>
                                                        <div className="carousel-inner">
                                                            <div className="carousel-item active">
                                                                <img src={decentralization}
                                                                    className="d-block w-100" alt="..." />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src={regstration} className="d-block w-100"
                                                                    alt="..." />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src={decentralization}
                                                                    className="d-block w-100" alt="..." />
                                                            </div>
                                                        </div>
                                                        <button className="carousel-control-prev" type="button"
                                                            data-bs-target="#carouselExampleIndicator" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                        <button className="carousel-control-next" type="button"
                                                            data-bs-target="#carouselExampleIndicator" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> */}

                      <div
                        className="card banner-section1-card1 gap-3 activate"
                        id="simple-list-item-11"
                      >
                        <p id="simple-list-item-7">
                          <h5 className="card-title dashboard-text-9">
                            How many levels should be activated at the start
                          </h5>
                          <img
                            src={tencarddark}
                            className="card-img-top tencard-dark rounded-4"
                            alt="logo"
                          />
                          <img
                            src={tencardwhite}
                            className="card-img-top tencard-white rounded-4"
                            alt="logo"
                          />
                          <div className="dashboard-text-4 banner-section1-textcolor1">
                            Of course, you can start with level 1. But it is
                            recommended to activate at least the first 3 levels
                            right at the start. This will allow you to get good
                            results from the very beginning. Such strategy can
                            maximize your results.
                          </div>
                          <div className="dashboard-text-4 banner-section1-textcolor1 mt-2">
                            Many successful participants use this strategy of
                            activating several levels from the beginning. They
                            set an example for those below them, and so do they
                            follow. As a result, profits of the whole structure
                            grow faster. Many start with 5 levels at once, while
                            some start with 7 or even more levels.
                          </div>
                        </p>
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3 level-income"
                        id="simple-list-item-12"
                      >
                        <p id="simple-list-item-7">
                          <h5 className="card-title dashboard-text-9 pb-2">
                            LEVEL INCOME
                          </h5>
                          {/* <img src={whitelevel} className="card-img-top level-white rounded-4" alt="logo" />
                                                    <img src={darklevel} className="card-img-top level-dark rounded-4" alt="logo" /> */}
                          <img
                            src={levelincome}
                            className="card-img-top rounded-4"
                            alt="logo"
                          />
                          <div className="textwhite mt-4">
                            In Level Income You get 30% income from your direct
                            referrals, 20% income from the referrals of your
                            referrals (indirect referrals), and 10% income from
                            the referrals of your indirect referrals.With Level
                            Income, your earning potential extends to seven
                            downlines, including both direct and indirect
                            referrals.
                          </div>
                          {/* <div className="textwhite mt-4">
                                                        For each User can see the top 7 downline user list & calculation of
                                                        commission distribution given below.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        1) When <span className="id-one">ID 1</span> Joined - 100% Commission goes to <span className="id-text">Admin</span> , because <span className="id-one">ID 1</span> is a direct referral of <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        2) When <span className="id-two">ID 2</span> Joined - Commission split into 30% to  <span className="id-one">ID 1</span> & 70% to <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        3) When <span className="id-three">ID 3</span> Joined - Commission split into 30% to <span className="id-two">ID 2</span> , 20% commission to <span className="id-one">ID 1</span> & 50% to <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        4) When <span className="id-four">ID 4</span> Joined - Commission split into 30% to <span className="id-three">ID 3</span> , 20% to <span className="id-two">ID 2</span> , 10% to <span className="id-one">ID 1</span> , 40% to <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        5) When <span className="id-five">ID 5</span> Joined - Commission split into 30% to <span className="id-four">ID 4</span> , 20 % to <span className="id-three">ID 3</span> , 10% to <span className="id-two">ID 2</span> , 10% to <span className="id-one">ID 1</span> , 30% to <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        6) When <span className="id-six">ID 6</span> Joined - Commission split into 30% to <span className="id-five">ID 5</span> , 20 % to <span className="id-four">ID 4</span> , 10% to <span className="id-three">ID 3</span> , 10% to <span className="id-two">ID 2</span> , 10% to <span className="id-one">ID 1</span> , 20% to <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        7) When <span className="id-seven">ID 7</span> Joined - Commission split into 30% to <span className="id-six">ID 6</span> , 20 % to <span className="id-five">ID 5</span> , 10% to <span className="id-four">ID 4</span> , 10% to <span className="id-three">ID 3</span> , 10% to <span className="id-two">ID 2</span> , 10% to <span className="id-one">ID 1</span> , 10% to <span className="id-text">Admin</span>.
                                                    </div>
                                                    <div className="textwhite mt-4">
                                                        NOTE : Admin will get commision for first 7 downline only
                                                    </div> */}
                          <div className="textwhite mt-4">
                            Example: If <span className="id-eight">ID 8</span>{" "}
                            joined by <span className="id-seven">ID 7</span> ,
                            commission distribution{" "}
                            <span className="id-seven">ID 7</span> to{" "}
                            <span className="id-two">ID 2</span> only.
                          </div>
                        </p>
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3 rankincome"
                        id="simple-list-item-13"
                      >
                        <p>
                          <h5 className="card-title dashboard-text-9">
                            Rank Income
                          </h5>
                          <div className="textwhite mt-4">
                            PropelX appreciates potential Users by giving Awards
                            & Rewards.
                          </div>
                          <img
                            src={ridark}
                            className="card-img-top ri-dark my-4 rounded-4"
                            alt="logo"
                          />
                          <img
                            src={riwhite}
                            className="card-img-top ri-white my-4 rounded-4"
                            alt="logo"
                          />
                          <div className="textwhite mt-3">
                            <b>
                              1. <span className="id-text">Bronze</span>
                            </b>{" "}
                            - Direct refer 5 person (every user need to make 5
                            direct referral to become eligible for Bronze).
                          </div>
                          <div className="textwhite mt-3">
                            <b>
                              2. <span className="id-text">Silver</span>
                            </b>{" "}
                            - Downline 3 Bronze. (every user need to have 3
                            Bronze downline partners to become eligible for
                            Silver).
                          </div>
                          <div className="textwhite mt-3">
                            <b>
                              3. <span className="id-text">Gold</span>
                            </b>{" "}
                            - Downline 3 silver (every user needs 3 Silver
                            downline partners & must purchase 5th slot to become
                            eligible for Gold). They have a team of 100 People.
                            When this User reaches Gold, eligible to withdraw
                            20% from the Total reward added in contract.
                          </div>
                          <div className="textwhite mt-3">
                            <b>
                              4. <span className="id-text">Platinum</span>
                            </b>{" "}
                            - Downline 3 Gold (every user needs 3 Gold downline
                            partners & must purchase 7th slot to become eligible
                            for Platinum). They have a team of 500 people When
                            this User reaches Platinum, eligible to withdraw 30%
                            from the Total reward added in contract.
                          </div>
                          <div className="textwhite mt-3">
                            <b>
                              5. <span className="id-text">Diamond</span>
                            </b>{" "}
                            - Downline 3 platinum (every user needs 3 Platinum
                            downline partners & must purchase 10th slot to
                            become eligible for Diamond). They have a team of
                            2500 people When this User reaches Diamond, eligible
                            to withdraw 50% from the Total reward added in
                            contract.
                          </div>
                        </p>
                      </div>

                      <div
                        className="card banner-section1-card1 gap-3 "
                        id="simple-list-item-9"
                      >
                        <h5 className="card-title dashboard-text-9">
                          Glossary
                        </h5>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <i className="fa-regular fa-circle-up textwhite"></i>
                          <b className="textwhite">UPLINE PARTNER </b> - A
                          partner under whose referral link you registered.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <i className="fa-solid fa-user-large textwhite"></i>
                          <b className="textwhite">DOWNLINE PARTNER</b> - A
                          partner who registered using your referral link.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <i className="fa-solid fa-arrow-up text-success"></i>
                          <b className="textwhite">UPGRADE </b> - Activation of
                          the next expensive level.
                        </div>
                        <div className="dashboard-text-4 banner-section1-textcolor1">
                          <i className="fa-solid fa-arrows-spin text-success"></i>
                          <b className="textwhite">CYCLE </b> - Filling all
                          places at the level and automatic reopening of the
                          current level.
                        </div>
                        {/* <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                                className="fa-solid fa-circle-exclamation text-danger"></i><b
                                                    className="textwhite">
                                                    LOST PROFITS </b> - a payment that was redirected to
                                                an upline partner due to overtake or lack of upgrade.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                                className="fa-solid fa-gift text-info"></i><b className="textwhite"> EXTRA
                                                    PROFITS </b> - the payment that comes to you due to the lost profit of
                                                the downline partner.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                                className="fa-solid fa-rocket text-warning"></i><b className="textwhite">
                                                    SPILLOVER FROM ABOVE </b> - the partner of your upline partner who took
                                                a place in your level.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                                className="fa-solid fa-rocket text-warning"></i><b className="textwhite">
                                                    SPILLOVER FROM BELOW </b> - the partner of your downline partner who
                                                took a place in your level.</div> */}
                        <div className="dashboard-text-4 banner-section1-textcolor1 ">
                          <i className="fa-regular fa-circle-up text-danger"></i>
                          <b className="textwhite">OVERTAKE </b> - Activation by
                          the downline partner of the more expensive level you
                          don't have yet.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default InstructionInfo;
