import React from 'react';
// import './Modal.css'; // Create a CSS file for styling
import { RotatingLines } from 'react-loader-spinner'

const ModalProcess = ({ isOpens, onClose }) => {
    if (!isOpens) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-content align-items-center">
                    <RotatingLines
                        visible={true}
                        height="56"
                        width="56"
                        color="#daa520e9"
                        strokeWidth="3"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        strokeColor="#daa520e9"
                    />
                    <h2 className="modal-title">Transaction is Processing</h2>
                    <p className="modal-body">Do Not Refresh or Reload the Page</p>
                </div>
            </div>
        </div>
    );
};
export default ModalProcess;