import React, { useEffect, useState } from 'react'
import Style from "../Assets/Css/Style.css";
import dlogo from "../Assets/images/dlogo.webp";

import { AiFillQuestionCircle } from "react-icons/ai";
import { RiShareBoxFill } from "react-icons/ri";
import { IoWalletSharp } from "react-icons/io5";
import { BsArrowUp } from "react-icons/bs";
import { BsClipboard } from "react-icons/bs";
import { BsLink45Deg } from "react-icons/bs";
import $ from 'jquery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import { showUserTransactionInDashBoard, getloginid } from "../Dashboard-pages/api/authapis";
import { formatDistanceToNow } from 'date-fns';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import DarkLight from '../Dashboard-pages/Separate/Dark-Light';
import design from "../Assets/images/Design.png";
import Footer from './Footer';
import { Tooltip } from 'react-tooltip';
import black from "../Assets/images/Asset 2.png";
import white from "../Assets/images/Asset 1.png";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import { BiErrorCircle, BiLogOut } from "react-icons/bi";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaCopy } from 'react-icons/fa';

function Login() {

  // $(document).ready(function () {
  //   $(window).scroll(function () {
  //     var scroll = $(window).scrollTop();
  //     if (scroll > 50) {
  //       $(".login-section-one").css("background", "#000210");
  //     }

  //     else {
  //       $(".login-section-one").css("background", "transparent");
  //     }
  //   })
  // })


  const [connectedWallet, setConnectedWallet] = useState(null);
  const [getReferralId, setGetreferralId] = useState();
  const [registerDataList, setRegisterDataList] = useState([]);
  const [visibleItems, setVisibleItems] = useState(config.REGISTER_HISTORY_SEE_MORE_LESS);
  const [isLevelIncomeContractAddressCopied, setIsLevelIncomeContractAddressCopied] = useState(false);
  const [isXcoreContractAddressCopied, setIsXcoreContractAddressCopied] = useState(false);
  const toastCheck = React.useRef(null);
  const [previewloader, setPreviewloader] = useState(false);
  const [istypeAdd, setIstypeAdd] = useState(false)
  const [show, setShow] = useState(false);

  const sliceAddress = (getTheConnectedWalletAddress, startLength = 6, endLength = 6) => {
    try {
      if (!getTheConnectedWalletAddress) return '';

      const prefix = getTheConnectedWalletAddress.slice(0, startLength);
      const suffix = getTheConnectedWalletAddress.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };




  var web3js = '';

  if (window.ethereum) {
    // web3js = new Web3(window.ethereum);
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }

  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);

  const addCustomNetwork = async () => {

    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      else {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }


    }
  };


  const checkConnectedWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });

      if (accounts.length >= 2) {
        toast.warning(ToastApi.ConnectAnyOneAccount);
      } else {
        const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(accounts[0]).call();
        if (checkUserRegisteredExists === true) {
          sliceAddress(accounts[0]);
          setConnectedWallet(accounts[0])
        }
        else {
          toast.warning(ToastApi.RegisterToLogin)
          sliceAddress(accounts[0]);
          setConnectedWallet(accounts[0])
        }
      }

    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });


        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
          checkConnectedWallet();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);

      }
    } catch (error) {
      console.log("err", error.code);
      toast.error(ToastApi.WalletConnectionError);
    }
  };

  const handleInstallMetaMask = async (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus()
    }
  };


  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const handleDesktopConnectClick = () => {
    try {
      connectWallet()
    } catch (error) {
      console.log('error', error)
    }
  }

  const mobilewalletConnect = async () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      try {

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          if (accounts && accounts.length > 0) {
            const address = accounts[0];
            localStorage.setItem("address", accounts[0]);
            setConnectedWallet(accounts[0]);

          } else {
            console.log("Account request failed");
          }
        }
      } catch (error) {
        console.error("Failed to request accounts:", error);
      }
    }
  };

  const handleMobileConnectClick = async () => {

    try {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        mobilewalletConnect();
        return;
      }
      else {
        // const confirmed = window.confirm(
        //   "Connect your MetaMask wallet to this app?"
        // );

        let dappUrl = `${config.FRONTEND_URL}`;
        let metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
        window.location.href = metamaskAppDeepLink;

      }

    } catch (error) {
      console.log("error", error);
    }

  };




  useEffect(() => {
    // checkConnectedWallet();
    const switchNetwork = async () => {
      try {
        // Switch network
        await window.ethereum.request({
          method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
          params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
        })

      } catch (error) {
        console.log("error", error);
      }

    }
    switchNetwork();

    const eventAccountWatch = async () => {
      try {
        if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
          const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });

          if (accounts.length == 1) {
            setConnectedWallet(accounts[0]);
          } else if (accounts.length > 1) {
            if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount);
          }
          else if (accounts.length == 0) {
            if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.ConnectYourWallet, { autoClose:1300});
          }
        }
        else {
          if (isMobileDevice()) {
            if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn("connect mobile metamask");
          } else {
            if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn(ToastApi.PleaseInstallMetamask);
          }
        }
        // else if (accounts.length == 0) {
        //   toast.warning(ToastApi.ConnectYourWallet);
        // }
      } catch (error) {
        console.log("eventAccountError", error);
      }
    }

    eventAccountWatch();

  }, [connectedWallet]);
  // setGetreferralId

  const JoinDChain = async () => {
    try {
      if (web3js) {
        const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(connectedWallet).call();
        if (checkUserRegisteredExists) {
          const checkRegisteredIds = await DemoCorePlanContract.methods.users(connectedWallet).call();
          if (checkRegisteredIds.id.toString()) {
            setInterval(() => {
              window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + checkRegisteredIds.id.toString()
            }, 500);
          }
        } else {
          setInterval(() => {
            window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
          }, 500);
        }
      }
    } catch (error) {

    }
  }
  const handleInputChange = async (event) => {
    try {
      const inputValue = event.target.value;


      const vall = /^0x[a-fA-F0-9]{40}$/g.test(inputValue)

      // Check if the input value is numeric using a regular expression
      if (vall) {
        setIstypeAdd(true);
        setGetreferralId(inputValue)
      } else {
        // If the input value is numeric, update the state
        setGetreferralId(inputValue);
      }
      // Check if the input value is numeric using a regular expression
      // if (/^\d*$/.test(inputValue)) {
      //   // If the input value is numeric, update the state
      //   // setGetreferralId(inputValue);
      // } else {
      //   // If the input value is not numeric, show an alert
      //   toast.warning(ToastApi.EnterNumberOnly);
      // }
    } catch (error) {
      console.log("error", error);
    }
  };

  const userRegisterDetails = async (event) => {
    try {
      event.preventDefault();

      if (getReferralId === "" || getReferralId === undefined) {
        return toast.warning("Id or Address Required")
      }
      setPreviewloader(true)

      if (istypeAdd) {

        const useraddress = getReferralId
        const getidvalue = await getloginid({ useraddress });

        const statuscheck = getidvalue.status
        if (statuscheck) {
          const useridlocate = getidvalue.userId.New_User_Referral_Id

          const intervalId = setInterval(() => {
            window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + `${useridlocate}`;
            setPreviewloader(false)
          }, 1000);
        }
        else {
          toast.warning(ToastApi.ReferralIdNotFound);
          setPreviewloader(false)
          setIstypeAdd(false)
        }

      } else {

        try {
          const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(getReferralId).call();

          if (checkUserRegisteredExists !== config.REGISTRATION_ZERO_ADDRESS) {
            const intervalId = setInterval(() => {
              window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + `${getReferralId}`;
              setPreviewloader(false)
            }, 1000);
          } else {
            toast.warning(ToastApi.ReferralIdNotFound);
            setPreviewloader(false)
          }
        } catch (error) {
          toast.warning(ToastApi.ReferralIdNotFound);
          setPreviewloader(false)
        }
      }
    } catch (error) {
      console.log("userregistererror", error);
      setPreviewloader(false)
    }

  }



  const handleSeeMoreClick = () => {
    // Increase the number of visible items (you can adjust the step as needed)
    setVisibleItems((prevVisibleItems) => prevVisibleItems + config.REGISTER_HISTORY_SEE_MORE_LESS);
  };

  const handleSeeLessClick = () => {
    // Decrease the number of visible items (you can adjust the step as needed)
    setVisibleItems((prevVisibleItems) => Math.max(prevVisibleItems - config.REGISTER_HISTORY_SEE_MORE_LESS, config.REGISTER_HISTORY_SEE_MORE_LESS));
  };

  const getListOfRegisterData = async () => {
    try {
      const resultOfRegisterData = await showUserTransactionInDashBoard();

      const modifiedDataArray = [];

      for (const registerDataItem of resultOfRegisterData.data.data) {
        // Convert the Create_At timestamp to a JavaScript Date object
        const createdAtDate = new Date(registerDataItem.Create_At);

        const newItem = {
          newUserReferralId: registerDataItem.New_User_Referral_Id,
          newUserRegisteredAddress: registerDataItem.New_User_Registered_Address,
          referralAddress: registerDataItem.Referral_Address,
          referralId: registerDataItem.Referral_ID,
          status: registerDataItem.status,
          txHash: registerDataItem.tx_Hash,
          dateTime: formatDistanceToNow(createdAtDate, { addSuffix: true }),
        };

        modifiedDataArray.push(newItem);
      }
      // modifiedDataArray.sort((a, b)=>new Date())
      setRegisterDataList(modifiedDataArray);
    } catch (error) {
      console.error('Error fetching register data:', error);
    }
  };




  const handleXcoreContractAddressCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
      setIsXcoreContractAddressCopied(true);
      toast.success(ToastApi.ContractAddressCopied, { autoClose: 2000 });
    } catch (error) {
      toast.error(ToastApi.FailedToCopy);
    }
  };

  const handleLevelIncomeContractAddressCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(config.PLAN_1_CONTRACT_ADDRESS);
      setIsLevelIncomeContractAddressCopied(true);
      toast.success(ToastApi.ContractAddressCopied, { autoClose: 2000 });
    } catch (error) {
      toast.error(ToastApi.FailedToCopy);
    }
  };

  const switchNetwork = async () => {
    try {
      // Switch network
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
      });
    } catch (error) {

    }

  }

  useEffect(() => {
    getListOfRegisterData();


    // switchNetwork();

  }, []);

  const invitedIdRedirect = async (invitedId) => {
    try {

      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + `${invitedId}`;
    } catch (error) {

    }
  };


  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  const RegAddressShow = async () => {
    try {
      const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(connectedWallet).call();
      if (checkUserRegisteredExists == true) {
        setShow(true)
      } else {
        setShow(false)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    RegAddressShow()
  }, [connectedWallet])

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(config.DUSD_TOKEN_ADDRESS)
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
      toast.success(ToastApi.CopiedAddress);
  };

  const AddDchain = async () => {
    try {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);

      }
    } catch (error) {
      toast.error(ToastApi.WalletConnectionError);
    }
  }

  return (
    <div className='d-ecosystem-login'>
      <ToastContainer />
      <div className='login-section-one fixed-top'>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand pb-lg-0 pt-lg-2" href="/">
              <img className="banner-img1 black-logo" src={black} alt="logo" />
              <img className="banner-img1 white-logo" src={white} alt="logo" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-lg-center">
                <li ><DarkLight /></li>
                <li className='ms-lg-3 mt-2 mt-lg-0'>
                  {connectedWallet ? (
                    <div className="button1" type="submit" > {sliceAddress(connectedWallet)}</div>
                  ) : (
                    <button className="button1" type="submit" onClick={() => {
                      isMobileDevice() ? handleMobileConnectClick() : handleDesktopConnectClick()
                    }}>
                      Connect Wallet
                    </button>
                  )}

                </li>

                {show ? (
                  <>
                  </>
                ) : (
                  <>
                    <li className="nav-item button-hover rounded-pill me-lg-4 ms-lg-3 mt-2 mt-lg-0">
                      <Link className="" to="/registration">
                        <button className="button1  fw-bold ">Registration</button>
                      </Link>
                    </li>
                  </>
                )}

              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div className='login-section-two'>
        <div className="container">
          <div className="row secondpartparent align-items-center d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="secondpartchild">
                <h4 className="pt-4">PropelX DUSD main page</h4>
                <p className="pt-2">Connect wallet to Register or Login</p>
                <div className="secondpartbtn">
                  {connectedWallet ? (
                    <p className="btn secondpartbtn1 btn-dark my-3 me-4 rounded-pill" onClick={() => { JoinDChain(); switchNetwork() }}> Join PropelX</p>
                  ) : (
                    <button className="btn secondpartbtn1 btn-dark my-lg-3 me-4 rounded-pill px-lg-3" onClick={() => {
                      isMobileDevice() ? handleMobileConnectClick() : handleDesktopConnectClick()
                    }}>
                      Connect Now
                    </button>
                  )}

                  <a href='/instructions/1'>
                    <button className="btn secondpartbtn2 my-lg-2 rounded-pill px-lg-3">
                      Help Me
                    </button></a>
                </div>
              </div>
            </div>
            <div className='col-lg-6 d-none d-lg-block ms-auto'>
              <img src={design} className='banner-img' alt='img'></img>
            </div>
          </div>
        </div>
      </div>

      <div className="login-section-three mt-4" data-aos="fade-up">
        <div className="container">
          <div className="row py-3">
            <div className="col-lg-12">
              <div className="thirdpartchild">
                <h4 className="fs-2 fw-bold">Account preview</h4>

              </div>
            </div>
          </div>

          <div className="row d-flex align-items-baseline flex-lg-row-reverse gy-3">
            <div className="col-lg-6">
              <p>
                Look up any PropelX DUSD member account in preview mode.
                Enter ID or DUSD address to preview.
              </p>
              <div className="thirdpartchild0">
                <h5 className="pb-2">Enter ID or DUSD wallet</h5>
                <form onSubmit={userRegisterDetails}>
                  <input
                    type="text"
                    placeholder="example:87654"
                    className="inputchild0 text-white"
                    value={getReferralId}
                    onChange={handleInputChange}
                  // inputmode="numeric"
                  // pattern="[0-9]*"
                  />
                  {previewloader
                    ? (<button className="loadbtn" type='button' >
                      <div class="spinner-border " role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>)
                    : (<button className="tbtn" type='submit'>Preview</button>)}
                </form>
              </div>
            </div>
            <div class="col-lg-6  d-flex registerationradius justify-content-end flex-column py-3 px-4">
              <div class="row py-2">
                <div class="col-lg-12 d-flex align-items-center">
                  <div class="fs-4 fw-bold"><BiErrorCircle className="me-1 fw-4" /></div>
                  <p class="fs-4 fw-bold mb-0">Information</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">

                  <p class="text-size">Steps to register:</p>
                  <ul className='info-steps ps-3'>
                    <li className=''>Connect your wallet (MetaMask or D-Wallet extension).</li>
                    <li className=''>Ensure you have 15 DUSD to purchase a Level 1 Card. You can swap 15 USDT for 15 DUSD on the BNB Network via the D-ecosystem Salespad, or buy directly using your card through MetaMask's fiat payment system.</li>
                    <li className=''>Remember to purchase a small amount of $BNB and $DCX to cover the gas fees.</li>
                  </ul>
                  <p className='banner-texttwo'>
                    Purchase DUSD & DCX by clicking below
                  </p>
                  <p class="fs-6">
                    <div className='d-flex align-items-center  gap-3 flex-wrap'>
                      <div><a href={`${config.SALESPAD_DUSD_URL}`} target='_blank'><button className='info-button'>Buy DUSD</button></a></div>
                      <div><a href={`${config.SALESPAD_DUSD_URL}`} target='_blank'><button className='info-button'>Buy DCX</button></a></div>
                      <div><a ><button onClick={AddDchain} className='info-button'>Add D-Chain</button></a></div>
                    </div>
                  </p>
                  <div className='text-two'>
                    DUSD Contract Address :
                    <span className="">
                      <a
                        href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DUSD_TOKEN_ADDRESS}`}
                        target='_blank'
                        className='tutorial-link'
                        rel="noopener noreferrer"
                      >
                        {sliceAddress(config.DUSD_TOKEN_ADDRESS)}
                      </a>
                      <button type='button' onClick={handleCopy} className='bg-transparent border-0'><FaCopy className='tutorial-link ms-1'  /></button>
                    </span>
                  </div>
                  <div className='text-two'>
                  How to start PropelX Journey?
                    <a href='https://youtu.be/ZynHzHUb30g?feature=shared' target='_blank' className='tutorial-link'>Tutorial Video </a>
                  </div>
                  <div className='text-two'>
                    Contact Support :
                    <a href="mailto:info.propelx@gmail.com" target='_blank' className='tutorial-link'>
                      info.propelx@gmail.com
                    </a>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>

      <div className="login-section-four pb-5">
        <div className="container rounded">
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="fourthpartchild">
                <h5 className="">
                  Platform recent activity
                  <AiFillQuestionCircle className="fs-6 ms-2 platform-recent" id="clickable" />
                  <Tooltip anchorSelect=".platform-recent" place="top" clickable>
                    <div className='platform-tt'>PropelX DUSD real-time global events with links to transaction in blockchain</div>
                  </Tooltip>
                </h5>
              </div>
            </div>
          </div> */}

          <div className="row" data-aos="fade-up">
            {/* <div className="col-lg-8 fourthpartwhole0 gy-1 py-2 px-4 h-100">

              {registerDataList.slice(0, visibleItems).map((item, index) => (


                <>
                  <div className="row d-flex align-items-center fourthbanner9 py-4">
                    <div className="col-lg-8  col-md-8 col-8 d-flex align-items-lg-center align-items-start">
                      <div className="fourthbanner20 d-flex align-items-center">
                        <span className="fourthbannericontags">
                          <IoWalletSharp className="fs-5" />
                        </span>
                      </div>
                      <div className="fourthbanner12 d-flex align-items-center flex-wrap">
                        <span className=" mx-2">
                          <button className="px-2 py-1 border-0 fourthbannerbtn1" onClick={() => { invitedIdRedirect(item.newUserReferralId) }}>
                            ID {item.newUserReferralId}
                          </button>
                        </span>
                        <span className="btn21 mx-2">
                          <span className="">
                            + 15 DUSD <span className="opacity-50"> in </span>
                            <bold className="fourthgreen">xCore</bold>
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-end">
                      <div className="fourthbanner13 d-flex align-items-center">
                        <span>
                          <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.txHash}`} target='_blank'>
                            <RiShareBoxFill className="fs-6 me-2" />
                          </a>
                        </span>
                        <span className="opacity-50">{item.dateTime} </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}



              {registerDataList.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (
                <div className="row pt-2">
                  <div className="col-lg-12">
                    <button
                      className="w-100 py-2 fourthbannerbtn2"
                      onClick={handleSeeMoreClick}
                    >
                      <IoIosArrowDown className="fs-5" /> {'See More'}
                    </button>
                  </div>
                </div>
              )}

              
            </div> */}

            <div className="col-lg-4 gy-3">
              {/* <div className="row ">
                <div className="col-lg-12 ">
                  <div className="fourthpartchild2 py-1 px-4">
                    <h5 className=" bannercontent0 py-3">
                      Members Total
                      <AiFillQuestionCircle className=" mx-1 mb-1 member-total" id='clickable' />
                      <Tooltip anchorSelect=".member-total" place="top" clickable>
                        <div className='platform-tt'>Total number of members in PropelX DUSD</div>
                      </Tooltip>
                    </h5>
                    <h3 className=" bannerdate fs-4 pt-3">{registerDataList.length}</h3>
                    <p className="banneryear1">
                    </p>
                  </div>
                </div>
              </div> */}

              {/* <div className="row mt-4 gy-2">
                <div className="col-lg-12">
                  <div className="fourthpartchild2 py-1 px-4">
                    <h5 className="bannercontent0 py-3">
                      Members Received{" "}
                      <AiFillQuestionCircle className=" mx-1 member-receive" id='clickable' />
                      <Tooltip anchorSelect=".member-receive" place="top" clickable>
                        <div className='platform-tt'>Total amount received by all members of PropelX</div>
                      </Tooltip>
                    </h5>
                    <h3 className=" bannerdate fs-4 pt-3">
                      {registerDataList.length * 15} DUSD
                    </h3>
                    <p className="banneryear1">
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="row p-3">
                <div className="col-lg-12 fourthrounding px-4">
                  <div className="row ">
                    <div className="col-lg-12">
                      <p className="bannercontent0  pt-3">
                        PropelX DUSD Contracts
                      </p>
                    </div>
                  </div>

                  

                  <div className="row d-flex align-items-center fourthbanner6 py-1">
                    <div className="col-lg-5">
                      <span className="fw-6 fourthbanner5 py-2 ">
                        <bold>xCore</bold>
                      </span>
                    </div>
                    <div className="col-lg-5 d-flex align-items-center  py-2">
                      <span className="me-2">
                        <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DEMO_CORE_PLAN_CONTRACT_ADDRESS}`} target='_blank'>
                          {sliceAddress(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS)}
                        </a>
                      </span>
                      <span className="me-2" onClick={handleXcoreContractAddressCopyClick} disabled={isXcoreContractAddressCopied}>
                        <BsClipboard />
                      </span>
                      <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DEMO_CORE_PLAN_CONTRACT_ADDRESS}`} target='_blank'>
                        <span className="me-2">
                          <BsLink45Deg />
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center fourthbanner6 py-1">
                    <div className="col-lg-5">
                      <span className="fw-6 fourthbanner5 py-2">
                        <bold>Level Income</bold>
                      </span>
                    </div>
                    <div className="col-lg-5 d-flex align-items-center py-2">
                      <span className="me-2">
                        <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.PLAN_1_CONTRACT_ADDRESS}`} target='_blank'>
                          {sliceAddress(config.PLAN_1_CONTRACT_ADDRESS)}
                        </a>
                      </span>
                      <span className="me-2" onClick={handleLevelIncomeContractAddressCopyClick} disabled={isLevelIncomeContractAddressCopied}>
                        <BsClipboard />
                      </span>
                      <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.PLAN_1_CONTRACT_ADDRESS}`} target='_blank'>
                        <span className="me-2">
                          <BsLink45Deg />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="login-section-five my-4 py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="fifthsectionchild">
                <h4 className="fs-2">
                  Need help on how to use the platform ?
                </h4>
                <p className="opacity-50 fs-6 py-3">
                  Get qualified support from PropelX experts via online chat
                </p>
                <button className="btn  fifthbutton  border-0 outline-none text-white">
                  contact support
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />

    </div>
  )
}

export default Login