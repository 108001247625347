import "../Assets/Css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import finalgif from "../Assets/images/final.gif";

function Links() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-6">
                                    <div className="card h-100 links-card-2">
                                        <div className="d-flex flex-row gap-1 mt-1 align-items-center">
                                            <div>
                                                <span className="dashboard-text-4 mb-0 ms-2">New partners by</span>
                                                <select className="link-page-select">
                                                    <option value="volvo">month</option>
                                                    <option value="saab">year</option>
                                                </select>
                                            </div>
                                            <div className="ms-auto">
                                                <button className="ms-3 dashboard-button-1">July</button>
                                            </div>
                                            <div className="">
                                                <button className="ms-3 dashboard-button-1">2023</button>
                                            </div>
                                        </div>
                                        <ul className="chart">
                                            <li>
                                                <p className="text-center mb-0">4</p>
                                                <span style={{ height: "20%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">2</p>
                                                <span style={{ height: "42%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">8</p>
                                                <span style={{ height: "50%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">9</p>
                                                <span style={{ height: "70%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">4</p>
                                                <span style={{ height: "45%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">2</p>
                                                <span style={{ height: "42%" }}></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card bg-transparent h-100">
                                        <div className="row g-4">
                                            <div className="col-lg-4">
                                                <div className="card dashboard-card-2">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Link clicks</p>
                                                    <p className="mb-2 dashboard-text-2 ms-2">47847</p>
                                                    <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                        <div className="dashboard-text-7">
                                                            <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                        </div>
                                                        <div className="ms-auto">
                                                            <img className="dashboard-partners-gif" src={finalgif}
                                                                alt="gif" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card dashboard-card-2">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Partners</p>
                                                    <p className="mb-2 dashboard-text-2 ms-2">0</p>
                                                    <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                        <div className="dashboard-text-7">
                                                            <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                        </div>
                                                        <div className="ms-auto">
                                                            <img className="dashboard-partners-gif" src={finalgif}
                                                                alt="gif" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card dashboard-card-2">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Team</p>
                                                    <p className="mb-2 dashboard-text-2 ms-2">0</p>
                                                    <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                        <div className="dashboard-text-7">
                                                            <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                        </div>
                                                        <div className="ms-auto">
                                                            <img className="dashboard-partners-gif" src={finalgif}
                                                                alt="gif" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card dashboard-card-2">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <p className="dashboard-text-4 mb-0 ms-2">Link clicks</p>
                                                            <p className="mb-2 dashboard-text-2 ms-2">47847</p>
                                                            <div
                                                                className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                                <div className="dashboard-text-7">
                                                                    <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <img className="dashboard-partners-gif"
                                                                        src={finalgif} alt="gif" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4 mt-4">
                                <div className="col-lg-12">
                                    <div className="card links-card-1">
                                        <p className="dashboard-text-4 mb-2">Personal link <span className="ms-1 "><i
                                            className="fa-solid fa-circle-question" data-bs-toggle="tooltip"
                                            data-bs-placement="top" data-bs-custom-className="custom-tooltip"
                                            data-bs-title="This top tooltip is themed via CSS variables."></i></span>
                                        </p>
                                        <div className="d-flex flex-lg-row flex-column align-items-center">
                                            <div>
                                                <p className="link-text-1 mb-0">d-mlm.io/xqg1z8</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <button className="dashboard-preview-btn-2 mt-1">Copy <i
                                                    className="fa-solid fa-copy ms-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card links-card-1" style={{ backgroundcolor: "rgba(255, 236, 64, 0.1)" }}>
                                        <p className="dashboard-text-4 mb-2">Personal link <span
                                            style={{ color: "#ffc600" }}>xCore</span><span className="ms-1 "><i
                                                className="fa-solid fa-circle-question" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-className="custom-tooltip"
                                                data-bs-title="This top tooltip is themed via CSS variables."></i></span>
                                        </p>
                                        <div className="d-flex flex-lg-row flex-column align-items-center">
                                            <div>
                                                <p className="link-text-1 mb-0">d-mlm.io/xqg1z8</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <button className="dashboard-preview-btn-2 mt-1">Copy <i
                                                    className="fa-solid fa-copy ms-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4 mt-2">
                                <div className="col-lg-12">
                                    <span className="dashboard-text-2">Custom links</span>
                                    <p className="mb-0 dashboard-text-4 mt-2">Promote different teams by using cutom links, Create
                                        and set up custom links for your team. Include as many participants in your link as you
                                        want and set up how the new registrations will be distributed within the group.
                                    </p>
                                </div>
                            </div>
                            <div className="row p-2 g-4 mt-2">
                                <div className="col-lg-6">
                                    <div className="card links-card-2">
                                        <span className="dashboard-text-9 mb-4">Custom link #1</span>
                                        <div className="dashboard-card-1-inner-3 d-flex flex-lg-row flex-column gap-2 align-items-center mt-2"
                                            style={{ borderradius: "56px" }}>
                                            <div>
                                                <p className="mb-0 dashboard-text-6">D-Chain.io/br/c4pk5kx4c</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <button className="ms-3 dashboard-button-1">copy</button>
                                            </div>
                                        </div>

                                        <div className="row g-4 mt-2">
                                            <div className="col-lg-4">
                                                <div className="card links-card-2-inner-1">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Link clicks</p>
                                                    <p className="mb-2 dashboard-text-9 ms-2">47847</p>
                                                    <div className="dashboard-text-7 ms-2 mt-3 mb-2">
                                                        <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card links-card-2-inner-1">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Partners</p>
                                                    <p className="mb-2 dashboard-text-9 ms-2">0</p>
                                                    <div className="dashboard-text-7 ms-2 mt-3 mb-2">
                                                        <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card links-card-2-inner-1">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Team</p>
                                                    <p className="mb-2 dashboard-text-9 ms-2">0</p>
                                                    <div className="dashboard-text-7 ms-2 mt-3 mb-2">
                                                        <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-lg-row mt-4">
                                            <div>
                                                <p className="mb-0 dashboard-text-6"><span
                                                    className="mb-0 dashboard-text-4">Owner</span>
                                                    ID 1</p>
                                            </div>
                                            <div className="ms-auto">
                                                <p className="mb-0 dashboard-text-6"><span
                                                    className="mb-0 dashboard-text-4">Participants</span>
                                                    1</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
}

export default Links;