import "../Assets/Css/dashboard-style.css";

import Topnav from "./Separate/Top-nav";
import Sidenav from "./Separate/Side-nav";
import React, { useEffect, useState } from "react";
import { userRefLevel } from "./api/authapis";
import imglogo from "../Assets/images/dusd.png";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import {
  getAllUserRegisterList, buyNewLevelApprovalStatus,
  buyNewNextLevelOfMLMId, getParentID, linkedIdsFromData, findBuyerAddressAndParentId, getTheLevelOfCompletedTransactionsDetsils, getTheBuyNextLevelDmlmXcoreCompletedTransactionsDetsils, getTheRegistrationDmlmXcoreCompletedTransactionsDetsils
} from '../Dashboard-pages/api/authapis';
import { useNavigate } from 'react-router-dom';

import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import Circleanimation from "../Assets/images/cirlcleloader.gif"
import { Circles } from "react-loader-spinner"

function Dmlmxcoreinner() {
  const navigate = useNavigate();


  const [connectedWallet, setConnectedWallet] = useState(null);

  const [referredByUserId, setReferredByUserId] = useState();

  const [referralAddress, setReferralAddress] = useState();


  const [referralWalletAddress, setReferralWalletAddress] = useState();

  const [firstLoopStageOfIdNumber, setFirstLoopStageOfIdNumber] = useState([]);
  const [firstSeconderyLevelStatus, setFirstSeconderyLevelStatus] = useState([]);

  const [firstLevelStatus, setFirstLevelStatus] = useState(false);


  const [checkLevelMatching, setCheckLevelMatching] = useState();

  const [partnerFirstLoopLength, setPartnerFirstLoopLength] = useState(0);


  const [transactionDetailsInTable, setTransactionDetailsInTable] = useState([]);
  const [visibleItems, setVisibleItems] = useState(config.REGISTER_HISTORY_SEE_MORE_LESS);

  const [getFirstCycles, setGetFristCycles] = useState();

  const [cycleCount, setCycleCount] = useState();
  const [theCycleCount, setTheCycleCount] = useState(1);
  const [displayCount, setDisplayCount] = useState(0);
  const toastCheck = React.useRef(null);
  const [cyclecountnumber, setCycleCountnumber] = useState()
  const [totalLevelRevenue, setTotalLevelRevenue] = useState(0);
  const [transactionDetailsInTablelevel1, setTransactionDetailsInTablelevel1] = useState([])
  const [cycleloader, setCycleloader] = useState(false);

  var web3js = '';

  if (window.ethereum) {
    // web3js = new Web3(window.ethereum);
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }

  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);
  const gasPriceWei = web3js.utils.toWei('4', 'gwei'); // Adjust the gas price as needed

  const locateUserId = window.location.href.split('=')[1].split('&')[0];
  const locateUserLevel = window.location.href.split('=')[2];
  const [DUSDPrice, setDUSDPrice] = useState(0);
  const [lastTrueIndex, setLastTrueIndex] = useState(0);
  const [count, setCount] = useState(locateUserLevel);

  const checkTheLevelOfUsers = async () => {
    switch (locateUserLevel) {
      case '1':
        setDUSDPrice(15);
        break;
      case '2':
        setDUSDPrice(30);
        break;
      case '3':
        setDUSDPrice(100);
        break;
      case '4':
        setDUSDPrice(200);
        break;
      case '5':
        setDUSDPrice(300);
        break;
      case '6':
        setDUSDPrice(500);
        break;
      case '7':
        setDUSDPrice(1000);
        break;
      case '8':
        setDUSDPrice(1500);
        break;
      case '9':
        setDUSDPrice(2500);
        break;
      case '10':
        setDUSDPrice(5000);
        break;
      default:
        setDUSDPrice(0)
        break;
    }
  }

  const checkConnectedWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
        setConnectedWallet(accounts?.[0] || null);
      }
    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const getTheUserWalletAddress = async () => {
    try {
      const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();


      setReferralAddress(checkUserRegisteredExists);

      const checkReferralUserDetails = await DemoCorePlanContract.methods.users(checkUserRegisteredExists).call();

      const checkReferralIDWithAddressUserDetails = await DemoCorePlanContract.methods.users(checkReferralUserDetails.referrer).call();
      const objAddress = await convertBigIntToString(checkReferralIDWithAddressUserDetails);

      setReferredByUserId(objAddress.id);
    } catch (error) {
      console.error('Error in getTheUserWalletAddress:', error);
    }
  };

  useEffect(() => {
    checkTheLevelOfUsers();
    checkConnectedWallet();
    getTheUserWalletAddress();

  }, [locateUserId]);


  const convertBigIntToString = async (obj) => {
    if (typeof obj === 'bigint') {
      return obj.toString();
    } else if (typeof obj === 'object') {
      for (const key in obj) {
        obj[key] = await convertBigIntToString(obj[key]);
      }
    }

    return obj;
  };

  // Call the async function
  getTheUserWalletAddress();

  const getListOfRegisterData = async () => {
    try {

      const resultOfRegisterData = await getAllUserRegisterList(locateUserId);
      setCycleloader(true)

      const arrayOfArray = [];
      arrayOfArray.push(resultOfRegisterData.data.data)
      for (const registerDataItem of arrayOfArray) {

        const levelOfData = await checkNextNewLevelStatus();
        const checkUserRegisteredIdExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
        const FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
        setFirstLevelStatus(FirstLevelStatus);
        if (FirstLevelStatus) {
          const findTheGroupOfReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredIdExists).call();
          if (registerDataItem && findTheGroupOfReferredID.length > 0) {

            if (registerDataItem.New_User_Referral_Id == locateUserId) {

              for (let i = 0; i < levelOfData.result.length; i++) {

                var elements = levelOfData.result[i].Buy_Level_Status;
                setCheckLevelMatching(elements)
              }

              const referralAddressesSet = [];
              const secondReferralAddressesSet = [];

              if (locateUserLevel == '1') {
                if (FirstLevelStatus) {

                  const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                  const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                  let checkTheUserLevel = 3 - firstUserlevelsl.toString();

                  setGetFristCycles((firstUserlevelsl.toString()) + 1);

                  for (let i = 0; i < findTheGroupOfReferredID.length; i++) {
                    const element = findTheGroupOfReferredID[i];
                    const checkUserRegisteredExists = await DemoCorePlanContract.methods.users(element).call();

                    referralAddressesSet.push(checkUserRegisteredExists.id.toString());
                  };

                  setPartnerFirstLoopLength(referralAddressesSet.length)
                  let new1cycle = [...referralAddressesSet];
                  dmlmXcoreTransactionDetailsWithRegisteration(new1cycle);

                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)                  
                  }

                  if (new1cycle.length < 3) {
                    new1cycle.push(0);
                  }


                  const firstThreeElements = new1cycle.slice(0, 3);
                  setFirstLoopStageOfIdNumber(firstThreeElements);
                  let userlen2 = 9 - firstUserlevel2[2].length;

                  var new2cycle = [...referralAddressesSet];
                  for (let i = 4; i < userlen2; i++) {

                    new2cycle.push(0)
                  }

                  while (new2cycle.length < 9) {
                    new2cycle.push(0);
                  }

                  const sliceElements = new2cycle.slice(3, 12);
                  if (sliceElements.length !== 9) {
                    const elementsToAdd = 9 - sliceElements.length;

                    for (let i = 0; i < elementsToAdd; i++) {
                      sliceElements.push(0);
                    }
                  }
                  setFirstSeconderyLevelStatus(sliceElements);



                  if (referralAddressesSet.length > 0) {

                    // Calculate the number of groups of 12 data points
                    const groupsOfTwelve = Math.ceil(referralAddressesSet.length / 12);

                    setDisplayCount(groupsOfTwelve);
                  }
                  setCycleloader(false)

                }
              } else if (locateUserLevel > "1") {


                let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
                setFirstLevelStatus(TwoLevelStatus);
                if (TwoLevelStatus && levelOfData.result.length > 0) {

                  // var levelOfData = await checkNextNewLevelStatus();

                  for (let i = 0; i < levelOfData.result.length; i++) {
                    if (levelOfData.result[i].Buy_Level_Status == 'Level-' + locateUserLevel) {
                      var element = levelOfData.result[i].Owner_MLM_ID;

                      if (element) {

                        secondReferralAddressesSet.push(element);
                      } else {


                        secondReferralAddressesSet.push(element);
                      }
                    }
                  }
                  // const secondReferralAddressesSet = new Set(); // Assuming you have your Set defined

                  // Your array to store items in batches
                  const batchesArray = [];

                  // Convert Set to an array
                  const referralAddressesArray = Array.from(secondReferralAddressesSet);
                  console.log("referralAddressesArray", referralAddressesArray);
                  if (referralAddressesArray.length > 0) {

                    for (let i = 0; i < referralAddressesArray.length; i += 12) {
                      batchesArray.push(referralAddressesArray.slice(i, i + 12));
                      let new1cycle = [...batchesArray];

                      dmlmXcoreBuyNextLevelTransactionDetails(new1cycle);
                      // Add additional elements to each array in new1cycle
                      for (let i = 0; i < new1cycle.length; i++) {
                        for (let j = new1cycle[0].length; j < 12; j++) {
                          new1cycle[0].push(0);
                        }
                      }


                      const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                      const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                      let checkTheUserLevel = 3 - secondUserlevelsl.toString();

                      // Add additional arrays to new1cycle if needed
                      // while (new1cycle.length < 3) {
                      //   const emptyArray = Array(12).fill(0); // Create an array of 12 zeros
                      //   new1cycle.push(emptyArray);
                      // }


                      for (let i = 1; i < checkTheUserLevel; i++) {
                        new1cycle.push(0)
                      }
                     

                      while (new1cycle.length < 3) {

                        new1cycle.push(0);
                      }

                      for (let j = 0; j < new1cycle.length; j++) {
                        const element = new1cycle[0];
                        if (Array.isArray(element)) {

                          const firstThreeElements = element.slice(0, 3);
                          setFirstLoopStageOfIdNumber(firstThreeElements);

                        } else {
                          console.error("Expected an array but found:", element);
                          // Handle the unexpected data format appropriately
                        }
                      };

                      // -------------------------------------------------
                      let new2cycle = [...batchesArray];

                      // Add additional elements to each array in new1cycle
                      for (let i = 4; i < new2cycle.length; i++) {
                        for (let j = new2cycle[0].length; j < 12; j++) {
                          new2cycle[0].push(0);
                        }
                      }

                      let userlen2 = 9 - secondUserlevel2[2].length;
                      //    // Add additional arrays to new1cycle if needed
                      //  while (new2cycle.length < 9) {
                      //   const emptyArray = Array(12).fill(0); // Create an array of 12 zeros
                      //   new2cycle.push(emptyArray);
                      // }

                      for (let i = 4; i < userlen2; i++) {
                        new2cycle.push(0)
                      }


                      while (new2cycle.length < 9) {

                        new2cycle.push(0);
                      }
                      for (let j = 0; j < new2cycle.length; j++) {
                        const element = new2cycle[0];
                        if (Array.isArray(element)) {

                          const firstThreeElements = element.slice(3, 12);
                          setFirstSeconderyLevelStatus(firstThreeElements);

                        } else {
                          console.error("Expected an array but found:", element);
                          // Handle the unexpected data format appropriately
                        }
                      }

                    }

                    setPartnerFirstLoopLength(secondReferralAddressesSet.length);

                    if (secondReferralAddressesSet.length > 0) {

                      // Calculate the number of groups of 12 data points
                      const groupsOfTwelve = Math.ceil(secondReferralAddressesSet.length / 12);
                      setDisplayCount(groupsOfTwelve);

                    }
                    setCycleloader(false)

                  }
                  else {
                    let firstloopdum = [];
                    let secondloopdum = [];

                    for (let index = 0; index < 3; index++) {
                      firstloopdum.push(0);
                    }

                    for (let index = 0; index < 9; index++) {
                      secondloopdum.push(0);
                    }
                    setFirstLoopStageOfIdNumber(firstloopdum);
                    setFirstSeconderyLevelStatus(secondloopdum);
                    setPartnerFirstLoopLength(0)
                    setDisplayCount(1)
                    setTotalLevelRevenue(0)
                    setCycleloader(false)
                  }
                  // Push items into the batchesArray in batches of 12

                } else if (TwoLevelStatus && levelOfData.result.length == 0) {
                  // var levelOfData = await checkNextNewLevelStatus();

                  for (let i = 0; i < levelOfData.result.length; i++) {

                    var elements = levelOfData.result[i].Owner_MLM_ID;
                    if (elements) {

                      secondReferralAddressesSet.push(elements);
                    } else {


                      secondReferralAddressesSet.push(elements);
                    }


                  }
                  if (checkLevelMatching == 'Level-' + locateUserLevel) {
                    setPartnerFirstLoopLength(secondReferralAddressesSet.length);
                  }


                  const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                  const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                  let checkTheUserLevel = 3 - secondUserlevelsl.toString();
                  setGetFristCycles((secondUserlevelsl.toString()) + 1);

                  let new1cycle = [];

                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)
                  }

                  while (new1cycle.length < 3) {
                    new1cycle.push(0);
                  }

                  const firstThreeElements = new1cycle.slice(0, 3);
                  setFirstLoopStageOfIdNumber(firstThreeElements);

                  let userlen2 = 9 - secondUserlevel2[2].length;

                  var new2cycle = [];
                  for (let i = 4; i < userlen2; i++) {

                    new2cycle.push(0)
                  }

                  while (new2cycle.length < 9) {
                    new2cycle.push(0);
                  }

                  const sliceElements = new2cycle.slice(3, 12);
                  if (sliceElements.length !== 9) {
                    const elementsToAdd = 9 - sliceElements.length;

                    for (let i = 0; i < elementsToAdd; i++) {
                      sliceElements.push(0);
                    }
                  }
                  setFirstSeconderyLevelStatus(sliceElements);

                  setPartnerFirstLoopLength(0)
                  setDisplayCount(1)
                  setTotalLevelRevenue(0)
                  setCycleloader(false)

                }
              }


            }
          } else {
            if (registerDataItem && findTheGroupOfReferredID.length == 0) {

              if (registerDataItem.New_User_Referral_Id == locateUserId) {


                for (let i = 0; i < levelOfData.result.length; i++) {

                  var elements = levelOfData.result[i].Buy_Level_Status;
                  setCheckLevelMatching(elements)
                }

                const referralAddressesSet = [];
                const secondReferralAddressesSet = [];

                if (locateUserLevel == '1') {

                  if (FirstLevelStatus) {


                    const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                    const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();

                    let checkTheUserLevel = 3 - firstUserlevelsl.toString();

                    setGetFristCycles((firstUserlevelsl.toString()) + 1);

                    for (let i = 0; i < findTheGroupOfReferredID.length; i++) {
                      const element = findTheGroupOfReferredID[i];
                      const checkUserRegisteredExists = await DemoCorePlanContract.methods.users(element).call();

                      referralAddressesSet.push(checkUserRegisteredExists.id.toString());
                    };

                    setPartnerFirstLoopLength(referralAddressesSet.length)
                    let new1cycle = [...referralAddressesSet];
                    dmlmXcoreTransactionDetailsWithRegisteration(new1cycle);

                    for (let i = 1; i < checkTheUserLevel; i++) {
                      new1cycle.push(0)
                    }

                    while (new1cycle.length < 3) {
                      new1cycle.push(0);
                    }

                    const firstThreeElements = new1cycle.slice(0, 3);
                    setFirstLoopStageOfIdNumber(firstThreeElements);

                    let userlen2 = 9 - firstUserlevel2[2].length;

                    var new2cycle = [...referralAddressesSet];
                    for (let i = 4; i < userlen2; i++) {

                      new2cycle.push(0)
                    }

                    while (new2cycle.length < 9) {
                      new2cycle.push(0);
                    }

                    const sliceElements = new2cycle.slice(3, 12);
                    if (sliceElements.length !== 9) {
                      const elementsToAdd = 9 - sliceElements.length;

                      for (let i = 0; i < elementsToAdd; i++) {
                        sliceElements.push(0);
                      }
                    }
                    setFirstSeconderyLevelStatus(sliceElements);



                    if (referralAddressesSet.length > 0) {

                      // Calculate the number of groups of 12 data points
                      const groupsOfTwelve = Math.ceil(referralAddressesSet.length / 12);

                      setDisplayCount(groupsOfTwelve);
                    }
                    setCycleloader(false)

                  }
                } else if (locateUserLevel >= '2') {
                  let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
                  setFirstLevelStatus(TwoLevelStatus);
                  if (TwoLevelStatus && levelOfData.result.length > 0) {


                    // var levelOfData = await checkNextNewLevelStatus();

                    for (let i = 0; i < levelOfData.result.length; i++) {
                      if (levelOfData.result[i].Buy_Level_Status == 'Level-' + locateUserLevel) {
                        var element = levelOfData.result[i].Owner_MLM_ID;


                        if (element) {

                          secondReferralAddressesSet.push(element);
                        } else {


                          secondReferralAddressesSet.push(element);
                        }
                      }
                    }

                    // const secondReferralAddressesSet = new Set(); // Assuming you have your Set defined

                    // Your array to store items in batches
                    const batchesArray = [];

                    // Convert Set to an array
                    const referralAddressesArray = Array.from(secondReferralAddressesSet);

                    // Push items into the batchesArray in batches of 12
                    for (let i = 0; i < referralAddressesArray.length; i += 12) {
                      batchesArray.push(referralAddressesArray.slice(i, i + 12));

                      let new1cycle = [...batchesArray];
                      dmlmXcoreBuyNextLevelTransactionDetails(new1cycle);
                      // Add additional elements to each array in new1cycle
                      for (let i = 0; i < new1cycle.length; i++) {
                        for (let j = new1cycle[0].length; j < 12; j++) {
                          new1cycle[0].push(0);
                        }
                      }

                      const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                      const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                      let checkTheUserLevel = 3 - secondUserlevelsl.toString();

                      // Add additional arrays to new1cycle if needed
                      // while (new1cycle.length < 3) {
                      //   const emptyArray = Array(12).fill(0); // Create an array of 12 zeros
                      //   new1cycle.push(emptyArray);
                      // }


                      for (let i = 1; i < checkTheUserLevel; i++) {
                        new1cycle.push(0)
                      }

                      while (new1cycle.length < 3) {

                        new1cycle.push(0);
                      }
                      for (let j = 0; j < new1cycle.length; j++) {
                        const element = new1cycle[0];
                        if (Array.isArray(element)) {

                          const firstThreeElements = element.slice(0, 3);
                          setFirstLoopStageOfIdNumber(firstThreeElements);

                        } else {
                          console.error("Expected an array but found:", element);
                          // Handle the unexpected data format appropriately
                        }
                      };

                      // -------------------------------------------------
                      let new2cycle = [...batchesArray];

                      // Add additional elements to each array in new1cycle
                      for (let i = 4; i < new2cycle.length; i++) {
                        for (let j = new2cycle[0].length; j < 12; j++) {
                          new2cycle[0].push(0);
                        }
                      }

                      let userlen2 = 9 - secondUserlevel2[2].length;
                      //    // Add additional arrays to new1cycle if needed
                      //  while (new2cycle.length < 9) {
                      //   const emptyArray = Array(12).fill(0); // Create an array of 12 zeros
                      //   new2cycle.push(emptyArray);
                      // }

                      for (let i = 4; i < userlen2; i++) {
                        new2cycle.push(0)
                      }


                      while (new2cycle.length < 9) {

                        new2cycle.push(0);
                      }
                      for (let j = 0; j < new2cycle.length; j++) {
                        const element = new2cycle[0];
                        if (Array.isArray(element)) {

                          const firstThreeElements = element.slice(3, 12);
                          setFirstSeconderyLevelStatus(firstThreeElements);

                        } else {
                          console.error("Expected an array but found:", element);
                          // Handle the unexpected data format appropriately
                        }
                      }

                    }
                    setPartnerFirstLoopLength(secondReferralAddressesSet.length);

                    if (secondReferralAddressesSet.length > 0) {

                      // Calculate the number of groups of 12 data points
                      const groupsOfTwelve = Math.ceil(secondReferralAddressesSet.length / 12);
                      setDisplayCount(groupsOfTwelve);
                      setCycleloader(false)
                    }

                  } else if (TwoLevelStatus && levelOfData.result.length == 0) {

                    // var levelOfData = await checkNextNewLevelStatus();

                    for (let i = 0; i < levelOfData.result.length; i++) {

                      var elements = levelOfData.result[i].Owner_MLM_ID;
                      if (elements) {

                        secondReferralAddressesSet.push(elements);
                      } else {


                        secondReferralAddressesSet.push(elements);
                      }


                    }

                    if (checkLevelMatching == 'Level-' + locateUserLevel) {
                      setPartnerFirstLoopLength(secondReferralAddressesSet.length);
                    }
                    const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                    const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                    let checkTheUserLevel = 3 - secondUserlevelsl.toString();
                    setGetFristCycles((secondUserlevelsl.toString()) + 1);

                    let new1cycle = [];

                    for (let i = 1; i < checkTheUserLevel; i++) {
                      new1cycle.push(0)
                    }

                    while (new1cycle.length < 3) {
                      new1cycle.push(0);
                    }

                    const firstThreeElements = new1cycle.slice(0, 3);
                    setFirstLoopStageOfIdNumber(firstThreeElements);

                    let userlen2 = 9 - secondUserlevel2[2].length;

                    var new2cycle = [];
                    for (let i = 4; i < userlen2; i++) {

                      new2cycle.push(0)
                    }

                    while (new2cycle.length < 9) {
                      new2cycle.push(0);
                    }

                    const sliceElements = new2cycle.slice(3, 12);
                    if (sliceElements.length !== 9) {
                      const elementsToAdd = 9 - sliceElements.length;

                      for (let i = 0; i < elementsToAdd; i++) {
                        sliceElements.push(0);
                      }
                    }

                    setFirstSeconderyLevelStatus(sliceElements);
                    setCycleloader(false)
                  }
                }


              }
            }
          }

        } else if (!FirstLevelStatus) {

          if (registerDataItem.New_User_Referral_Id == locateUserId) {

            let idToAddressChecker = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
            // const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(idToAddressChecker).call();
            const referralAddress = idToAddressChecker;

            const levelCompleted = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();
            if (levelCompleted) {

              if (locateUserLevel == '1') {

                if (locateUserId) {

                  let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();
                  setFirstLevelStatus(FirstLevelStatus);
                  if (FirstLevelStatus) {

                    const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
                    const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, locateUserLevel, 0).call();
                    let checkTheUserLevel = 3 - firstUserlevelsl.toString();


                    let new1cycle = [];

                    for (let i = 1; i < checkTheUserLevel; i++) {
                      new1cycle.push(0)
                    }

                    while (new1cycle.length < 3) {
                      new1cycle.push(0);
                    }

                    const firstThreeElements = new1cycle.slice(0, 3);
                    setFirstLoopStageOfIdNumber(firstThreeElements);

                    let userlen2 = 9 - firstUserlevel2[2].length;

                    var new2cycle = [];
                    for (let i = 4; i < userlen2; i++) {

                      new2cycle.push(0)
                    }

                    while (new2cycle.length < 9) {
                      new2cycle.push(0);
                    }

                    const sliceElements = new2cycle.slice(3, 12);
                    if (sliceElements.length !== 9) {
                      const elementsToAdd = 9 - sliceElements.length;

                      for (let i = 0; i < elementsToAdd; i++) {
                        sliceElements.push(0);
                      }
                    }

                    setFirstSeconderyLevelStatus(sliceElements);

                  }
                }
              } else if (locateUserLevel >= '2') {
                if (locateUserId) {


                  let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();

                  setFirstLevelStatus(TwoLevelStatus);
                  if (TwoLevelStatus) {

                    const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
                    const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, locateUserLevel, 0).call();
                    let checkTheUserLevel = 3 - secondUserlevelsl.toString();


                    let new1cycle = [];

                    for (let i = 1; i < checkTheUserLevel; i++) {
                      new1cycle.push(0)
                    }

                    while (new1cycle.length < 3) {
                      new1cycle.push(0);
                    }

                    const firstThreeElements = new1cycle.slice(0, 3);
                    setFirstLoopStageOfIdNumber(firstThreeElements);

                    let userlen2 = 9 - secondUserlevel2[2].length;

                    var new2cycle = [];
                    for (let i = 4; i < userlen2; i++) {

                      new2cycle.push(0)
                    }

                    while (new2cycle.length < 9) {
                      new2cycle.push(0);
                    }

                    const sliceElements = new2cycle.slice(3, 12);
                    if (sliceElements.length !== 9) {
                      const elementsToAdd = 9 - sliceElements.length;

                      for (let i = 0; i < elementsToAdd; i++) {
                        sliceElements.push(0);
                      }
                    }

                    setFirstSeconderyLevelStatus(sliceElements);

                  }
                }
              }
            } else {
              if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.LevelNotBuy)
              // toast.warning(ToastApi.LevelNotBuy)
            }
          }
        }

      }

    } catch (error) {
      console.error('Error fetching register data:', error);
    }


  };




  const checkNextNewLevelStatus = async () => {
    const nextLeveData = await getParentID(locateUserId);
    return nextLeveData;

  }




  const sliceAddress = (address, startLength = 6, endLength = 6) => {
    try {
      if (!address) return '';

      const prefix = address.slice(0, startLength);
      const suffix = address.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };

  const handleSeeMoreClick = () => {
    // Increase the number of visible items (you can adjust the step as needed)
    setVisibleItems((prevVisibleItems) => prevVisibleItems + config.REGISTER_HISTORY_SEE_MORE_LESS);
  };

  const handleSeeLessClick = () => {
    // Decrease the number of visible items (you can adjust the step as needed)
    setVisibleItems((prevVisibleItems) => Math.max(prevVisibleItems - config.REGISTER_HISTORY_SEE_MORE_LESS, config.REGISTER_HISTORY_SEE_MORE_LESS));
  };


  const changeCyclesDiagramNext = async () => {
    try {

        const addValue = theCycleCount + 1;
        setCycleloader(true)


        if (addValue) {
          setTheCycleCount(addValue);


          const resultOfRegisterData = await getAllUserRegisterList(locateUserId);

          const arraydata = Object.values(resultOfRegisterData);


          for (const registerDataItem of arraydata) {
            const registerDataItemsdetail = registerDataItem.data;

            if (registerDataItemsdetail.Referral_ID == locateUserId) {

              var levelOfData = await checkNextNewLevelStatus();


              for (let i = 0; i < levelOfData.result.length; i++) {

                var elements = levelOfData.result[i].Buy_Level_Status;
                setCheckLevelMatching(elements)
              }

              const referralAddressesSet = [];
              const secondReferralAddressesSet = [];

              const checkUserRegisteredIdExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();

              if (locateUserLevel == '1') {

                let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
                setFirstLevelStatus(FirstLevelStatus);
                if (FirstLevelStatus) {


                  const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                  const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                  let checkTheUserLevel = 3 - firstUserlevelsl.toString();

                  setGetFristCycles((firstUserlevelsl.toString()) + 1);
                  const findTheGroupOfReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredIdExists).call();
                  for (let i = 0; i < findTheGroupOfReferredID.length; i++) {
                    const element = findTheGroupOfReferredID[i];
                    const checkUserRegisteredExists = await DemoCorePlanContract.methods.users(element).call();

                    referralAddressesSet.push(checkUserRegisteredExists.id.toString());
                  };

                  dmlmXcoreTransactionDetailsWithRegisteration(referralAddressesSet);

                  setPartnerFirstLoopLength(referralAddressesSet.length)

                  const batchesArray = [];

                  // Convert Set to an array
                  const referralAddressesArray = Array.from(referralAddressesSet);

                  // Push items into the batchesArray in batches of 12
                  for (let i = 0; i < referralAddressesArray.length; i += 12) {
                    batchesArray.push(referralAddressesArray.slice(i, i + 12));

                    let new1cycle = [...batchesArray];

                    // Add additional elements to each array in new1cycle
                    for (let i = 0; i < new1cycle.length; i++) {


                      for (let j = new1cycle[addValue].length; j < 12; j++) {
                        new1cycle[addValue].push(0);
                      }
                    }

                    for (let i = 1; i < checkTheUserLevel; i++) {
                      new1cycle.push(0)
                    }

                    while (new1cycle.length < 3) {

                      new1cycle.push(0);
                    }
                    for (let j = 0; j < new1cycle.length; j++) {
                      const element = new1cycle[addValue];
                      if (Array.isArray(element)) {
                        const firstThreeElements = element.slice(0, 3);
                        setFirstLoopStageOfIdNumber(firstThreeElements);


                      } else {
                        console.error("Expected an array but found:", element);
                        // Handle the unexpected data format appropriately
                      }
                    };


                    let new2cycle = [...batchesArray];

                    // Add additional elements to each array in new1cycle
                    for (let i = 4; i < new2cycle.length; i++) {
                      for (let j = new2cycle[addValue].length; j < 12; j++) {
                        new2cycle[addValue].push(0);
                      }
                    }

                    let userlen2 = 9 - firstUserlevel2[2].length;


                    for (let i = 4; i < userlen2; i++) {
                      new2cycle.push(0);
                    }

                    while (new2cycle.length < 9) {

                      new2cycle.push(0);
                    }
                    for (let j = 0; j < new2cycle.length; j++) {
                      const element = new2cycle[addValue];
                      if (Array.isArray(element)) {

                        const firstThreeElements = element.slice(3, 12);
                        setFirstSeconderyLevelStatus(firstThreeElements);

                      } else {
                        console.error("Expected an array but found:", element);
                        // Handle the unexpected data format appropriately
                      }
                    }
                  };


                }
              } else if (locateUserLevel >= '2') {
                let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
                setFirstLevelStatus(TwoLevelStatus);

                if (TwoLevelStatus) {
                  var levelOfData = await checkNextNewLevelStatus();

                  for (let i = 0; i < levelOfData.result.length; i++) {
                    if (levelOfData.result[i].Buy_Level_Status == 'Level-' + locateUserLevel) {
                      var element = levelOfData.result[i].Owner_MLM_ID;


                      if (element) {

                        secondReferralAddressesSet.push(element);
                      } else {


                        secondReferralAddressesSet.push(element);
                      }
                    }
                  }
                  dmlmXcoreBuyNextLevelTransactionDetails(secondReferralAddressesSet);


                  const batchesArray = [];

                  // Convert Set to an array
                  const referralAddressesArray = Array.from(secondReferralAddressesSet);

                  // Push items into the batchesArray in batches of 12
                  for (let i = 0; i < referralAddressesArray.length; i += 12) {
                    batchesArray.push(referralAddressesArray.slice(i, i + 12));

                    let new1cycle = [...batchesArray];

                    // Add additional elements to each array in new1cycle
                    for (let i = 0; i < new1cycle.length; i++) {


                      for (let j = new1cycle[i].length; j < 12; j++) {

                        new1cycle[addValue].push(0);
                      }
                    }

                    const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                    const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                    let checkTheUserLevel = 3 - secondUserlevelsl.toString();


                    for (let i = 1; i < checkTheUserLevel; i++) {
                      new1cycle.push(0)
                    }

                    while (new1cycle.length < 3) {

                      new1cycle.push(0);
                    }
                    for (let j = 0; j < new1cycle.length; j++) {
                      const element = new1cycle[j];
                      if (Array.isArray(element)) {
                        const firstThreeElements = element.slice(0, 3);
                        setFirstLoopStageOfIdNumber(firstThreeElements);


                      } else {
                        console.error("Expected an array but found:", element);
                        // Handle the unexpected data format appropriately
                      }
                    }

                    // -------------------------------------------------
                    let new2cycle = [...batchesArray];

                    // Add additional elements to each array in new1cycle
                    for (let i = 4; i < new2cycle.length; i++) {
                      for (let j = new2cycle[i].length; j < 12; j++) {
                        new2cycle[addValue].push(0);
                      }
                    }

                    let userlen2 = 9 - secondUserlevel2[2].length;


                    for (let i = 4; i < userlen2; i++) {
                      new2cycle.push(0);
                    }

                    while (new2cycle.length < 9) {

                      new2cycle.push(0);
                    }
                    for (let j = 0; j < new2cycle.length; j++) {
                      const element = new2cycle[j];
                      if (Array.isArray(element)) {

                        const firstThreeElements = element.slice(3, 12);
                        setFirstSeconderyLevelStatus(firstThreeElements);


                      } else {
                        console.error("Expected an array but found:", element);
                        // Handle the unexpected data format appropriately
                      }
                    }

                  }
                  setPartnerFirstLoopLength(secondReferralAddressesSet.length);

                } else if (TwoLevelStatus) {

                  var levelOfData = await checkNextNewLevelStatus();

                  for (let i = 0; i < levelOfData.result.length; i++) {

                    var elements = levelOfData.result[i].Owner_MLM_ID;
                    if (elements) {

                      secondReferralAddressesSet.push(elements);
                    } else {


                      secondReferralAddressesSet.push(elements);
                    }


                  }

                  if (checkLevelMatching == 'Level-' + locateUserLevel) {
                    setPartnerFirstLoopLength(secondReferralAddressesSet.length);
                  }
                  const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                  const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                  let checkTheUserLevel = 3 - secondUserlevelsl.toString();
                  setGetFristCycles((secondUserlevelsl.toString()) + 1);

                  let new1cycle = [];

                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)
                  }

                  while (new1cycle.length < 3) {
                    new1cycle.push(0);
                  }

                  const firstThreeElements = new1cycle.slice(0, 3);
                  setFirstLoopStageOfIdNumber(firstThreeElements);

                  let userlen2 = 9 - secondUserlevel2[2].length;

                  var new2cycle = [];
                  for (let i = 4; i < userlen2; i++) {

                    new2cycle.push(0)
                  }

                  while (new2cycle.length < 9) {
                    new2cycle.push(0);
                  }

                  const sliceElements = new2cycle.slice(3, 12);
                  if (sliceElements.length !== 9) {
                    const elementsToAdd = 9 - sliceElements.length;

                    for (let i = 0; i < elementsToAdd; i++) {
                      sliceElements.push(0);
                    }
                  }

                  setFirstSeconderyLevelStatus(sliceElements);

                }
              }


            } else if (registerDataItemsdetail.New_User_Referral_Id == locateUserId) {
              const referralAddress = registerDataItemsdetail.New_User_Registered_Address.trim(); // Trim the address
              if (locateUserLevel == '1') {
                const FistCycleID = [];
                const SecondCycleID = [];

                let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();
                setFirstLevelStatus(FirstLevelStatus);

                if (FirstLevelStatus) {

                  const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                  const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
                  const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, locateUserLevel, theCycleCount).call()

                  for (let i = 0; i < firstUserlevel2[1].length; i++) {
                    const element = firstUserlevel2[1][i];
                    FistCycleID.push(element[1])
                  }

                  for (let i = 0; i < firstUserlevel2[2].length; i++) {
                    const element = firstUserlevel2[2][i];
                    SecondCycleID.push(element[1])
                  }

                  let checkTheUserLevel = 3 - firstUserlevelsl.toString();


                  let new1cycle = [...FistCycleID];
                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)
                  }

                  while (new1cycle.length < 3) {
                    new1cycle.push(0);
                  }


                  const firstThreeElements = new1cycle.slice(0, 3);
                  setFirstLoopStageOfIdNumber(firstThreeElements);

                  let userlen2 = 9 - firstUserlevel2[2].length;

                  var new2cycle = [...SecondCycleID];
                  for (let i = 4; i < userlen2; i++) {

                    new2cycle.push(0)
                  }

                  while (new2cycle.length < 9) {
                    new2cycle.push(0);
                  }

                  const sliceElements = new2cycle.slice(0, 10);
                  if (sliceElements.length !== 9) {
                    const elementsToAdd = 9 - sliceElements.length;

                    for (let i = 0; i < elementsToAdd; i++) {
                      sliceElements.push(0);
                    }
                  }

                  setFirstSeconderyLevelStatus(sliceElements);
                  setCycleloader(false)
                }
              } else if (locateUserLevel > '1') {
                const FistCycleID = [];
                const SecondCycleID = [];

                let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();
                setFirstLevelStatus(TwoLevelStatus);
                if (TwoLevelStatus) {
                  const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                  const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
                  const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, locateUserLevel, theCycleCount).call();
                  let checkTheUserLevel = 3 - secondUserlevelsl.toString();

                  for (let i = 0; i < secondUserlevel2[1].length; i++) {
                    const element = secondUserlevel2[1][i];
                    FistCycleID.push(element[1])
                  }

                  for (let i = 0; i < secondUserlevel2[2].length; i++) {
                    const element = secondUserlevel2[2][i];
                    SecondCycleID.push(element[1])
                  }

                  let new1cycle = [...FistCycleID];

                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)
                  }

                  while (new1cycle.length < 3) {
                    new1cycle.push(0);
                  }

                  const firstThreeElements = new1cycle.slice(0, 3);
                  setFirstLoopStageOfIdNumber(firstThreeElements);

                  let userlen2 = 9 - secondUserlevel2[2].length;

                  var new2cycle = [...SecondCycleID];
                  for (let i = 4; i < userlen2; i++) {

                    new2cycle.push(0)
                  }

                  while (new2cycle.length < 9) {
                    new2cycle.push(0);
                  }

                  const sliceElements = new2cycle.slice(0, 10);
                  if (sliceElements.length !== 9) {
                    const elementsToAdd = 9 - sliceElements.length;

                    for (let i = 0; i < elementsToAdd; i++) {
                      sliceElements.push(0);
                    }
                  }


                  setFirstSeconderyLevelStatus(sliceElements);
                  setCycleloader(false)

                }
              }

            }

          }

        }


    } catch (error) {
      console.log("cardnexterror", error);
    }

  }

  const changeCyclesDiagramPrevious = async () => {
    try {

      const minuesValue = theCycleCount - 1;

      setCycleloader(true)


      if (theCycleCount >= 1) {

        setTheCycleCount(minuesValue);

        const resultOfRegisterData = await getAllUserRegisterList(locateUserId);
        const arraydata = Object.values(resultOfRegisterData);

        for (const registerDataItem of arraydata) {
          const registerDataItemsdetail = registerDataItem.data;

          if (registerDataItemsdetail.Referral_ID == locateUserId) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Buy_Level_Status;
              setCheckLevelMatching(elements)
            }

            const referralAddressesSet = [];
            const secondReferralAddressesSet = [];

            const checkUserRegisteredIdExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
            if (locateUserLevel == '1') {


              let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
              setFirstLevelStatus(FirstLevelStatus);
              if (FirstLevelStatus) {


                const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                let checkTheUserLevel = 3 - firstUserlevelsl.toString();

                setGetFristCycles((firstUserlevelsl.toString()) + 1);
                const findTheGroupOfReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredIdExists).call();
                for (let i = 0; i < findTheGroupOfReferredID.length; i++) {
                  const element = findTheGroupOfReferredID[i];
                  const checkUserRegisteredExists = await DemoCorePlanContract.methods.users(element).call();

                  referralAddressesSet.push(checkUserRegisteredExists.id.toString());
                };

                // dmlmXcoreTransactionDetailsWithRegisteration(referralAddressesSet);

                setPartnerFirstLoopLength(referralAddressesSet.length);

                const batchesArray = [];

                // Convert Set to an array
                const referralAddressesArray = Array.from(referralAddressesSet);

                // Push items into the batchesArray in batches of 12
                for (let i = 0; i < referralAddressesArray.length; i += 12) {
                  batchesArray.push(referralAddressesArray.slice(i, i + 12));

                  let new1cycle = [...batchesArray];

                  // Add additional elements to each array in new1cycle
                  for (let i = 0; i < new1cycle.length; i++) {


                    for (let j = new1cycle[minuesValue].length; j < 12; j++) {
                      new1cycle[minuesValue].push(0);
                    }
                  }

                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)
                  }

                  while (new1cycle.length < 3) {

                    new1cycle.push(0);
                  }
                  for (let j = 0; j < new1cycle.length; j++) {
                    const element = new1cycle[minuesValue];
                    if (Array.isArray(element)) {
                      const firstThreeElements = element.slice(0, 3);
                      setFirstLoopStageOfIdNumber(firstThreeElements);


                    } else {
                      console.error("Expected an array but found:", element);
                      // Handle the unexpected data format appropriately
                    }
                  };


                  let new2cycle = [...batchesArray];

                  // Add additional elements to each array in new1cycle
                  for (let i = 4; i < new2cycle.length; i++) {
                    for (let j = new2cycle[minuesValue].length; j < 12; j++) {
                      new2cycle[minuesValue].push(0);
                    }
                  }

                  let userlen2 = 9 - firstUserlevel2[2].length;


                  for (let i = 4; i < userlen2; i++) {
                    new2cycle.push(0);
                  }

                  while (new2cycle.length < 9) {

                    new2cycle.push(0);
                  }
                  for (let j = 0; j < new2cycle.length; j++) {
                    const element = new2cycle[minuesValue];
                    if (Array.isArray(element)) {

                      const firstThreeElements = element.slice(3, 12);
                      setFirstSeconderyLevelStatus(firstThreeElements);


                    } else {
                      console.error("Expected an array but found:", element);
                      // Handle the unexpected data format appropriately
                    }
                  }
                };

              }
            } else if (locateUserLevel >= '2') {
              let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, locateUserLevel).call();
              setFirstLevelStatus(TwoLevelStatus);

              if (TwoLevelStatus) {
                var levelOfData = await checkNextNewLevelStatus();

                for (let i = 0; i < levelOfData.result.length; i++) {
                  if (levelOfData.result[i].Buy_Level_Status == 'Level-' + locateUserLevel) {
                    var element = levelOfData.result[i].Owner_MLM_ID;


                    if (element) {

                      secondReferralAddressesSet.push(element);
                    } else {


                      secondReferralAddressesSet.push(element);
                    }
                  }
                }

                // dmlmXcoreBuyNextLevelTransactionDetails(secondReferralAddressesSet);

                const batchesArray = [];

                // Convert Set to an array
                const referralAddressesArray = Array.from(secondReferralAddressesSet);

                // Push items into the batchesArray in batches of 12
                for (let i = 0; i < referralAddressesArray.length; i += 12) {
                  batchesArray.push(referralAddressesArray.slice(i, i + 12));

                  let new1cycle = [...batchesArray];

                  // Add additional elements to each array in new1cycle
                  for (let i = 0; i < new1cycle.length; i++) {


                    for (let j = new1cycle[minuesValue].length; j < 12; j++) {
                      new1cycle[minuesValue].push(0);
                    }
                  }

                  const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                  const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                  let checkTheUserLevel = 3 - secondUserlevelsl.toString();


                  for (let i = 1; i < checkTheUserLevel; i++) {
                    new1cycle.push(0)
                  }

                  while (new1cycle.length < 3) {

                    new1cycle.push(0);
                  }
                  for (let j = 0; j < new1cycle.length; j++) {
                    const element = new1cycle[minuesValue];
                    if (Array.isArray(element)) {
                      const firstThreeElements = element.slice(0, 3);
                      setFirstLoopStageOfIdNumber(firstThreeElements);
                  

                    } else {
                      console.error("Expected an array but found:", element);
                      // Handle the unexpected data format appropriately
                    }
                  }

                  // -------------------------------------------------
                  let new2cycle = [...batchesArray];

                  // Add additional elements to each array in new1cycle
                  for (let i = 4; i < new2cycle.length; i++) {
                    for (let j = new2cycle[minuesValue].length; j < 12; j++) {
                      new2cycle[minuesValue].push(0);
                    }
                  }

                  let userlen2 = 9 - secondUserlevel2[2].length;


                  for (let i = 4; i < userlen2; i++) {
                    new2cycle.push(0);
                  }

                  while (new2cycle.length < 9) {

                    new2cycle.push(0);
                  }
                  for (let j = 0; j < new2cycle.length; j++) {
                    const element = new2cycle[minuesValue];
                    if (Array.isArray(element)) {

                      const firstThreeElements = element.slice(3, 12);
                      setFirstSeconderyLevelStatus(firstThreeElements);
                    

                    } else {
                      console.error("Expected an array but found:", element);
                      // Handle the unexpected data format appropriately
                    }
                  }

                }
                setPartnerFirstLoopLength(secondReferralAddressesSet.length);

              } else if (TwoLevelStatus) {

                var levelOfData = await checkNextNewLevelStatus();

                for (let i = 0; i < levelOfData.result.length; i++) {

                  var elements = levelOfData.result[i].Owner_MLM_ID;
                  if (elements) {

                    secondReferralAddressesSet.push(elements);
                  } else {


                    secondReferralAddressesSet.push(elements);
                  }


                }

                if (checkLevelMatching == 'Level-' + locateUserLevel) {
                  setPartnerFirstLoopLength(secondReferralAddressesSet.length);
                }
                const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredIdExists, locateUserLevel).call();
                const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredIdExists, locateUserLevel, 0).call();
                let checkTheUserLevel = 3 - secondUserlevelsl.toString();
                setGetFristCycles((secondUserlevelsl.toString()) + 1);

                let new1cycle = [];

                for (let i = 1; i < checkTheUserLevel; i++) {
                  new1cycle.push(0)
                }

                while (new1cycle.length < 3) {
                  new1cycle.push(0);
                }

                const firstThreeElements = new1cycle.slice(0, 3);
                setFirstLoopStageOfIdNumber(firstThreeElements);

                let userlen2 = 9 - secondUserlevel2[2].length;

                var new2cycle = [];
                for (let i = 4; i < userlen2; i++) {

                  new2cycle.push(0)
                }

                while (new2cycle.length < 9) {
                  new2cycle.push(0);
                }

                const sliceElements = new2cycle.slice(3, 12);
                if (sliceElements.length !== 9) {
                  const elementsToAdd = 9 - sliceElements.length;

                  for (let i = 0; i < elementsToAdd; i++) {
                    sliceElements.push(0);
                  }
                }

                setFirstSeconderyLevelStatus(sliceElements);

              }
            }


          } else if (registerDataItemsdetail.New_User_Referral_Id == locateUserId) {
            const referralAddress = registerDataItemsdetail.New_User_Registered_Address.trim(); // Trim the address

            const FistCycleID = [];
            const SecondCycleID = [];


            if (locateUserLevel == '1') {


              let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();
              setFirstLevelStatus(FirstLevelStatus);
              if (FirstLevelStatus) {
                const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
                const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, locateUserLevel, theCycleCount - 2).call();

                let checkTheUserLevel = 3 - firstUserlevelsl.toString();

                for (let i = 0; i < firstUserlevel2[1].length; i++) {
                  const element = firstUserlevel2[1][i];                
                  FistCycleID.push(element[1])
                }

                for (let i = 0; i < firstUserlevel2[2].length; i++) {
                  const element = firstUserlevel2[2][i];
                  SecondCycleID.push(element[1])
                }

                let new1cycle = [...FistCycleID];
                for (let i = 1; i < checkTheUserLevel; i++) {
                  new1cycle.push(0)
                }

                while (new1cycle.length < 3) {
                  new1cycle.push(0);
                }


                const firstThreeElements = new1cycle.slice(0, 3);
                setFirstLoopStageOfIdNumber(firstThreeElements);


                let userlen2 = 9 - firstUserlevel2[2].length;

                var new2cycle = [...SecondCycleID];
                for (let i = 4; i < userlen2; i++) {

                  new2cycle.push(0)
                }

                while (new2cycle.length < 9) {
                  new2cycle.push(0);
                }

                const sliceElements = new2cycle.slice(0, 10);
                if (sliceElements.length !== 9) {
                  const elementsToAdd = 9 - sliceElements.length;

                  for (let i = 0; i < elementsToAdd; i++) {
                    sliceElements.push(0);
                  }
                }

                setFirstSeconderyLevelStatus(sliceElements);
                setCycleloader(false)

              }
            } else if (locateUserLevel > '1') {

              const FistCycleID2 = [];
              const SecondCycleID2 = [];

              let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, locateUserLevel).call();
              setFirstLevelStatus(TwoLevelStatus);
              if (TwoLevelStatus) {
                const findTheGroupOfReferredID = await DemoCorePlanContract.methods.users(referralAddress).call();
                const secondUserlevelsl = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
                const secondUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(referralAddress, locateUserLevel, theCycleCount - 2).call();
                let checkTheUserLevel = 3 - secondUserlevelsl.toString();
 

                for (let i = 0; i < secondUserlevel2[1].length; i++) {
                  const element = secondUserlevel2[1][i];
                  FistCycleID2.push(element[1])
                }

                for (let i = 0; i < secondUserlevel2[2].length; i++) {
                  const element = secondUserlevel2[2][i];
                  SecondCycleID2.push(element[1])
                }

                let new1cycle = [...FistCycleID2];
                for (let i = 1; i < checkTheUserLevel; i++) {
                  new1cycle.push(0)
                }

                while (new1cycle.length < 3) {
                  new1cycle.push(0);
                }

                const firstThreeElements = new1cycle.slice(0, 3);
                setFirstLoopStageOfIdNumber(firstThreeElements);

                let userlen2 = 9 - secondUserlevel2[2].length;

                var new2cycle = [...SecondCycleID2];

                for (let i = 4; i < userlen2; i++) {

                  new2cycle.push(0)
                }

                while (new2cycle.length < 9) {
                  new2cycle.push(0);
                }

                const sliceElements = new2cycle.slice(0, 10);
                if (sliceElements.length !== 9) {
                  const elementsToAdd = 9 - sliceElements.length;

                  for (let i = 0; i < elementsToAdd; i++) {
                    sliceElements.push(0);
                  }
                }

                setFirstSeconderyLevelStatus(sliceElements);
                setCycleloader(false)

              }
            }

          }

        }
      }
    } catch (error) {
      console.log("cardpreviouserror", error);
    }

  }

  useEffect(() => {
    // changeCyclesDiagramPrevious();
    // changeCyclesDiagramNext()
  }, []);


  const levelNumber = window.location.search.split('=')[2];

  const dmlmXcoreTransactionDetailsWithRegisteration = async (referralAddressesSet) => {

    const showTheTransactionDetails = await getTheRegistrationDmlmXcoreCompletedTransactionsDetsils(referralAddressesSet);
    const assignValue = [];

    showTheTransactionDetails.result.forEach(element => {
      if ('Level-' + levelNumber == 'Level-1') {
        const objectValue = {};
        var createdDate = new Date(element.Create_At);

        // Format date
        var formattedDate = createdDate.getDate() + " " +
          createdDate.toLocaleString('default', { month: 'short' }) + " " +
          createdDate.getFullYear();

        objectValue['userIds'] = element.New_User_Referral_Id;
        objectValue['tx_Hash'] = element.tx_Hash;
        objectValue['create_At'] = formattedDate; // Include the formatted date
        objectValue['userLevel'] = "Level-1";
        objectValue['amount'] = element.X_Core_Amount;
        objectValue['walletAddress'] = element.New_User_Registered_Address;

        assignValue.push(objectValue);
        let totalAmount = 0;
        assignValue.forEach(item => {
          totalAmount += parseFloat(item.amount);
        });
        setTotalLevelRevenue(totalAmount);

      }
      setTransactionDetailsInTablelevel1(assignValue)
    });
    // console.log("assignValue", assignValue);
    // Sort assignValue array based on create_At property in descending order
    // assignValue.sort((a, b) => new Date(a.create_At) - new Date(b.create_At));

    // Update the state with assignValue
    // setTransactionDetailsInTable(prevState => [...assignValue, ...prevState]);

  };



  const dmlmXcoreBuyNextLevelTransactionDetails = async (ids) => {
    if (ids.length > 0) {
      let idsArray = [].concat(...ids);

      let uniqueIdsArray = [...new Set(idsArray)]; // Convert to Set to remove duplicates, then back to array
      const showTheTransactionDetails = await getTheBuyNextLevelDmlmXcoreCompletedTransactionsDetsils(uniqueIdsArray);

      const assignValue = [];


      showTheTransactionDetails.result.forEach(element => {
        if ('Level-' + levelNumber == element.Buy_Level_Status) {
          const objectValue = {};
          let purchasedAmount = web3js.utils.fromWei(element.Level_Purchased_Amount, "ether");

          var createdDate = new Date(element.Create_at);


          var formattedDate = createdDate.getDate() + " " +
            createdDate.toLocaleString('default', { month: 'short' }) + " " +
            createdDate.getFullYear();

          objectValue['userIds'] = element.Owner_MLM_ID;
          objectValue['tx_Hash'] = element.Next_Level_Txhah;
          objectValue['create_At'] = formattedDate;
          objectValue['userLevel'] = element.Buy_Level_Status;
          objectValue['walletAddress'] = element.Owner_From_Address;
          objectValue['amount'] = element.X_Core_Amount;

          assignValue.push(objectValue);
          let totalAmount = 0;
          assignValue.forEach(item => {
            totalAmount += parseFloat(item.amount);
          });

          setTotalLevelRevenue(totalAmount);
        }


        setTransactionDetailsInTable(assignValue);

      });


      // assignValue.sort((a, b) => new Date(a.create_At) - new Date(b.create_At));

      // setTransactionDetailsInTable(prevState => [...prevState, ...assignValue]); 
      // setTransactionDetailsInTable(assignValue)


    }

  };

  const locateUserPathname = window.location.pathname.split('/')[1];
  const findLocalLevel = window.location.search.split('=')[2];
  const matchingDmlmXcoreInner = config.WINDOW_LOCATION_DMLM_XCORE_INNER.split('/')[1];

  const invitedIdRedirect = async (invitedId) => {
    try {
      if (locateUserPathname === matchingDmlmXcoreInner.split('?')[0]) {
        if (findLocalLevel) {
          window.location.href = config.WINDOW_LOCATION_DMLM_XCORE_INNER + invitedId + config.WINDOW_LOCATION_LEVEL + findLocalLevel;
        }
      }
    } catch (error) {

    }
  };



  const countvall = async () => {
    try {
      if (count <= 10 && count >= 1) {
        const levelCalculate = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, count).call();

        if (levelCalculate) {
          const newUrl = `?user=${new URLSearchParams(window.location.search).get('user')}&level=${count}`;
          setTimeout(() => {
            navigate(newUrl);
          }, 1000);
        }
        else {
          if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.LevelNotBuy)
        }
      }
    } catch (error) {

    }
  }



  const onChangeNextLevel = async () => {
    try {
      setTheCycleCount(1);
      const nextLevelCardId = new URLSearchParams(window.location.search).get('user');
      const nextLevelNumber = parseInt(new URLSearchParams(window.location.search).get('level'), 10) || 0;



      if (count < 10) {
        const previousCount = count + 1


        setCount(Number(locateUserLevel) + 1);

        const countval = previousCount + 1



        const levelCalculate = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, countval).call();



      }
    } catch (error) {

    }
  };

  const onChangePreviousLevel = async () => {
    try {
      setTheCycleCount(1)
      const nextLevelCard = window.location.search.split('=')[1].split('&')[0];
      const levelCalculate = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, 1).call();

      if (levelCalculate && count > 1) {
        setCount(Number(locateUserLevel) - 1)
        // const newUrl = window.location.pathname + '?user=' + nextLevelCard + '&level=' + count;
        // console.log('resultOfRegisterData---', newUrl, nextLevelCard);
        // window.location.href = newUrl;
        // navigate(newUrl); // Uncomment this line if you want to navigate
      } else {
        if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.LevelNotBuy)
        // toast.warning(ToastApi.LevelNotBuy)
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    async function checkLevelcount() {
      for (let index = 0; index <= 10; index++) {
        try {
          const referralAdd = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
          const checkval = await DemoCorePlanContract.methods.usercompletelevel(referralAdd, index).call();

          if (checkval === true) {
            setLastTrueIndex(index); // Update lastTrueIndex if checkval is true
          }
        } catch (error) {

        }
      }
    }

    checkLevelcount();
  }, []);


  function showNonZeroElements(arr) {
    let nonZeroElements = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        nonZeroElements.push(arr[i]);
      } else {
        break; // Stop when we encounter a zero
      }
    }
    return nonZeroElements;
  }


  useEffect(() => {
    checkNextNewLevelStatus();
    countvall()
    checkTheLevelOfUsers();

    const levelCycleOfMLM = async () => {
      try {
        const CycleLength = await DemoCorePlanContract.methods.getcycles(referralAddress, locateUserLevel).call();
        setCycleCount(CycleLength);
      } catch (error) {

      }

    };

    levelCycleOfMLM();
    // onChangePreviousLevel();
    // onChangeNextLevel();
  }, [referredByUserId, referralWalletAddress, connectedWallet, checkLevelMatching, locateUserId, count, locateUserLevel]);


  useEffect(() => {
    getListOfRegisterData();

  }, [referredByUserId, referralWalletAddress, connectedWallet, checkLevelMatching, locateUserId, locateUserLevel])

  async function ArrayCycleElement() {
    try {
      const thearraycyclecount = firstLoopStageOfIdNumber.concat(firstSeconderyLevelStatus)
      const result = showNonZeroElements(thearraycyclecount);
      if (partnerFirstLoopLength >= 13) {
        setCycleCountnumber(result.length)
      } else {
        setCycleCountnumber(0)
      }
      // setCycleCountnumber(result.length)
      // console.log("theCycleCount", result.length,"partnerFirstLoopLength",partnerFirstLoopLength,"thearraycyclecount",thearraycyclecount);
    } catch (error) {
      console.log("cyc", error);
    }

  }


  useEffect(() => {
    ArrayCycleElement();

  }, [firstLoopStageOfIdNumber, firstSeconderyLevelStatus, locateUserLevel,partnerFirstLoopLength])

  return (
    <div className="App">
      <Topnav />
      <section className="dashboard-split-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
              <Sidenav />
            </div>
            <div className="col-lg-10 dashboard-split-right-section">
              <div className="row p-2 g-4">
                <div className="col-lg-12">
                  <p className="my-3 dashboard-text-6">
                    <span className="mb-0 dashboard-text-4">
                      <a className="" href={`${config.WINDOW_LOCATION_DASHBOARD_PAGE}/${locateUserId}`}> <span style={{ color: "#daa520e9", textDecoration: "underline" }}>ID {locateUserId}</span></a>  / <a className="" href={`${config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE}${locateUserId}`}><span className="" style={{ color: "#daa520e9", textDecoration: "underline", marginRight: "5px" }}> PropelX xCore </span>  </a>({lastTrueIndex} out of 10 levels)
                    </span>{" "}
                    / level {locateUserLevel}
                  </p>
                  <div className="d-flex flex-lg-row justify-content-center">
                    <div>
                      <p className="dashboard-text-2 mb-0">Level {locateUserLevel} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-2 g-4">
                <div className="col-lg-2 col-6 order-lg-1 order-1">
                  <a onClick={() => { onChangePreviousLevel() }} disabled={Number(locateUserLevel) === 1}>
                    <button className="card dashboard-gold-inner-card-1 h-100" disabled={Number(locateUserLevel) === 1}>
                      <div className="card dashboard-gold-inner-card-1 h-100">
                        <i className="fa-solid fa-chevron-left"></i>
                      </div>
                    </button>
                  </a>
                </div>
                <div className="col-lg-8 order-lg-2 order-3">
                  <div className="card dashboard-gold-inner-card-2">
                    <div className="d-flex flex-row justify-content-between">
                      <p
                        className="dashboard-text-2 mb-0"
                        style={{ opacity: "0.6" }}
                      >
                        Lvl {locateUserLevel}
                      </p>
                      <p className="dashboard-text-2 mb-0">ID {locateUserId}</p>
                      <p className="dashboard-text-2 mb-0">
                        <img
                          className="dashboard-gold-inner-img-1"
                          src={imglogo}
                          alt="logo"
                        />{" "}
                        <span style={{ opacity: "0.8" }}>{DUSDPrice}</span>
                      </p>
                    </div>

                    {/* <div className="xcoreinner-total-grid-overrall"> */}
                    {
                      cycleloader
                        ? (
                          // <Circles
                          //   height="300"
                          //   width="200"
                          //   color="#fff583"
                          //   ariaLabel="circles-loading"
                          //   wrapperStyle={{}}
                          //   wrapperClass="circle-loader"
                          //   visible={true}/>

                          <div class="spinner-grow circle-loader" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>)
                        : (<>
                          <div className="xcoreinner-total-grid-overrall">
                            <div className="xcoreinner-total-grid-2">

                              <div className="grid-dashboard-xcoreinner-l2-container">
                                {firstLoopStageOfIdNumber.length >= 0 && firstLoopStageOfIdNumber.map((item, index) => {

                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className={`dashboard-user-xcoreinner-all-level-2-programs ${item !== 0
                                          ? "dashboard-user-xcoreinner-all-level-2-programs dashboard-user-xcoreinner-programs-active"
                                          : ""
                                          }`}
                                      >
                                        {item === 0
                                          ? ""
                                          : parseInt(item)}
                                      </div>
                                    </>
                                  )
                                })}
                              </div>

                            </div>

                            <div className="xcoreinner-total-grid-1">

                              <div className="grid-dashboard-xcoreinner-l1-container">
                                {firstSeconderyLevelStatus.length >= 0 && firstSeconderyLevelStatus.map((item, index) => {
                                  return (

                                    <>
                                      <div key={index} className={`dashboard-user-xcoreinner-all-level-programs ${item !== 0
                                        ? "dashboard-user-xcoreinner-all-level-programs dashboard-user-xcoreinner-all-level-programs-active"
                                        : ""
                                        }`}
                                      >
                                        {item == 0
                                          ? ""
                                          : parseInt(item)}
                                      </div>

                                    </>

                                  )
                                })}

                              </div>
                            </div>
                          </div>
                        </>)
                    }


                    {/* </div> */}


                    <div className="d-flex flex-lg-row mt-5 gap-4">
                      <div>
                        <p className="mb-1">Partners</p>
                        <p className="mb-0">
                          <i className="fa-solid fa-user-group me-2"></i>{partnerFirstLoopLength}
                        </p>
                      </div>
                      <div>
                        <p className="mb-1">Cycles</p>
                        <p className="mb-0">
                          <i className="fa-solid fa-arrows-rotate me-2"></i>{displayCount}
                        </p>
                      </div>
                      <div className="ms-lg-auto">
                        <p className="mb-1">Total level revenue</p>
                        <p className="mb-0">
                          <i className="fa-solid fa-wallet me-2"></i>{totalLevelRevenue}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-6 order-lg-3 order-2">
                  <a onClick={() => { onChangeNextLevel() }} disabled={Number(locateUserLevel) === 10}>
                    {/* disabled={theCycleCount == displayCount - 1} */}
                    <button className="card dashboard-gold-inner-card-1 h-100" disabled={Number(locateUserLevel) === 10}>

                      <div className="card dashboard-gold-inner-card-1 h-100">
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>

                    </button>
                  </a>
                </div>

              </div>
              <div className="row p-2 g-4 justify-content-center">
                <div className="col-lg-8">
                  <div className="card dashboard-gold-inner-card-3">
                    <div className="d-flex flex-row justify-content-center gap-4 align-items-center">
                      <div className="">
                        <a onClick={() => { changeCyclesDiagramPrevious() }} disabled={theCycleCount == 1}>
                          {theCycleCount === 1 ? (<p className="mb-0 dashboard-text-4">

                          </p>) : (
                            <button className="mb-0 dashboard-text-4" >
                              <p className="mb-0 dashboard-text-4">
                                {theCycleCount - 1} <i className="fa-solid fa-chevron-down ms-1"></i>
                              </p>
                            </button>
                          )}
                        </a>
                      </div>
                      <div className="">
                        <p className="mb-0 dashboard-text-4">Cycle: {theCycleCount}</p>
                      </div>
                      <div className="">
                        <a onClick={() => { changeCyclesDiagramNext() }} >
                          {(theCycleCount == displayCount || cyclecountnumber == 0) ? (
                            <p className="mb-0 dashboard-text-4">

                            </p>
                          ) : (
                            <button className="mb-0 dashboard-text-4" disabled={theCycleCount == displayCount}>
                              <p className="mb-0 dashboard-text-4">
                                {theCycleCount + 1} <i className="fa-solid fa-chevron-up ms-1"></i>
                              </p>
                            </button>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-2 g-4">
                <div className="col-lg-12">
                  <div className="card dashboard-x3-card-2 mt-4">
                    <table className="table">
                      <thead className="">
                        <tr>
                          <th scope="col">SI.no</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">ID</th>
                          <th scope="col">Level</th>
                          <th scope="col">Wallet</th>
                          <th scope="col">DUSD</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(locateUserLevel === "1") ?
                          // level 1 datas
                          (transactionDetailsInTablelevel1.length === 0 || partnerFirstLoopLength === 0) ? (
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td> Record not found</td>
                              <td></td>
                              <td></td>
                              <td></td>

                            </tr>
                          ) : (
                            transactionDetailsInTablelevel1.length > 0 &&
                            transactionDetailsInTablelevel1
                              .sort((a, b) => new Date(b.create_At) - new Date(a.create_At)) // Sort by create_At in descending order
                              .filter(item => item.userLevel === "Level-" + locateUserLevel)
                              .slice(0, visibleItems)
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                    <td>{item.create_At}</td>
                                    <td><button className="dashboard-button-1" onClick={() => { invitedIdRedirect(item.userIds) }}>ID {item.userIds}</button></td>
                                    <td>{item.userLevel}</td>
                                    <td>
                                      <div className="ms-auto d-flex align-items-center gap-2" style={{ fontsize: "13px" }}>
                                        <span>
                                          {sliceAddress(item.walletAddress)}
                                        </span>
                                        <button className="border-0 bg-transparent textwhite">
                                          <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.tx_Hash}`} target='_blank'>
                                            <i className="fa-solid fa-link"></i>
                                          </a>
                                        </button>
                                      </div>
                                    </td>
                                    <td>{item.amount} DUSD</td>
                                  </tr>
                                );
                              })
                          ) :
                          // level 2 above datas
                          (transactionDetailsInTable.length === 0 || partnerFirstLoopLength === 0) ? (
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td> Record not found</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          ) : (
                            transactionDetailsInTable.length > 0 &&
                            transactionDetailsInTable
                              .sort((a, b) => new Date(b.create_At) - new Date(a.create_At)) // Sort by create_At in descending order
                              .filter(item => item.userLevel === "Level-" + locateUserLevel)
                              .slice(0, visibleItems)
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                    <td>{item.create_At}</td>
                                    <td><button className="dashboard-button-1" onClick={() => { invitedIdRedirect(item.userIds) }}>ID {item.userIds}</button></td>
                                    <td>{item.userLevel}</td>
                                    <td>
                                      <div className="ms-auto d-flex align-items-center gap-2" style={{ fontsize: "13px" }}>
                                        <span>
                                          {sliceAddress(item.walletAddress)}
                                        </span>
                                        <button className="border-0 bg-transparent textwhite">
                                          <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.tx_Hash}`} target='_blank'>
                                            <i className="fa-solid fa-link"></i>
                                          </a>
                                        </button>
                                      </div>
                                    </td>
                                    <td>{item.amount} DUSD</td>
                                  </tr>
                                );
                              })
                          )}

                        {/* {(transactionDetailsInTable.length === 0 ) ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td> Record not found</td>
                            <td></td>
                            <td></td>
                            <td></td>

                          </tr>
                        ) : (
                          transactionDetailsInTable.length > 0 &&
                          transactionDetailsInTable
                            .sort((a, b) => new Date(b.create_At) - new Date(a.create_At)) // Sort by create_At in descending order
                            .filter(item => item.userLevel === "Level-" + locateUserLevel)
                            .slice(0, visibleItems)
                            .map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                  <td>{item.create_At}</td>
                                  <td><button className="dashboard-button-1" onClick={() => { invitedIdRedirect(item.userIds) }}>ID {item.userIds}</button></td>
                                  <td>{item.userLevel}</td>
                                  <td>
                                    <div className="ms-auto d-flex align-items-center gap-2" style={{ fontsize: "13px" }}>
                                      <span>
                                        {sliceAddress(item.walletAddress)}
                                      </span>
                                      <button className="border-0 bg-transparent textwhite">
                                        <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.tx_Hash}`} target='_blank'>
                                          <i className="fa-solid fa-link"></i>
                                        </a>
                                      </button>
                                    </div>
                                  </td>
                                  <td>{item.amount} DUSD</td>
                                </tr>
                              );
                            })
                        )} */}

                      </tbody>
                    </table>

                    {(locateUserLevel === "1") ?
                      transactionDetailsInTablelevel1.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                        <button
                          className="btn dashboard-activity-seemore-btn2 my-2 m-2 py-2" type="button"
                          onClick={handleSeeMoreClick}
                        >
                          <IoIosArrowDown /> {'See More'}
                        </button>

                      ) :
                      transactionDetailsInTable.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                        <button
                          className="btn dashboard-activity-seemore-btn2 my-2 m-2 py-2" type="button"
                          onClick={handleSeeMoreClick}
                        >
                          <IoIosArrowDown /> {'See More'}
                        </button>

                      )
                    }
                    {/* {transactionDetailsInTable.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                      <button
                        className="btn dashboard-activity-seemore-btn2 my-2 m-2 py-2" type="button"
                        onClick={handleSeeMoreClick}
                      >
                        <IoIosArrowDown /> {'See More'}
                      </button>

                    )} */}


                    {/* {transactionDetailsInTable.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                      <button
                        className="btn dashboard-activity-seemore-btn mt-5 py-2" type="button"
                        onClick={handleSeeLessClick}
                      >
                        <IoIosArrowUp /> {'See Less'}
                      </button>

                    )} */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dmlmxcoreinner;
