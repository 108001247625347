import React, { useState, useEffect } from 'react'
import { BsTwitterX } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { getsocialmedialinks, UserInfluencer } from '../Dashboard-pages/api/authapis';
import { PiArrowRightBold } from "react-icons/pi";
import Select from 'react-select';
import selectContact from 'react-select';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import { IoLogoWechat } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebookMessenger } from "react-icons/fa";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Web3 from 'web3';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import config from '../config/apiConfig';
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import ToastApi from '../config/toastApi';
import { toast } from 'react-toastify';
import { valHooks } from 'jquery';
import $ from 'jquery';

import black from "../Assets/images/Asset 2.png";
import white from "../Assets/images/Asset 1.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import { FaMedium } from "react-icons/fa6";



var web3js = '';

if (window.ethereum) {
    web3js = new Web3(window.ethereum);
    // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
} else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
}
const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);


const Schema = yup.object().shape({
    name: yup.string().required("name is require"),
    address: yup.string().required("address is required").matches("^(0x)?[0-9a-fA-F]{40}$", "Address only required").test("Address-Check", "Already Registered Address", async (value) => {
        if (!value) return false;

        try {
            const Address = value;
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(Address).call();
            return !checkUserRegisteredExists;  // Return true if not registered, false if already registered
        } catch (error) {
            console.error("Error checking address registration", error);
            return false;
        }

    }),
    contactNumber: yup.number().required("contact Number is require")
    // .test('is-10-digits', 'Contact number must be exactly 10 digits', (value) => {

    //     if (!value) return false;

    //     const cleanedValue = value.replace(/\D/g, '')
    //     if (value.startsWith('+')) {
    //         // Check length for numbers starting with +
    //         return cleanedValue.length === 12;  // Example: +91 1234567890
    //     } else {
    //         // Check length for numbers without +
    //         return cleanedValue.length === 10;  // Example: 1234567890
    //     }
    // })
    ,
    youtube: yup.string().required("youtube channel name is require"),
    youtubefolCount: yup.number().typeError('must be a number').integer().positive().required("youtube follow count is require"),
    instagram: yup.string().required("instagram id is require"),
    instaFolCount: yup.number().typeError('must be a number').integer().positive().required("instagram follow  number require"),
    facebook: yup.string().required("facebook page name is require"),
    facebookFolCount: yup.number().typeError('must be a number').integer().positive().required("facebook follow  number require"),
    telegram: yup.string().required("telegram id is require"),
    telegramFolCount: yup.number().typeError('must be a number').integer().positive().required("telegram follow  number require")
})


function Footer(walletAddress) {
    const [medialinks, setMedialinks] = useState([]);
    const [connectedWallet, setConnectedWallet] = useState(null);
    const [isloading, setIsloading] = useState(false);

    const [selectOption, setSelectedOption] = useState(null);
    const [contactNo, setContactNo] = useState(null)

    const [error, setErrors] = useState({})
    const [adderror, setAdderror] = useState("")
    const [selecterror, setSelectError] = useState("")
    const [isOpen, setisOpen] = useState(false)
    const [hideform, setHideform] = useState(true)


    const { register, handleSubmit, control, formState: { errors }, setValue, reset, resetField } = useForm({
        resolver: yupResolver(Schema),
    })

    // useEffect(() => {
    //     const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'), {
    //         backdrop: 'static',
    //         keyboard: false,
    //     });

    //     if (isOpen) {
    //         modal.show();
    //     } else {
    //         modal.hide();
    //     }

    //     return () => {
    //         modal.hide();
    //     };
    // }, [isOpen]);

    const handleClosed = () => {
        setisOpen(false);
        setTimeout(() => {
            window.location.href = "/"
        }, 500);
    };

    const [show, setShow] = useState(false);

    const handledclosing = () => setShow(false);
    const handleShow = () => setShow(true);

    const getsociallinks = async () => {
        try {
            const reponse = getsocialmedialinks();

            const getdata = (await reponse).result;

            if (Number(getdata.length) > 0) {
                setMedialinks(getdata)
            }
            else {
                console.log("data not found");
            }
        } catch (error) {

        }

    }

    useEffect(() => {
        getsociallinks();
    }, [])


    const options = [
        { value: 'select contact', label: 'Select Contact' },
        { value: 'Whatsapp', label: <div className="d-flex align-items-center gap-2"><BsWhatsapp />Whatsapp</div> },
        { value: 'Telegram', label: <div className="d-flex align-items-center gap-2"><FaTelegramPlane />Telegram</div> },
        { value: 'Wechat', label: <div className="d-flex align-items-center gap-2"><IoLogoWechat />Wechat</div> }
    ]

    async function selectContact(data) {
        setSelectedOption(data.value)
    }

    const customStyles = {
        menu: base => ({
            ...base,
            background: "#FFF",
            margin: "0px",
            padding: "0px",
        }),
        menuList: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        control: (base, state) => ({
            ...base,
            background: "transparent",
            color: "#000",
            border: "0px solid transparent",
            boxShadow: state.isFocused ? "none" : base.boxShadow,
            fontSize: "13px",
            margin: "0px",
            padding: "0px",
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: "var(--background-color-1)", // Change background color to red
                color: "var(--color-1)",
                fontSize: "13px",
                "&:hover": {
                    backgroundColor: "gray",
                    color: "var(--color-1)",
                }
            };
        },
    };




    const handleSelectChange = (val) => {
        console.log('val', val)
        setSelectedOption(val.value);
        setValue('contactMethod', val);
        resetField('contactNumber')
        setContactNo("")
        setSelectError("")
        // reset({ [val.value.toLowerCase()]: '' }); // Clear the previous input value
    };


    async function handlechangeAdd(val) {
        try {
            const Invalue = val.target.value
            const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(Invalue).call();
            // console.log("checkUserRegistered=====", checkUserRegisteredExists, Invalue);
            // console.log("valueaddress", val.target.value);
            // if (checkUserRegisteredExists) {
            //     return setAdderror("Already is registered Address");
            // } else if (checkUserRegisteredExists === undefined) {
            //     setAdderror("");
            // } else {
            //     setAdderror("");
            // }
        } catch (error) {
            console.log("checkUserRegisterederror", error);
            setAdderror("")
        }

    }
    const handleChangess = (event, name) => {

        setContactNo(event.target.value);
    };

    const handleClose = () => {
        reset();  // Reset form values
        setContactNo('');  // Clear phone number state
        setSelectedOption(options[0])
        setIsloading(false)
    };


    const OnSubmit = async (data) => {
        try {
            if (data) {
                setIsloading(true);
                const InfluencerDetails = {
                    UserName: data.name,
                    UserAddress: data.address,
                    contactDetails: [{ contacttype: data.contactMethod.value, contactno: data.contactNumber }],
                    Status: true,
                    YoutubeName: data.youtube,
                    YoutubeSubcount: data.youtubefolCount,
                    instagramID: data.instagram,
                    InstagramFollowcount: data.instaFolCount,
                    FacebookName: data.facebook,
                    FacebookFollowcount: data.facebookFolCount,
                    TelegramName: data.telegram,
                    TelegramSubcount: data.telegramFolCount
                }
                console.log("InfluencerDetails", InfluencerDetails);
                if (InfluencerDetails.contactDetails[0].contacttype === "select contact") {
                    setIsloading(false)
                    return setSelectError("Contact type required")
                } else {
                    const response = await UserInfluencer(InfluencerDetails)
                    console.log("response", response);
                    if (response.message === "User already registered") {
                        setIsloading(false)
                        return toast.warn("User Address Already Added")
                    }
                    if (response.status === true) {
                        toast.success("Successfully Submitted")
                        handleClose()
                        handledclosing()
                        setisOpen(true)
                        setIsloading(false)
                        // setTimeout(() => {
                        //     window.location.href = "/"
                        // }, 1000);
                    } else {
                        toast.error(ToastApi.FailedMessage)
                    }

                }

            } else {
                toast.error(ToastApi.FailedMessage)
                setIsloading(false)
            }
        } catch (error) {
            console.log("OnSubmitError", error);
            setIsloading(false)
        }
    }



    // $(function() {
    //     $("input[name='youtube']").autocomplete({
    //         source: function(request, response) {
    //             var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
    //             response($.grep(yourDataArray, function(value) {
    //                 return matcher.test(value.label || value.value || value);
    //             }));
    //         },
    //         open: function(event, ui) {
    //             $(".ui-autocomplete").css("background-color", "transparent");
    //         }
    //     });
    // });



    return (
        <div className='footer'>
            <section className='footer-section'>
                <div className='container py-5'>
                    <div className='row d-flex align-items-center flex-column-reverse flex-md-row'>
                        <div className='col'>
                            <div className='align-items-center d-flex flex-column-reverse flex-lg-row gap-3 justify-content-lg-between'>
                                <div className=''>© 2024 All Rights Reserved <div><a href=''>Disclaimer</a></div></div>

                                <div className=''>
                                    {Array.isArray(medialinks) ? (
                                        medialinks.map((data, index) => (
                                            <div className='d-flex align-items-center gap-2' key={index}>
                                                <div className=''>
                                                    <a href={`${data.Telegram}`} target="_blank"><FaTelegramPlane className='footer-icon' /></a>
                                                </div>
                                                <div className=''>
                                                    <a href={`${data.Whatsapp}`} target="_blank"><FaWhatsapp className='footer-icon' /></a>
                                                </div>
                                                <div className=''>
                                                    <a href={`${data.Twitter}`} target="_blank"><BsTwitterX className='footer-icon' /></a>
                                                </div>
                                                <div className=''>
                                                    <a href={`${data.Instagram}`} target="_blank"><FaInstagram className='footer-icon' /></a>
                                                </div>
                                                <div className=''>
                                                    <a href={`${data.Youtube}`} target="_blank"><FaYoutube className='footer-icon' /></a>
                                                </div>
                                                <div className=''>
                                                    <a href='https://medium.com/@propelx.io' target="_blank"><FaMedium className='footer-icon' /></a>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='d-flex align-items-center gap-2' >
                                            Loading
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </section>

            {/* modal success */}
            {/* <div className='submit'>

                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-center">
                            <div className="modal-body m-auto w-100">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img className="banner-img1 black-logo" src={black} alt="logo" />
                                    <img className="banner-img1 white-logo" src={white} alt="logo" />
                                </div>
                                <span className='pop-three-one my-1'>Thank You !</span>
                                <p className='my-1'>Our team will get in touch with you soon to help you out further.</p>
                                {Array.isArray(medialinks) ? (
                                    medialinks.map((data, index) => (
                                        <div className='d-flex align-items-center justify-content-center gap-3 py-3' key={index}>
                                            <div className=''>
                                                <a href={`${data.Telegram}`} target="_blank"><FaTelegramPlane className='submit-icon' /></a>
                                            </div>
                                            <div className=''>
                                                <a href={`${data.Whatsapp}`} target="_blank"><FaWhatsapp className='submit-icon' /></a>
                                            </div>
                                            <div className=''>
                                                <a href={`${data.Twitter}`} target="_blank"><BsTwitterX className='submit-icon' /></a>
                                            </div>
                                            <div className=''>
                                                <a href={`${data.Instagram}`} target="_blank"><FaInstagram className='submit-icon' /></a>
                                            </div>
                                            <div className=''>
                                                <a href={`${data.Youtube}`} target="_blank"><FaYoutube className='submit-icon' /></a>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='d-flex align-items-center gap-2' >

                                    </div>
                                )}
                                <div className='p-2'><button onClick={handleClosed} className='pop-btn-one'>Ok</button></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}

        </div>
    )
}

export default Footer;