import React, { useState, useEffect } from 'react'

function Modal({ isOpen, onClose }) {
    // if (!isOpen) return null;

    useEffect(() => {
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'), {
            backdrop: 'static',
            keyboard: false,
        });

        if (isOpen) {
            modal.show();
        } else {
            modal.hide();
        }

        return () => {
            modal.hide();
        };
    }, [isOpen]);

    return (
        <div className='modal-popup'>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content text-center">
                        <div className="modal-body m-auto w-75">
                            <span className='pop-three'>Your Address already Whitelisted , Kindly reach our support team for further clarifications.</span>
                            <div className='p-2'><button onClick={onClose} className='pop-btn'>Ok</button></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Modal