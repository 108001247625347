import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,

} from "react-router-dom";
import "./Assets/Css/Home.css";
import $ from 'jquery';

import black from "./Assets/images/Asset 2.png";
import white from "./Assets/images/Asset 1.png";
import DarkLight from './Dashboard-pages/Separate/Dark-Light';
import Window_Ethereum_Config_Api from './config/windowEthereumConfig';
import config from './config/apiConfig';
import Web3 from 'web3';
import DUSDtokenAbi from './ABI/DUSDtokenAbi.json';
import Plan1Abi from './ABI/Plan1Abi.json';
import DemoCorePlanAbi from './ABI/DemoCorePlan.json';
import { AiOutlineArrowRight } from "react-icons/ai";
import earth from "./Assets/videos/scene.m4v"
import { IoWalletSharp } from "react-icons/io5";
import { RiShareBoxFill } from "react-icons/ri";
import iconImage from "./Assets/images/dusd.png";
import { FaLayerGroup } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import Footer from './Pages/Footer';
import { getsocialmedialinks, showUserTransactionInDashBoard, getFaqcontentdetails } from "./Dashboard-pages/api/authapis"
import { formatDistanceToNow } from 'date-fns';
import ToastApi from './config/toastApi';
import { ToastContainer, toast } from 'react-toastify';
import one from "./Assets/images/Enraune-Vour-Inunotmant.gif";
import two from "./Assets/images/Built-on-Transparency.gif";
import three from "./Assets/images/Join-our-Community.gif";
import four from "./Assets/images/Simplified-Success.gif";
import five from "./Assets/images/Seamless-Integration.gif";
import six from "./Assets/images/Security-First-.gif";
import axios from 'axios';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import pencil from "./Assets/images/pencil.png";
import question from "./Assets/images/question.png";
import Influencerform from './Dashboard-pages/influencerform'
import { FaCopy } from 'react-icons/fa';
import { FaMedium } from "react-icons/fa6";

import AOS from 'aos';
import 'aos/dist/aos.css';

import iconposter from "./Assets/images/iconposter.png";
import bani from "./Assets/images/bani.png";


function Home() {

  const [network, setNetwork] = useState('');
  const [walletAddress, setWalletAddress] = useState(null);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [medialinks, setMedialinks] = useState([]);
  const [registerDataList, setRegisterDataList] = useState([]);
  const [listview, setListview] = useState(7)
  const toastCheck = React.useRef(null);
  const [faqcontnet, setFaqcontnet] = useState([])
  var web3js = '';

  if (window.ethereum) {
    // web3js = new Web3(window.ethereum);
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }
  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);



  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        // Request account access
        const web3 = new Web3(window.ethereum);

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
          // const web3 = new Web3(window.ethereum);  
          const account = await web3.eth.getAccounts();
          const address = account[0];
          // const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(address).call();
          // if (checkUserRegisteredExists == true) {
          //   setShow(true)
          // } else {
          //   setShow(false)
          // }
          setWalletAddress(account[0]);
          localStorage.setItem('walletAddress', address);

          await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
        }

        // Switch network
        // await window.ethereum.request({
        //   method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        //   params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
        // });

        // Get current network ID
        const networkId = await web3.eth.net.getId();
        setNetwork(networkId);


      } catch (error) {
        console.log("Home ConnectError:", error)
        setError(error.message || error);
      }
    } else {
      toast.warn(ToastApi.PleaseInstallMetamask)
      setTimeout(() => {
        handleInstallMetaMask('https://metamask.io/download.html')
      }, 1000);
      setError('Please install MetaMask or another Web3 provider extension');
    }
  };

  const handleInstallMetaMask = async (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus()
    }
  };

  const RegAddressShow = async () => {
    try {
      const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(walletAddress).call();
      if (checkUserRegisteredExists == true) {
        setShow(true)
      } else {
        setShow(false)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    RegAddressShow()
  }, [walletAddress])

  useEffect(() => {

    const switchNetwork = async () => {
      try {
        // Switch network
        await window.ethereum.request({
          method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
          params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
        });
      } catch (error) {

      }

    }



    const eventAccountWatch = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });


        if (accounts.length === 1) {
          setWalletAddress(accounts[0]);

        } else if (accounts.length > 1) {
          if (!toast.isActive(toastCheck.current)) {
            toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount);
            setWalletAddress(accounts[0])
          }
        }
        else if (accounts.length == 0) {
          if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.ConnectYourWallet, { autoClose: 1300 });
        }
      }
      else {
        if (isMobileDevice()) {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn("connect mobile metamask");
        } else {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn(ToastApi.PleaseInstallMetamask);
        }
      }
      // else if (accounts.length == 0) {
      //   toast.warning(ToastApi.ConnectYourWallet);
      // }
    }
    eventAccountWatch();
    // switchNetwork();
    // connectWallet();
  }, [walletAddress]);


  const sliceAddress = (getTheConnectedWalletAddress, startLength = 6, endLength = 6) => {
    try {
      if (!getTheConnectedWalletAddress) return '';

      const prefix = getTheConnectedWalletAddress.slice(0, startLength);
      const suffix = getTheConnectedWalletAddress.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };

  const addCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      else {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      // handle other "switch" errors
    }
  }

  useEffect(() => {
    $(document).ready(function () {
      $(window).scroll(function () {
        //Method 1: Using addClass and removeClass
        if ($(document).scrollTop() > 100) {
          $('.home-section-one').addClass('homenav');
        } else {
          $('.home-section-one').removeClass('homenav');
        }
      });
    });
  });

  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const handleDesktopConnectClick = () => {
    try {
      connectWallet()
    } catch (error) {
      console.log('error', error)
    }
  }


  const mobilewalletConnect = async () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      try {

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          if (accounts && accounts.length > 0) {
            const address = accounts[0];
            localStorage.setItem("address", accounts[0]);
            setWalletAddress(accounts[0]);

          } else {
            console.log("Account request failed");
          }
          window.location.reload()
        }
      } catch (error) {
        console.error("Failed to request accounts:", error);
      }
    }
    // else {
    //     toast.warn("Please install metamask");
    //     openInNewTab(
    //       `https://metamask.io/download/`
    //     );
    //   }
  };


  const handleMobileConnectClick = async () => {
    try {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        mobilewalletConnect();
        return;
      }
      else {
        // const confirmed = window.confirm(
        //   "Connect your MetaMask wallet to this app?"
        // );

        let dappUrl = `${config.FRONTEND_URL}`;
        let metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
        window.location.href = metamaskAppDeepLink;

      }

    } catch (error) {
      console.log("error", error);
    }

  };


  const addMobileCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }

    }
  }


  async function checkIfWalletIsConnected() {
    if (window.ethereum) {
      if (isMobileDevice()) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          if (accounts && accounts.length > 0) {
            const address = accounts[0];
            localStorage.setItem("address", accounts[0]);
            setWalletAddress(accounts[0]);
          }
          await addMobileCustomNetwork();
        }
        else {
          if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });

            if (accounts && accounts.length > 0) {
              const address = accounts[0];
              localStorage.setItem("address", accounts[0]);
              setWalletAddress(accounts[0]);
            }
          }

        }
      }

    } else {
      console.log("metmask not deteched");
    }
  }

  useEffect(() => {

    checkIfWalletIsConnected();
  }, []);

  const getsociallinks = async () => {
    try {
      const reponse = await getsocialmedialinks();

      const getdata = (await reponse).result;
      if (Number(getdata.length) > 0) {
        setMedialinks(getdata)
      }
      else {
        console.log("data not found");
      }
    } catch (error) {

    }

  }

  const getFaqdetails = async () => {
    try {
      const reponse = await getFaqcontentdetails()

      const getdata = (await reponse).result;
      if (Number(getdata.length) > 0) {
        setFaqcontnet(getdata)
      }
      else {
        console.log("data not found");
      }
    } catch (error) {

    }

  }

  const getListOfRegisterData = async () => {
    try {
      const resultOfRegisterData = await showUserTransactionInDashBoard();

      const modifiedDataArray = [];

      for (const registerDataItem of resultOfRegisterData.data.data) {
        // Convert the Create_At timestamp to a JavaScript Date object
        const createdAtDate = new Date(registerDataItem.Create_At);

        const newItem = {
          newUserReferralId: registerDataItem.New_User_Referral_Id,
          newUserRegisteredAddress: registerDataItem.New_User_Registered_Address,
          referralAddress: registerDataItem.Referral_Address,
          referralId: registerDataItem.Referral_ID,
          status: registerDataItem.status,
          txHash: registerDataItem.tx_Hash,
          dateTime: formatDistanceToNow(createdAtDate, { addSuffix: true }),
        };


        modifiedDataArray.push(newItem);
      }
      // modifiedDataArray.sort((a, b)=>new Date())
      setRegisterDataList(modifiedDataArray);

    } catch (error) {
      console.error('Error fetching register data:', error);
    }
  };

  const invitedIdRedirect = async (invitedId) => {
    try {

      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + `${invitedId}`;
    } catch (error) {

    }
  };

  const seemoreData = async () => {
    let num = 5
    if (registerDataList.length === listview) {
      return
    }

    setListview((prev) => {
      return prev + num;
    });
  }

  useEffect(() => {
    getsociallinks();
    getFaqdetails();
    getListOfRegisterData();
  }, [])


  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);


  const handleCopy = () => {
    navigator.clipboard.writeText(config.DUSD_TOKEN_ADDRESS)
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
    toast.success(ToastApi.CopiedAddress)
  };

  const AddDchain = async () => {
    try {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);

      }
    } catch (error) {
      toast.error(ToastApi.WalletConnectionError);
    }
  }

  const CheckRegister = async () => {
    try {

      if (!walletAddress) {
        // Show a toast when the wallet is not connected
        toast.warn(ToastApi.walletConnectText);
        return;
      }

      if (web3js) {
        const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(walletAddress).call();
        if (checkUserRegisteredExists) {
          const checkRegisteredIds = await DemoCorePlanContract.methods.users(walletAddress).call();
          if (checkRegisteredIds.id.toString()) {
            setInterval(() => {
              window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + checkRegisteredIds.id.toString()
            }, 500);
          }
        } else {
          setInterval(() => {
            window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
          }, 500);
        }
      }

    } catch (error) {
      toast.warn(ToastApi.WalletConnectionError);
      console.log('CheckRegister Error', error)
    }
  }

  const SwitchDashboard = async () => {
    try {
      if (web3js) {
        const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(walletAddress).call();
        if (checkUserRegisteredExists) {
          const checkRegisteredIds = await DemoCorePlanContract.methods.users(walletAddress).call();
          if (checkRegisteredIds.id.toString()) {
            setInterval(() => {
              window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + checkRegisteredIds.id.toString()
            }, 500);
          }
        } else {
          setInterval(() => {
            window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
          }, 500);
        }
      }
    } catch (error) {
      console.log('SwitchDashboard Error:', error)
      toast.error("server Connect Error")
    }
  }
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePosterClick = () => {
    setIsPlaying(true);
  };



  return (
    <div className='dmlm-home-section'>
      <ToastContainer />
      <section className="home-section-one fixed-top">
        <div className="container-fluid top-banner-section">
          <div className="row ">
            <div className="col">
              <nav className="navbar navbar-expand-lg text-white py-2">
                <div className="container-fluid">
                  <a className="navbar-brand pb-lg-0 pt-lg-2" href="/">
                    <img className="banner-img1 black-logo" src={black} alt="logo" />
                    <img className="banner-img1 white-logo" src={white} alt="logo" />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon banner-icon1"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="col">
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-5">
                        {/* <li className="nav-item header-texthover">
                            <a className="nav-link  active banner-text-1 header-fontsize1" aria-current="page" href="#">Community</a>
                          </li>
                          <li className="nav-item header-texthover">
                            <a className="nav-link banner-text-1 header-fontsize1 " href="#">Documentation</a>
                          </li> */}
                      </ul>
                    </div>

                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-lg-center gap-2 gap-lg-0">

                      {show
                        ? (
                          <>
                            <li className="nav-item me-lg-3 button-hover rounded-pill">
                              <Link className="" to="/login">
                                <button className="btn fw-bold">View Panel</button>
                              </Link>
                            </li>
                            <li className="nav-item me-lg-3 button-hover rounded-pill">
                              <Link className="" onClick={SwitchDashboard}>
                                <button className="btn fw-bold">Dashboard</button>
                              </Link>
                            </li>
                          </>
                        )
                        : (
                          <li className="nav-item me-lg-3 button-hover rounded-pill">
                            <Link className="" to="/login">
                              <button className="btn fw-bold">Join Now</button>
                            </Link>
                          </li>
                        )}


                      <li className="nav-item me-lg-3 button-hover rounded-pill">
                        <Link className="" to="/information">
                          <button className="btn fw-bold">Information</button>
                        </Link>
                      </li>




                      {walletAddress ? (
                        <li className="nav-item me-lg-3 button-hover rounded-pill">
                          <button className="btn  fw-bold me-3" type="submit">
                            {sliceAddress(walletAddress)}
                          </button>
                        </li>
                      ) : (
                        <>
                          <li className="nav-item button-hover rounded-pill me-lg-3" onClick={() => {
                            isMobileDevice() ? handleMobileConnectClick() : handleDesktopConnectClick()
                          }}>
                            <Link className="">
                              <button className="btn  fw-bold ">Connect Wallet</button>
                            </Link>
                          </li>

                        </>
                      )}


                      {show ? (
                        <>
                        </>
                      ) : (
                        <>
                          <li className="nav-item button-hover rounded-pill me-lg-4">
                            <Link className="" to="/registration">
                              <button className="btn  fw-bold ">Registration</button>
                            </Link>
                          </li>
                        </>
                      )}

                      <li className=''><DarkLight /></li>
                    </ul>

                  </div>
                </div>
              </nav>
            </div>
          </div>

        </div>
      </section>

      <section className='home-section-two'>
        <video id="video-background" autoPlay={true} loop muted poster>
          <source src={earth} type="video/mp4 " className="video_contain " />
        </video>
        <div className="container-fluid">
          <div className='row align-items-center'>
            <div className='col text-center mx-auto'>
              <div className='blink-soft mb-3  banner-section2-text2'>
                <img src={bani} className='baniapp' alt='bani'/>
                  Sign up on PropelX and earn 2,000 <span className='bani-text'>$BANI</span> each day !
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-8 col-12 col-md-9 text-center mx-auto">
              <p className="banner-section2-text1 mb-lg-3 mb-3" >Welcome to <span className="gradient-color fw-semibold letter-animation"><span>P</span><span>r</span><span>o</span><span>p</span><span>e</span><span>l</span><span>X</span></span> : Introducing the Future of Cryptocurrency Investments</p>
              <p className='col-lg-8 col-12 col-md-9 mx-auto mb-lg-4 text-white banner-texttwo'>"Experience a platform where <span className='gradient-color fw-semibold'>innovation, equity, and community </span>collaboration shape a transparent and empowering investment journey for all."</p>
              {/* <Link className="" to="/login">
                <button className="signin-hide-desk btn  py-lg-1 px-lg-5 rounded-4 banner-btn-1 mb-2">Sign In<AiOutlineArrowRight className="ms-3" /></button>
              </Link> */}
              <div className='d-flex align-items-center justify-content-center flex-column flex-lg-row gap-1 gap-lg-4'>
                {/* <div className=''>
                  <Link className="" to="/registration">
                    <button className="btn rounded-4 banner-btn-1 pe-0 ps-3">Registration<AiOutlineArrowRight className="arrow-right" /></button>
                  </Link>
                </div> */}
                <div><Influencerform /></div>
                <div className='influencer'>
                  <a className="influence-btn btn mb-3 mb-lg-0 rounded-4" onClick={CheckRegister}>
                    Apply for Career
                    <AiOutlineArrowRight className="influence-btn-icon" />
                  </a>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
      <section className='home-section-eight'>
        <div className='container-fluid'>
          <div className='row align-items-center justify-content-center pb-5'>

            <div className='col-lg-6'>
              <div className="video-wrapper" style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                {/* {!isPlaying && (
                  <img
                    src={iconposter}
                    alt="Video Poster"
                    className="video-poster"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      cursor: 'pointer',
                      zIndex: 1,
                      borderRadius:'15px'
                    }}
                    onClick={handlePosterClick}
                  />
                )} */}

                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/kFslz3Ci9KE"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2, borderRadius: '15px' }}
                ></iframe>


              </div>
            </div>




          </div>
          <div className='row row-one align-items-center justify-content-center gap-5 gap-lg-0'>
            <div className='col-lg-5'>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Steps to Register</h5>
                  <p class="card-text">
                    <ul className='info-steps'>
                      <li className=''>Connect your wallet (MetaMask or D-Wallet extension).</li>
                      <li className=''>Ensure you have 15 DUSD to purchase a Level 1 Card. You can swap 15 USDT for 15 DUSD on the BNB Network via the D-ecosystem Salespad, or buy directly using your card through MetaMask's fiat payment system.</li>
                      <li className=''>Remember to purchase a small amount of $BNB and $DCX to cover the gas fees.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div class="card">
                <div class="card-body">
                  <p className='text-white banner-texttwo'>
                    Purchase DUSD & DCX by clicking below
                  </p>
                  <p class="fs-6">
                    <div className='d-flex align-items-center  gap-3 flex-wrap'>
                      <div><a href={`${config.SALESPAD_DUSD_URL}`} target='_blank'><button className='info-button'>Buy DUSD</button></a></div>
                      <div><a href={`${config.SALESPAD_DUSD_URL}`} target='_blank'><button className='info-button'>Buy DCX</button></a></div>
                      <div><a><button onClick={AddDchain} className='info-button'>Add D-Chain</button></a></div>
                    </div>
                  </p>
                  <div className='text-white text-two'>
                    DUSD Contract Address :
                    <span className="">
                      <a
                        href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DUSD_TOKEN_ADDRESS}`}
                        target='_blank'
                        className='tutorial-link'
                        rel="noopener noreferrer"
                      >
                        {sliceAddress(config.DUSD_TOKEN_ADDRESS)}
                      </a>
                      <button type='button' onClick={handleCopy} className='bg-transparent border-0'><FaCopy className='tutorial-link ms-1' /></button>
                    </span>
                  </div>
                  <div className='text-white text-two'>
                    How to start PropelX Journey?
                    <a href='https://youtu.be/ZynHzHUb30g?feature=shared' target='_blank' className='tutorial-link'>Tutorial Video </a>
                  </div>
                  <p className='text-two'>
                    Contact Support :
                    <a href="mailto:info.propelx@gmail.com" target='_blank' className='tutorial-link'>
                      info.propelx@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center pt-lg-5'>
              <Link className="" to="/registration">
                <button className="btn rounded-4 banner-btn-1 pe-0 ps-3">Registration<AiOutlineArrowRight className="arrow-right" /></button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* In Home-section-three , Already hidden elements are static table row array and Share button */}
      {/* <section className="home-section-three">
        <div className="container-fluid banner-section-3 banner-section-3-top ">
          <h1 className="banner-text fw-bold text-center" >Platform Recent Activity</h1>
          <p className="banner-text fs-6 py-3 text-center">Real-time global events of the PropelX Platform</p>

          <div className="container banner-section3-card" >
            <div className="row col-lg-10 mx-auto see-more-users-box" data-aos="fade-up">
              {Array.isArray(registerDataList) ? (
                registerDataList && registerDataList.slice(0, listview).map((item, index) => (
                  <>
                    <div className="col-12" key={index}>
                      <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-4 p-2 gap-5">
                        <span>
                          <img src={iconImage} className="banner-img-4 me-lg-2 me-1"></img>
                          <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-lg-2 me-1 p-2" />
                          <button type="button" className="banner-btn-3 me-1 banner-section3-text5" onClick={() => invitedIdRedirect(item.newUserReferralId)}>
                            ID{item.newUserReferralId}
                          </button>
                          <span className="textvalue"><span className="opacity-50 banner-section3-text5">+</span><span className="banner-section3-text5">15 DUSD </span>
                            <span className="banner-section3-text5 px-1 px-lg-1 xcore-text">in xCore</span></span>
                        </span>
                        <div className="ms-auto textvalue opacity-50 banner-section3-text5" >
                          <span>
                            <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.txHash}`} target='_blank'>
                              <RiShareBoxFill className="fs-5 me-2" />
                            </a>
                          </span>
                          <span>{item.dateTime}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div> no data </div>
              )}


              <div className="col-12 text-center py-3 ">
                {registerDataList.length > 7 ?
                  (listview >= (registerDataList.length))
                    ? (
                      <button type="button" className="btn btn-see banner-btn-4 py-2" disabled={true} >
                        See more
                      </button>) : (
                      <button type="button" className="btn btn-see banner-btn-4 py-2" onClick={seemoreData}>
                        See more
                      </button>)
                  : " "}

              </div>
            </div>


          </div>
        </div>
      </section> */}

      <section className='home-section-four'>
        <div className="container banner-section-4">
          <div className="row">
            <div className="col">
              <h1 className="text-center banner-text-3 fw-bold banner-section4-text1" >Transparency Redefined : <span className='text-two'>All on the Blockchain</span>
              </h1>
              <div className="text-center  banner-section4-text2 py-3 px-lg-5"><div className="banner-setion4-font">PropelX ensures every transaction and action is visible on the blockchain, fostering a
                transparent and trustworthy environment. Experience a platform where integrity is
                open for verification, building a foundation of mutual trust</div>
                <div className="">Contract Address dusd : <span className="dusd-add">{<a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DUSD_TOKEN_ADDRESS}`} target='_blank'>
                  {sliceAddress(config.DUSD_TOKEN_ADDRESS)}
                </a>
                }</span>
                </div></div>
            </div>
            <div className="text-center">
              {
                <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DUSD_TOKEN_ADDRESS}`} target='_blank'>
                  <button className="banner-section4-btn  px-3 py-2">DUSD</button>
                </a>
              }
            </div>


          </div>

          {/* <div className="row py-lg-5 py-3" data-aos="fade-up">
            <div className="col-lg-6 col-12 py-lg-0 py-3 text-center">
              <div className="d-flex align-items-center mx-auto justify-content-center">



                <span className="banner-section4-num1">
                  <CountUp end={registerDataList.length} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </span>
                <span className="fs-4 fw-bold ms-2" style={{color: "rgba(44,255,78)", verticalalign: "middle"}}> +1 901</span>
              </div>
              <div className="text-center flex-row fw-bold" >Accounts count</div>
            </div>
            <div className="col-lg-6 col-12 py-lg-0 py-3 text-center">
              <div className="d-flex align-items-center mx-auto justify-content-center">

                <span className="banner-section4-num1 ">
                  <CountUp end={registerDataList.length * 15} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </span>
                <span className="fs-4 fw-bold ms-2" style={{color: "rgba(44,255,78)", verticalalign: "middle"}}>+59 545</span>
              </div>
              <div className="text-center flex-row fw-bold">Total result, DUSD</div>
            </div>
          </div> */}
        </div>
      </section>
      <section className='home-section-five'>
        <div className='container'>
          <h1 className="text-center fw-bold pb-5 banner-text" >Welcome to PropelX : <span className='text-two'>The Future of Crypto Growth</span></h1>
          <p className='text-center mb-5'>Explore PropelX, where we're setting new standards in cryptocurrency distribution. Our innovative platform offers a
            robust alternative to traditional second income stream, focusing on transparency, fairness, and empowerment for every investor.</p>
          <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4' data-aos="fade-up">
            <div class="col">
              <div class="card h-100">
                <div class="card-body p-0">
                  <img src={one} className='card-icon' alt='gif'></img>
                  <h4 class="mt-4 fw-bold">Empower Your Investment</h4>
                  <p class="text-justify" style={{ opacity: 0.5 }}>
                    PropelX democratizes cryptocurrency
                    investment, making it accessible and
                    equitable for all. Our platform is built for
                    everyone, from crypto enthusiasts to
                    newcomers, providing an environment
                    where your crypto portfolio can thrive.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body p-0">
                  <img src={two} className='card-icon' alt='gif'></img>
                  <h4 class="mt-4 fw-bold">Built on Transparency</h4>
                  <p class="text-justify" style={{ opacity: 0.5 }}>
                    We prioritize trust and transparency.
                    PropelX operates on clear principles,
                    openly sharing information about our
                    distribution model, potential rewards,
                    and associated risks. Invest with
                    confidence in a system you can trust.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body p-0">
                  <img src={three} className='card-icon' alt='gif'></img>
                  <h4 class="mt-4 fw-bold">Join our Community</h4>
                  <p class="text-justify" style={{ opacity: 0.5 }}>
                    Become part of a community that values
                    growth and collaboration. PropelX
                    connects you with like-minded
                    individuals passionate about creating a
                    more inclusive financial future. Together,
                    we're more than investors; we're
                    pioneers.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body p-0">
                  <img src={four} className='card-icon' alt='gif'></img>
                  <h4 class="mt-4 fw-bold">Simplified Success</h4>
                  <p class="text-justify" style={{ opacity: 0.5 }}>
                    Our innovative system simplifies the
                    crypto distribution process, using
                    advanced technology to maximize your
                    potential for success. PropelX is not just
                    about cryptocurrency; it's about
                    fostering a scalable ecosystem for
                    growth.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body p-0">
                  <img src={five} className='card-icon' alt='gif'></img>
                  <h4 class="mt-4 fw-bold">Seamless Integration</h4>
                  <p class="text-justify" style={{ opacity: 0.5 }}>
                    PropelX seamlessly integrates into your
                    financial landscape, offering tools and
                    features that enhance your investment
                    process. Experience a user-friendly
                    interface and functionalities designed for
                    optimal performance and ease of use.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body p-0">
                  <img src={six} className='card-icon' alt='gif'></img>
                  <h4 class="mt-4 fw-bold">Security First</h4>
                  <p class="text-justify" style={{ opacity: 0.5 }}>
                    Your security is our top priority. PropelX
                    employs advanced security measures to
                    protect your investments and personal
                    information. Rest easy knowing you're
                    part of a platform that values your safety
                    and privacy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='home-section-six'>
        <div className="container banner-section-9">
          <div className="row pb-3">
            <div className="col">
              <h1 className="text-center banner-text fw-bold fs-1 pb-5">Official Channels</h1>
            </div>
          </div>

          {Array.isArray(medialinks) ? (
            medialinks.map((data, index) => (

              <div data-aos="fade-up" className="row row-cols-2 row-cols-lg-6 text-center g-4 justify-content-center" key={index}>
                <div className="col">
                  <a href={`${data.Telegram}`} target="_blank" >
                    <a className="btn__bordered_gradient text-decoration-none">
                      <FaTelegramPlane />
                    </a>
                    <a className="banner-section9-text text-decoration-none">Telegram</a>
                  </a>
                </div>

                <div className="col">
                  <a href={`${data.Whatsapp}`} target="_blank" >
                    <a className="btn__bordered_gradient text-decoration-none">
                      <FaWhatsapp />
                    </a>
                    <a className="banner-section9-text text-decoration-none">Whatsapp</a>
                  </a>
                </div>

                <div className="col">
                  <a href={`${data.Twitter}`} target="_blank">
                    <a className="btn__bordered_gradient text-decoration-none">
                      <BsTwitterX />
                    </a>
                    <a className="banner-section9-text text-decoration-none">Twitter</a>
                  </a>
                </div>


                <div className="col">
                  <a href={`${data.Instagram}`} target="_blank">
                    <a className="btn__bordered_gradient text-decoration-none">
                      <FaInstagram />
                    </a>
                    <a className="banner-section9-text text-decoration-none">Instagram</a>
                  </a>
                </div>

                <div className="col">
                  <a href={`${data.Youtube}`} target="_blank" >
                    <a className="btn__bordered_gradient text-decoration-none">
                      <FaYoutube />
                    </a>
                    <a className="banner-section9-text text-decoration-none">Youtube</a>
                  </a>
                </div>

                <div className="col">
                  <a href='https://medium.com/@propelx.io' target="_blank" >
                    <a className="btn__bordered_gradient text-decoration-none">
                      <FaMedium />
                    </a>
                    <a className="banner-section9-text text-decoration-none">Medium</a>
                  </a>
                </div>
              </div>

            ))
          ) : (
            <div className='d-flex align-items-center gap-2' >
              Loading
            </div>
          )}

        </div>

      </section >
      <section className='home-section-seven'>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h1 className="text-center banner-text-3 fw-bold fs-1 pb-3">Frequently Asked Questions</h1>

              {/* <div class="unselectable">
                This text cannot be selected, and the content will not be cropped.
              </div> */}

            </div>
          </div>

          <div className='position-relative'>
            <img src={question} className='q-tag'></img>
          </div>
          <div className="row pt-lg-5">
            <div className="col">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {
                  Array.isArray(faqcontnet) ? (
                    faqcontnet.map((faq, index) => (
                      <div className="accordion-item " key={index}>
                        <h2 className="accordion-header py-lg-2">
                          <button
                            className="accordion-button collapsed banner-section7-text1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse${index + 1}`} >
                            <img src={pencil} className='pen-img'></img>
                            {faq.title}
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse${index + 1}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body py-0 banner-section7-text2">
                            {
                              <p dangerouslySetInnerHTML={{ __html: faq.description }}></p>
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  ) :
                    (
                      <div className='d-flex align-items-center gap-2' >
                        Loading
                      </div>
                    )
                }

              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer address={walletAddress} />
      </section>
    </div>
  )
}

export default Home
