import "../../Assets/Css/dashboard-style.css";


import { AiOutlineMenu } from "react-icons/ai";
import Metalogo from "../../Assets/images/images-dash/meta-king-logo.png";
import { RxCross2 } from "react-icons/rx";
import React, { useEffect, useState } from "react";

import dlogo from "../../Assets/images/dlogo.webp";

import $, { error } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../../config/toastApi';
import config from '../../config/apiConfig';
import Window_Ethereum_Config_Api from '../../config/windowEthereumConfig';
import DUSDtokenAbi from '../../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import DarkLight from "./Dark-Light";
import black from "../../Assets/images/Asset 2.png";
import white from "../../Assets/images/Asset 1.png";
import { FaRankingStar } from "react-icons/fa6";
import { SiLevelsdotfyi } from "react-icons/si";


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Careerform from "./Careerform";

import bani from "../../Assets/images/bani.png"



function Topnav() {

  const [connectedWallet, setConnectedWallet] = useState(null);
  const [isNetworkToastShown, setIsNetworkToastShown] = useState(false);
  const [previousId, setPreviousId] = useState();
  const [locationState, setLocationState] = useState();
  const toastCheck = React.useRef(null);
  const [isload, setIsload] = useState(false)

  const locateUserPathname = window.location.pathname.split('/')[1];

  const findLocalLevel = window.location.search.split('=')[2];
  const matchingLevelIncomeLocation = config.WINDOW_LOCATION_LEVEL_INCOME_PAGE.split('/')[1];
  const matchingDashboardLocation = config.WINDOW_LOCATION_DASHBOARD_PAGE.split('/')[1];
  const matchingDmlmxCore = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE.split('/')[1];
  const matchingDmlmXcoreInner = config.WINDOW_LOCATION_DMLM_XCORE_INNER.split('/')[1];
  const matchingRankingPage = config.WINDOW_LOCATION_RANKINCOME_ID.split('/')[1];
  const matchinginstructionsPage = config.WINDOW_LOCATION_INSTRUCTIONS.split('/')[1];



  var web3js = '';

  if (window.ethereum) {
    // web3js = new Web3(window.ethereum);
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }
  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);


  const handleOnchangeEvent = (e) => {
    const inputValue = e.target.value;

    // Check if the input value is numeric using a regular expression
    if (/^\d*$/.test(inputValue)) {
      setPreviousId(e.target.value);
    } else {
      // If the input value is not numeric, show an alert
      toast.warning(ToastApi.EnterNumberOnly);
    }
  };

  const locateUserId = window.location.pathname.split('/')[2];




  const searchUsersBasedOnId = async (event) => {
    try {
      event.preventDefault();
      setIsload(true);
      const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(previousId).call();
      const checkUserRegisteredAddress = await DemoCorePlanContract.methods.isUserExists(checkUserRegisteredExists).call();
      if (checkUserRegisteredAddress) {

        if (locateUserPathname === matchingRankingPage) {
          window.location.href = config.WINDOW_LOCATION_RANKINCOME_ID + previousId;
          setIsload(false);

        } else if (locateUserPathname === matchingLevelIncomeLocation) {
          window.location.href = config.WINDOW_LOCATION_LEVEL_INCOME_PAGE + previousId;
          setIsload(false);

        } else if (locateUserPathname === matchingDashboardLocation) {
          window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + previousId;
          setIsload(false);

        } else if (locateUserPathname === matchingDmlmxCore) {
          window.location.href = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + previousId;
          setIsload(false);

        } else if (locateUserPathname === matchinginstructionsPage) {
          window.location.href = config.WINDOW_LOCATION_INSTRUCTIONS + '/' + previousId;
          setIsload(false);

        }
        else if (locateUserPathname === matchingDmlmXcoreInner.split('?')[0]) {
          if (findLocalLevel) {
            window.location.href = config.WINDOW_LOCATION_DMLM_XCORE_INNER + previousId + config.WINDOW_LOCATION_LEVEL + findLocalLevel;
            setIsload(false);

          }

        }
      } else {
        toast.error(ToastApi.UserNotFound)
        setIsload(false);
      }


    } catch (error) {
      setIsload(false);
    }

  };

  const handleNetworkChange = async (chainId) => {
    try {
      if (chainId === config.DCXCHAIN_HEX_ID) {
        // await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
        // checkConnectedWallet();
        setIsNetworkToastShown(false); // Reset the flag when the network is correct
      }
    } catch (error) {
      console.error('Error handling network change:', error);
    }
  };



  const addCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      else {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
    }
  };


  const checkConnectedWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
        setConnectedWallet(accounts?.[0] || null);
      }
      else {
        console.log("Metamask not install");
      }
    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
          checkConnectedWallet();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);
      }
    } catch (error) {
      toast.error(ToastApi.WalletConnectionError);
    }
  };

  const handleInstallMetaMask = async (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus()
    }
  };

  useEffect(() => {
    checkConnectedWallet();
    const locateUserId = window.location.pathname.split('/')[2];
    setPreviousId(locateUserId);

    // searchUsersBasedOnId()
    if (window.ethereum) {

      window.ethereum.on(Window_Ethereum_Config_Api.chainChanged, async (chainId) => {
        try {
          if (chainId == config.DCXCHAIN_HEX_ID) {
            // await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
            // await checkConnectedWallet();
          } else {
            handleNetworkChange(config.DCXCHAIN_HEX_ID);
            if (!isNetworkToastShown) {
              if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.error(ToastApi.NetworkChangeToast);
              // toast.error(ToastApi.NetworkChangeToast);
              setIsNetworkToastShown(true);
            }
          }
        } catch (error) {
          console.error('Error setting up event listener:', error);
        }
      });

    }

    const eventAccountWatch = async () => {

      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });


        if (accounts.length == 1) {
          setConnectedWallet(accounts[0]);

        } else if (accounts.length > 1) {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn(ToastApi.ConnectAnyOneAccount);
        } else if (accounts.length == 0) {
          if (!toast.isActive(toastCheck.current)) {
            toastCheck.current = toast.warning(ToastApi.ConnectYourWallet, { autoClose: 1300 })
          }
        }
      }
      else {
        if (isMobileDevice()) {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn("connect mobile metamask");
        } else {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn(ToastApi.PleaseInstallMetamask);
        }
      }
      // else if (accounts.length == 0) {
      //   toast.warning(ToastApi.ConnectYourWallet);
      // }
    }
    eventAccountWatch();

    protecturl()
  }, [connectedWallet]);

  const sliceAddress = (getTheConnectedWalletAddress, startLength = 6, endLength = 6) => {
    try {
      if (!getTheConnectedWalletAddress) return '';

      const prefix = getTheConnectedWalletAddress.slice(0, startLength);
      const suffix = getTheConnectedWalletAddress.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };

  const protecturl = async () => {
    try {
      const locateUserId = window.location.pathname.split('/')[2];

      if (locateUserId === null || locateUserId === undefined) {


        const xcoreuserid = window.location.href.split('=')[1].split('&')[0];
        const levelcheck = window.location.href.split('=')[2].split('&')[0];

        const checkUserRegisteredIdExists = await DemoCorePlanContract.methods.idToAddress(xcoreuserid).call();
        const FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredIdExists, levelcheck).call();

        const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(xcoreuserid).call();
        const checkUserRegisteredAddress = await DemoCorePlanContract.methods.isUserExists(checkUserRegisteredExists).call();



        if (!checkUserRegisteredAddress) {
          window.location.href = "/login";
        }

        if (FirstLevelStatus === false) {
          window.location.href = "/login";
        }

      } else {
        const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
        const checkUserRegisteredAddress = await DemoCorePlanContract.methods.isUserExists(checkUserRegisteredExists).call();

        if (!checkUserRegisteredAddress) {
          window.location.pathname = "/login";
        }

      }
      // const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
      // const checkUserRegisteredAddress = await DemoCorePlanContract.methods.isUserExists(checkUserRegisteredExists).call();


      // if (!checkUserRegisteredAddress) {
      //   window.location.pathname = "/login";
      // }
    } catch (error) {

    }

  }

  const shiftPages = () => {
    const condition = window.location.pathname.split('/')[1];
    if (condition == "dmlmxcoreinner") {
      const locateUserId = window.location.search.split("=")[1].split('&')[0];

      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + locateUserId;

      setLocationState(locateUserId)
    } else if (condition == 'instructions') {
      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + 1;
    } else {
      const locateUserId = window.location.pathname.split('/')[2];

      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + locateUserId;

      setLocationState(locateUserId)

    }
  }

  const mobileshiftPages = async (path) => {
    try {

      if (path === "Xcore") {
        const locateUserId = window.location.pathname.split('/')[2];

        if (locateUserId) {
          window.location.href = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + locateUserId
        } else {
          const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
          window.location.href = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + locateUserId1;
        }
      }
      else if (path === "LevelIncome") {
        const locateUserId = window.location.pathname.split('/')[2];

        if (locateUserId) {
          window.location.href = config.WINDOW_LOCATION_LEVEL_INCOME_PAGE + locateUserId;
        } else {
          const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
          window.location.href = config.WINDOW_LOCATION_LEVEL_INCOME_PAGE + locateUserId1;
        }

      } else if (path === "RankIncome") {
        const locateUserId = window.location.pathname.split('/')[2];

        if (locateUserId) {
          window.location.href = config.WINDOW_LOCATION_RANKINCOME_ID + locateUserId;
        } else {
          const locateUserId1 = window.location.search.split("=")[1].split('&')[0];
          window.location.href = config.WINDOW_LOCATION_RANKINCOME_ID + locateUserId1;
        }
      } else {
        console.log("path unable to work");
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const handleDesktopConnectClick = () => {
    try {
      connectWallet()
    } catch (error) {
      console.log('error', error)
    }
  }

  const mobilewalletConnect = async () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      try {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          if (accounts && accounts.length > 0) {
            const address = accounts[0];
            localStorage.setItem("address", accounts[0]);
            setConnectedWallet(accounts[0]);

          } else {
            console.log("Account request failed");
          }
          window.location.reload()
        }
      } catch (error) {
        console.error("Failed to request accounts:", error);
      }
    }
    // else {
    //     toast.warn("Please install metamask");
    //     openInNewTab(
    //       `https://metamask.io/download/`
    //     );
    //   }
  };

  const handleMobileConnectClick = async () => {

    try {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        mobilewalletConnect();
        return;
      }
      else {
        // const confirmed = window.confirm(
        //   "Connect your MetaMask wallet to this app?"
        // );

        let dappUrl = `${config.FRONTEND_URL}`;
        let metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
        window.location.href = metamaskAppDeepLink;
      }

    } catch (error) {
      console.log("error", error);
    }

  };

  const DailyClaimHandle = async () => {
    try {
      if (!connectedWallet) {
        toast.error(ToastApi.walletConnectText);
        return;
      }

      const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(connectedWallet).call();
      if (checkUserRegisteredExists) {
        const checkRegisteredIds = await DemoCorePlanContract.methods.users(connectedWallet).call();
        console.log(typeof checkRegisteredIds.id.toString())
        window.location.href = "/dailyclaim"
      } else {
        toast.warn("Register to get claim code!")
        setTimeout(() => {
          window.location.href = config.WINDOW_LOCATION_REGISTRATION_ID
        }, 4000);
      }

    } catch (error) {
      console.log('DailyClaimHandle Error:', error)
    }
  }

  return (
    <div>
      <section className="dashboard-navbar-section">
        <ToastContainer />
        <nav className="navbar navbar-expand-lg fixed-top pt-0">
          <div className="container py-2 px-3 align-items-center">
            <a className="navbar-brand-dashboard pb-lg-0 pt-lg-2" href="/">
              <img className="banner-img1 black-logo" src={black} alt="logo" />
              <img className="banner-img1 white-logo" src={white} alt="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              {/* <AiOutlineMenu /> */}
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto ms-lg-4">
                <li className="nav-item">
                  <p className="nav-link mb-0 dashboard-text-1">Preview ID</p>
                </li>
                <li className="nav-item">
                  <form className="d-flex" role="search">
                    <input
                      className="form-control me-2 navbar-preview-id-search"
                      type="search"
                      aria-label="Search"
                      onChange={handleOnchangeEvent}
                      value={previousId}
                    />
                    {isload
                      ? (<button
                        className="btn btn-dashboard-preview-search"
                        type="submit" >
                        <div class="spinner-grow text-light" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>)
                      : (<button
                        className="btn btn-dashboard-preview-search"
                        type="submit" onClick={searchUsersBasedOnId} >
                        GO
                      </button>)}
                    {/* <button
                      className="btn btn-dashboard-preview-search"
                      type="submit" onClick={searchUsersBasedOnId} >
                      GO
                    </button> */}
                  </form>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto align-items-lg-center">
                {/* <li className="nav-item me-lg-3">
                  <button className="nav-link dashboard-connect-wallet-btn">
                    Connect Wallet
                  </button>
                </li> */}

                <li className="mt-2 mt-lg-0">
                  <a onClick={DailyClaimHandle} className="btn btn-dashboard-preview-search d-flex align-items-center gap-1">
                    <div><img src={bani} className="bani-app" alt='bani'/></div>
                    <div>$BANI Claim</div>
                  </a></li>
                <li className="nav-item mx-lg-3 mt-3 mt-lg-0">
                  <Careerform address={connectedWallet} />
                </li>
                <li className="mt-3 mt-lg-0">
                  <DarkLight />
                </li>
                <li className="nav-item mx-lg-3">




                  {connectedWallet ? (
                    <button className="nav-link dashboard-connect-wallet-btn" type="submit">
                      {sliceAddress(connectedWallet)}
                    </button>
                  ) : (
                    <button
                      className="nav-link dashboard-connect-wallet-btn"
                      type="submit"
                      onClick={() => {
                        isMobileDevice() ? handleMobileConnectClick() : handleDesktopConnectClick()
                      }}
                    >
                      Connect Wallet
                    </button>
                  )}
                </li>
                <li className="nav-item me-lg-3">
                  <a href="/login">
                    <button className="nav-link dashboard-close-btn" >
                      <RxCross2 className="d-lg-block d-none" />
                      <span className="d-lg-none d-block">Exit Preview Node</span>
                    </button>
                  </a>
                </li>
              </ul>

              <ul className="navbar-nav ms-auto d-lg-none d-block mt-3">
                <div className="col-lg-2 dashboard-split-left-section">
                  <ul className="navbar-nav">
                    <li className="sidenavbar-nav-link sidenavbar-active">
                      <a className="" onClick={shiftPages} >
                        <div className="d-flex flex-row align-items-center">
                          <div className="sidenav-icon-1">
                            <i className="bi bi-grid-1x2-fill"></i>
                          </div>
                          <div>
                            <span className="sidenav-text-1">Dashboard</span>
                          </div>
                        </div>
                      </a>
                    </li>

                    <li className="sidenavbar-nav-link-2">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button accordion-button-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="sidenav-icon-1">
                                  <i className="fa-sharp fa-solid fa-people-group"></i>
                                </div>
                                <div>
                                  <span className="sidenav-text-1">Programs</span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul className="navbar-nav">
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" onClick={() => mobileshiftPages("Xcore")} >
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-user-group"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Xcore
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" onClick={() => mobileshiftPages("LevelIncome")}>
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <SiLevelsdotfyi className='mb-1' />
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Level Income
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" onClick={() => mobileshiftPages("RankIncome")}>
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <FaRankingStar className='mb-1' />
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Rank Income
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="sidenavbar-nav-link-2">
                      <div className="accordion" id="accordionExample2">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed accordion-button-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="sidenav-icon-1">
                                  <i className="fa-solid fa-book-open"></i>
                                </div>
                                <div>
                                  <span className="sidenav-text-1">
                                    Information
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              <ul className="navbar-nav">
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/instructions/1">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-shield-halved"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Instruction
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/calculator/1">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-calculator"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Calculator
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
}

export default Topnav;
