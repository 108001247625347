import React, { useEffect, useState } from 'react'
import Style from "../Assets/Css/Style.css";

import dlogo from "../Assets/images/dlogo.webp";

import { LiaShareSquare } from "react-icons/lia";
import { BiErrorCircle, BiLogOut } from "react-icons/bi";
import { BsPlayFill } from "react-icons/bs";
import { AiFillMessage } from "react-icons/ai";
import { userRegister, RegcheckWhitelist, showUserTransactionInDashBoard, regwithUsdt } from "../Dashboard-pages/api/authapis";
import $ from 'jquery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import DarkLight from '../Dashboard-pages/Separate/Dark-Light';
import black from "../Assets/images/Asset 2.png";
import white from "../Assets/images/Asset 1.png";
import ModalReg from './ModelPopup';
import ModalProcess from "../Dashboard-pages/Modal";
import { FaCopy } from 'react-icons/fa';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import dusd from "../Assets/images/dusd.png";
import usdt from "../Assets/images/USDT.png";
import USDTtokenAbi from "../ABI/BSCusdttoken.json"

function Registration() {

  const [connectedWallet, setConnectedWallet] = useState(null);
  const [getReferralIdToRegister, setGetReferralIdToRegister] = useState();
  const [isNetworkToastShown, setIsNetworkToastShown] = useState(false);
  const toastCheck = React.useRef(null);
  const [isloading, setIsloading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [open, setOpen] = useState(false);
  const [modalshow, setmodalshow] = useState(false)
  const [balerrMsg, setBalerrMsg] = useState(null)
  const [chainid, setchainid] = useState(null);
  const [chainshow, setChainShow] = useState(false);


  var web3js = '';

  if (window.ethereum) {
    web3js = new Web3(window.ethereum);
    // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }
  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);

  // const webBsc = new Web3(new Web3.providers.HttpProvider(config.BSCRPC_URL));
  // const USDT_BSC_Contract_address = new webBsc.eth.Contract(USDTtokenAbi, config.Usdt_contract)


  const handleNetworkChange = async (chainId) => {
    try {
      if (chainId === config.DCXCHAIN_HEX_ID) {
        await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
        checkConnectedWallet();
        setIsNetworkToastShown(false); // Reset the flag when the network is correct
      }
    } catch (error) {
      console.error('Error handling network change:', error);
    }
  };

  const addCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(`D-chain network added successfully`,{ autoClose: 1300 });
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(`D-chain network added successfully`);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      else {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(`D-chain network added successfully`);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
    }
  };

  const addBscCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.BSCCHAIN_HEX_ID }]
      });
      toast.success(`BNB network added successfully`,{ autoClose: 1300 });
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.BSCCHAIN_HEX_ID,
                  chainName: config.BSC_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.BSC_NETWORK_NAME,
                    symbol: config.BSC_SYMBOL,
                    decimals: config.BSC_DECIMAL,
                  },
                  rpcUrls: [config.BSCRPC_URL],
                  blockExplorerUrls: [config.BSC_EXPLORER_TXHASH_URL],
                },
              ],
            });
            toast.success(`BNB network added successfully`);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      else {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.BSCCHAIN_HEX_ID,
                  chainName: config.BSC_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.BSC_NETWORK_NAME,
                    symbol: config.BSC_SYMBOL,
                    decimals: config.BSC_DECIMAL,
                  },
                  rpcUrls: [config.BSCRPC_URL],
                  blockExplorerUrls: [config.BSC_EXPLORER_TXHASH_URL],
                },
              ],
            });
            toast.success(`BNB network added successfully`);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
    }
  };

  const checkConnectedWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method:  'eth_requestAccounts' });
        console.log("accounts",accounts);
        
        setConnectedWallet(accounts?.[0] || null);
      }
    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const connectWallet = async () => {
    try {

      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log('chainId', chainId,"config.DCXCHAIN_HEX_ID",config.DCXCHAIN_HEX_ID)
        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          // const acc = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
          checkConnectedWallet();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);

      }
    } catch (error) {
      console.log("Register connect Error:",error);
      toast.error(ToastApi.WalletConnectionError);
    }
  };

  const handleInstallMetaMask = async (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus()
    }
  };

  const sliceAddress = (address, startLength = 6, endLength = 6) => {
    try {
      if (!address) return '';

      const prefix = address.slice(0, startLength);
      const suffix = address.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };

  const switchNetwork = async () => {
    try {
      // Switch network
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
      });
    } catch (error) {

    }
  }

  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const handleDesktopConnectClick = () => {
    try {
      connectWallet()
    } catch (error) {
      console.log('error', error)
    }
  }

  const mobilewalletConnect = async () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      try {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          if (accounts && accounts.length > 0) {
            const address = accounts[0];
            localStorage.setItem("address", accounts[0]);
            setConnectedWallet(accounts[0]);

          } else {
            console.log("Account request failed");
          }
          window.location.reload()
        }
      } catch (error) {
        console.error("Failed to request accounts:", error);
      }
    }
    // else {
    //     toast.warn("Please install metamask");
    //     openInNewTab(
    //       `https://metamask.io/download/`
    //     );
    //   }
  };

  const handleMobileConnectClick = async () => {

    try {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        mobilewalletConnect();
        return;
      }
      else {
        // const confirmed = window.confirm(
        //   "Connect your MetaMask wallet to this app?"
        // );

        let dappUrl = `${config.FRONTEND_URL}`;
        let metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
        window.location.href = metamaskAppDeepLink;

      }

    } catch (error) {
      console.log("error", error);
    }

  };

  const handleClose = () => {
    setOpen(false);
    setIsloading(false)
  };


  const userRegisterDetails = async () => {
    try {
      const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

      if (chainId !== config.DCXCHAIN_HEX_ID) {
        return switchNetwork()
      }
      if (!connectedWallet) {
        // Show a toast when the wallet is not connected
        toast.error(ToastApi.walletConnectText);
        return;
      }

      if (!getReferralIdToRegister) {
        toast.error(ToastApi.registerInputRequired);
        return
      }

      if (connectedWallet) {
        const resultOfRegisterData = await showUserTransactionInDashBoard();

        if (resultOfRegisterData.status === false) {
          toast.error("Something went wrong! please try again later");
          return;
        }
      }

      if (connectedWallet) {
        const WalletBalanceAmt = await DUSD_Contract_address.methods.balanceOf(connectedWallet).call();
        const balDusd = await web3js.utils.fromWei(WalletBalanceAmt, "ether")

        if (Number(balDusd) < 15) {
          setBalerrMsg(`insufficient DUSD balance`)
          toast.warn("insufficient DUSD balance");
          return;
        } else {
          setBalerrMsg("")
        }
      }

      const referralId = parseInt(getReferralIdToRegister, 10);

      const data = {
        referralId,
        connectedWallet
      };
      let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(connectedWallet);

      // Check if the conversion is successful and it's a valid number
      if (!isNaN(referralId)) {
        try {
          setIsloading(true)

          // Check if the user is already registered
          const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(connectedWallet).call();

          if (checkUserRegisteredExists) {
            toast.error(ToastApi.UserAlreadyRegistered);
            setIsloading(false)
            setmodalshow(false)
            setTimeout(() => {
              window.location.href = "/login"
            }, 2000);
          } else {
            // Fetch the upline referral address
            const getUplineReferralAddressByID = await DemoCorePlanContract.methods.idToAddress(getReferralIdToRegister).call();

            const registrationToastErrorMessage = config.REGISTRATION_ZERO_ADDRESS;

            if (getUplineReferralAddressByID === registrationToastErrorMessage) {
              toast.warning(ToastApi.ReferralIdNotFound);
              setIsloading(false)
              setmodalshow(false)
            } else {

              const ResponseCheck = await RegcheckWhitelist({ address: checkSumMetamaskAddress })

              if (ResponseCheck.status) {
                if (checkSumMetamaskAddress === ResponseCheck.result.UserAddress) {

                  setOpen(true)
                  return
                }
              }
              setmodalshow(true)

              const gasPriceWei = web3js.utils.toWei('4', 'gwei'); // Adjust the gas price as needed
              // Check the allowance
              const allowance = await DUSD_Contract_address.methods.allowance(checkSumMetamaskAddress, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS).call();
              const registerationFeeAmount = await web3js.utils.toWei("15", "ether");
              const approvedAmountString = allowance.toString();
              let allowancefromwei = await web3js.utils.fromWei(allowance, 'ether')

              console.log('allowance', allowance, "allowancefromwei", allowancefromwei)
              if (Number(allowancefromwei) >= 15) {
                // User already approved, proceed with registration
                const alreadyApprovedContract = await DemoCorePlanContract.methods.registrationFor(checkSumMetamaskAddress, getUplineReferralAddressByID)
                  .send({
                    from: checkSumMetamaskAddress,
                    gasPrice: gasPriceWei,
                    nonce: await web3js.eth.getTransactionCount(checkSumMetamaskAddress),
                  });


                if (alreadyApprovedContract.transactionHash && alreadyApprovedContract.status == 1n) {

                  const txHash = alreadyApprovedContract.transactionHash;
                  const status = alreadyApprovedContract.status;
                  const returnContractValues = alreadyApprovedContract.events.Registration.returnValues;
                  const registeredAmount = alreadyApprovedContract.events.rewardpercentage.returnValues;
                  const xCoreAmount = alreadyApprovedContract.events.xcoreuserincome.returnValues;
                  const xcoresplit = alreadyApprovedContract.events.xcoresplit.returnValues;
                  const requestBody = {
                    txHash: alreadyApprovedContract.transactionHash,
                    status: alreadyApprovedContract.status,
                    returnContractValues: alreadyApprovedContract.events.Registration.returnValues,
                    registeredAmount: alreadyApprovedContract.events.rewardpercentage.returnValues,
                    xCoreAmount: alreadyApprovedContract.events.xcoreuserincome.returnValues,
                    xcoresplit: alreadyApprovedContract.events.xcoresplit.returnValues,
                    alreadyApprovedContract: alreadyApprovedContract // If you need the entire object
                  };

                  const registerDataSave = await userRegister(requestBody);

                  if (registerDataSave) {
                    setmodalshow(false)
                    toast.success(ToastApi.SuccessfullyRegistered);
                    setTimeout(() => {
                      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + returnContractValues.userId;
                      setIsloading(false)
                    }, 2000);
                  }
                  else {
                    setIsloading(false)
                    setmodalshow(false)
                  }

                } else {
                  toast.error(ToastApi.RegistrationErrorToast);
                  setIsloading(false)
                  setmodalshow(false)
                }


              } else if (Number(allowancefromwei) < 15) {
                // Approve the required amount
                const ApproveResult = await DUSD_Contract_address.methods.approve(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS, registerationFeeAmount)
                  .send({
                    from: checkSumMetamaskAddress,
                    gasPrice: gasPriceWei,
                  });
                console.log('ApproveResult---', ApproveResult);
                if (ApproveResult.transactionHash && ApproveResult.status == true) {
                  // Proceed with registration after approval
                  const registerTheUserAddress = await DemoCorePlanContract.methods.registrationFor(checkSumMetamaskAddress, getUplineReferralAddressByID)
                    .send({
                      from: checkSumMetamaskAddress,
                      gasPrice: gasPriceWei,
                      nonce: await web3js.eth.getTransactionCount(checkSumMetamaskAddress),
                    });


                  if (registerTheUserAddress.transactionHash && registerTheUserAddress.status == 1n) {
                    const txHash = registerTheUserAddress.transactionHash;
                    const status = registerTheUserAddress.status;
                    const returnContractValues = registerTheUserAddress.events.Registration.returnValues;
                    const registeredAmount = registerTheUserAddress.events.rewardpercentage.returnValues;
                    const xCoreAmount = registerTheUserAddress.events.xcoreuserincome.returnValues;
                    const getTheAllValuesFromRegistrationData = {
                      txHash,
                      status,
                      returnContractValues,
                      registeredAmount,
                      xCoreAmount,
                      xcoresplit: registerTheUserAddress.events.xcoresplit.returnValues,
                      alreadyApprovedContract: registerTheUserAddress
                    }
                    const registerDataSave = await userRegister(getTheAllValuesFromRegistrationData);
                    if (registerDataSave) {
                      setmodalshow(false)
                      toast.success(ToastApi.SuccessfullyRegistered);
                      setTimeout(() => {
                        window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + returnContractValues.userId;
                        setIsloading(false)
                      }, 2000);
                      // setTimeout(() => {
                      //   clearInterval(intervalId);
                      // }, 30000);
                    } else {
                      setIsloading(false)
                      setmodalshow(false)
                    }


                  }
                } else {
                  console.log('register send transactions error ')
                }
              }
            }
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error(ToastApi.SomeThingWentWorng);
          setIsloading(false)
          setmodalshow(false)
        }
      } else {
        console.error('Invalid number');
        setIsloading(false)
        setmodalshow(false)
      }
    } catch (error) {
      // Handle the outer catch block, if needed
      console.log('UserRegister Error:', error)
      setIsloading(false)
      setmodalshow(false)
    }
  };

  const userRegisterBsc = async () => {
    try {

      let webBsc;
      if (window.ethereum) {
        webBsc = new Web3(window.ethereum);
      } else {
        webBsc = new Web3(new Web3.providers.HttpProvider(config.BSCRPC_URL));
      }

      const USDT_BSC_Contract_address = new webBsc.eth.Contract(USDTtokenAbi, config.Usdt_contract)

      if (connectedWallet) {
        const WalletBalanceAmt = await USDT_BSC_Contract_address.methods.balanceOf(connectedWallet).call();
        const balDusd = await web3js.utils.fromWei(WalletBalanceAmt, "ether")

        if (Number(balDusd) < 15) {
          toast.warn("insufficient USDT balance");
          setBalerrMsg(`insufficient USDT balance`);
          return;
        }
      }

      if (connectedWallet) {
        const resultOfRegisterData = await showUserTransactionInDashBoard();

        if (resultOfRegisterData.status === false) {
          toast.error("Something went wrong! please try again later");
          return;
        }
      }

      const referralId = parseInt(getReferralIdToRegister, 10);
      let checkSumMetamaskAddress = web3js.utils.toChecksumAddress(connectedWallet);

      if (!isNaN(referralId)) {
        setIsloading(true)
        let web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
        const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
        const checkUserRegisteredExists = await DemoCorePlanContract.methods.isUserExists(connectedWallet).call();

        if (checkUserRegisteredExists) {
          toast.error(ToastApi.UserAlreadyRegistered);
          setmodalshow(false)
          setIsloading(false)
          setTimeout(() => {
            window.location.href = "/login"
          }, 2000);
        } else {

          const getUplineReferralAddressByID = await DemoCorePlanContract.methods.idToAddress(getReferralIdToRegister).call();

          const registrationToastErrorMessage = config.REGISTRATION_ZERO_ADDRESS;
          if (getUplineReferralAddressByID === registrationToastErrorMessage) {
            toast.warning(ToastApi.ReferralIdNotFound);
            setmodalshow(false)
            setIsloading(false)
          } else {

            const ResponseCheck = await RegcheckWhitelist({ address: checkSumMetamaskAddress })
            if (ResponseCheck.status) {
              if (checkSumMetamaskAddress === ResponseCheck.result.UserAddress) {

                setOpen(true)
                return
              }
            }

            setmodalshow(true)

            const gasPriceWei = webBsc.utils.toWei('4', 'gwei');
            const uplineAdminAddress = await DemoCorePlanContract.methods.idToAddress("1").call();
            const buyAmount = webBsc.utils.toWei("15", 'ether');
            console.log('buyAmount', buyAmount)
            const balance = await USDT_BSC_Contract_address.methods.balanceOf(connectedWallet).call();
            console.log('balance', balance)
            // const gasEstimate = await USDT_BSC_Contract_address.methods.transfer(uplineAdminAddress, buyAmount).estimateGas({ from: connectedWallet });
            const resultOfTransfer = await USDT_BSC_Contract_address.methods.transfer(config.ADMIN_WALLET_ADDRESS, buyAmount).send({
              from: connectedWallet,
              gasPrice: gasPriceWei,
              gas: 90000,
            });

            if (resultOfTransfer.status) {
              console.log('Transfer successful:', resultOfTransfer);

              const txReceipt = await webBsc.eth.getTransactionReceipt(resultOfTransfer.transactionHash);
              if (txReceipt && txReceipt.status) {
                console.log('Transaction confirmed and successful:', txReceipt);

                const sendJsonData = {
                  UserAddress: connectedWallet,
                  Referral_Address: getUplineReferralAddressByID,
                  Type: "BEP20 usdt",
                  Hash: resultOfTransfer.transactionHash
                }

                const Response = await regwithUsdt(sendJsonData);
                console.log("Response", Response);

                if (Response.status === true) {
                  setmodalshow(false)
                  setIsloading(false)
                  toast.success(ToastApi.SuccessfullyRegistered);
                  setTimeout(() => {
                    window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + Response.result.data.New_User_Referral_Id;
                    setIsloading(false)
                  }, 2000);
                } else {
                  setmodalshow(false)
                  setIsloading(false)
                }

              }
            } else {
              console.log('Transfer failed:', resultOfTransfer);
              toast.error('Transfer failed!')
              setIsloading(false)
            }

          }
        }
      }
    } catch (error) {
      console.log("userRegisterBsc Error:", error);
      setmodalshow(false)
      setIsloading(false)
      toast.error(ToastApi.SomeThingWentWorng);
    }
  }

  const handleInputChange = (event) => {
    if (event) {
      const valueNum = event.target.value.match(/\d*\.?\d*/)[0];

      if (!isNaN(valueNum) && event.target.value.trim() === valueNum) {
        setGetReferralIdToRegister(valueNum);
        setErrormsg("")
        setBalerrMsg("")
      } else {
        setErrormsg(ToastApi.registerInputRequired)
        setBalerrMsg("")
      }

    } else {
      // Handle the case when there is no event (initialization or other scenarios)
      const locationWithId = window.location.pathname.split('/')[2];
      if (locationWithId !== undefined) {
        setGetReferralIdToRegister(locationWithId);
      } else {
        // Set a default value or handle the case accordingly
        setGetReferralIdToRegister(''); // You can set a default value or leave it empty
      }
    }
  };

  useEffect(() => {
    handleInputChange(); // Call the function without an event to handle the initialization case
  }, []);

  const handleRegisterClick = async (e) => {
    try {
      e.preventDefault();
      if (!connectedWallet) {
        toast.error(ToastApi.walletConnectText);
        return;
      }

      if (!getReferralIdToRegister) {
        toast.error(ToastApi.registerInputRequired);
        return
      }

      if (connectedWallet) {
        const resultOfRegisterData = await showUserTransactionInDashBoard();

        if (resultOfRegisterData.status === false) {
          toast.error("Something went wrong! please try again later");
          return;
        }
      }

      setChainShow(true);
    } catch (error) {
      console.log('handleRegisterClick Error:', error);
    }
  };


  const SwitchNetworkSumit = async (chain) => {
    try {
      console.log('config.DCX_SYMBOL', config.DCX_SYMBOL)
      if (chain === config.DCX_SYMBOL) {
        // Dcx
        await addCustomNetwork();

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        console.log("chainId", chainId);
        setchainid(chainId)
      } else {
        // bsc  
        await addBscCustomNetwork();

        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        console.log("chainId", chainId);
        setchainid(chainId)
      }
    } catch (error) {
      console.log("SwitchNetworkSumit Error:", error)
    }
  }

  const handleModalConfirm = async (data) => {
    try {
      if (data === "DUSD") {
        setChainShow(false);

        await userRegisterDetails()
        setchainid(null)
      } else {
        setChainShow(false);

        await userRegisterBsc()
        setchainid(null)
      }
    } catch (error) {
      console.log("handleModalConfirm Error:", error);
      setchainid(null)
    }
  };

  useEffect(() => {

    // checkConnectedWallet();

    if (window.ethereum) {

      window.ethereum.on(Window_Ethereum_Config_Api.chainChanged, async (chainId) => {
        try {
          if (chainId == config.DCXCHAIN_HEX_ID) {
            await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
            await checkConnectedWallet();
          } else {
            handleNetworkChange(config.DCXCHAIN_HEX_ID);
            if (!isNetworkToastShown) {
              // if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.error(ToastApi.NetworkChangeToast);
              setIsNetworkToastShown(true);
            }
          }
        } catch (error) {
          console.error('Error setting up event listener:', error);
        }
      });

    }

    const eventAccountWatch = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });


        if (accounts.length == 1) {
          setConnectedWallet(accounts[0]);

        } else if (accounts.length >= 2) {
          if (!toast.isActive(toastCheck.current)) {
            toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount);
          }
        } else if (accounts.length == 0) {
          if (!toast.isActive(toastCheck.current)) toastCheck.current = toast.warning(ToastApi.ConnectYourWallet, { autoClose: 1300 });
        }
      }
      else {
        if (isMobileDevice()) {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn("connect mobile metamask");
        } else {
          if (!toast.isActive(toastCheck.current)) return toastCheck.current = toast.warn(ToastApi.PleaseInstallMetamask);
        }
      }
    }
    eventAccountWatch();
  }, [isNetworkToastShown]);

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(config.DUSD_TOKEN_ADDRESS)
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
    toast.success(ToastApi.CopiedAddress);
  };

  const AddDchain = async () => {
    try {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);

      }
    } catch (error) {
      toast.error(ToastApi.WalletConnectionError);
    }
  }


  const SwitchchainCloses = () => {
    setChainShow(false)
    setchainid(null)
  };
  const SwitchchainShow = () => setChainShow(true);

  return (
    <div className='d-ecosystem-registration'>
      <ToastContainer />
      <div className='register-section-one fixed-top'>
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <a class="navbar-brand pb-lg-0 pt-lg-2" href="/">
              <img className="banner-img1 black-logo" src={black} alt="logo" />
              <img className="banner-img1 white-logo" src={white} alt="logo" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">

              </ul>
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-lg-center">
                <li><DarkLight /></li>
                <li className='ms-lg-3 mt-2 mt-lg-0'>
                  {/* <form class="d-flex " role="search"> */}

                  {connectedWallet ? (
                    <div class="btn33 fs-6"> {sliceAddress(connectedWallet)}</div>
                  ) : (
                    <button class="btn33 fs-6" onClick={() => {
                      isMobileDevice() ? handleMobileConnectClick() : handleDesktopConnectClick()
                    }}>
                      Connect Wallet
                    </button>
                  )}
                </li>



                <li>
                  <a href='/'>
                    <button class="btn44 mx-lg-3 mt-2 mt-lg-0" ><LiaShareSquare className="opacity-50 text-center fs-5" /></button>
                  </a>
                </li>


                {/* </form> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div class="register-section-two gy-5">
        <div class="container">
          <div class="row my-5">
            <div class="col-lg-6 col-md-6  col-12">

              <div class="row">
                <div class="col-lg-12">
                  <div class="registerationchild0">
                    <h3 class="fs-1 registerationchild01">
                      Registration<br />
                      in PropelX DUSD</h3>
                  </div>
                </div>
              </div>
              <div class="row py-4">
                <form disabled={isloading} onSubmit={handleRegisterClick}>
                  <div class="col-lg-12">
                    <div class="banner10">
                      <h4 class="opacity-75 ms-2">To become a PropelX member:</h4>
                      <p class="opacity-75 ms-2" style={{ fontSize: "14px" }}>Enter ' 1 ' to use the default Upline ID for individuals without a referral.</p>
                      <input type="text" class="bannerinput p-3" placeholder='Referral ID' value={getReferralIdToRegister} onChange={handleInputChange} />
                    </div>
                    <span style={{ color: "red" }}>{errormsg}</span>
                    <span style={{ color: "red" }}>{balerrMsg}</span>
                  </div>

                  <div class="row py-3">
                    <div class="col-lg-12">
                      {isloading ?
                        (<button class="banner11bttn1 mt-2 px-5 py-2 text-white" disabled >
                          <div class="spinner-border" role="status">
                            <span class="sr-only" ></span>
                          </div>
                        </button>) : (
                          <button class="banner11bttn1 mt-2 px-4 py-3 text-white" type='submit'>Register Now</button>
                        )}
                    </div>

                  </div>
                </form>
                <ModalReg isOpen={open} onClose={handleClose} />
                <ModalProcess isOpens={modalshow} />
              </div>
              {/* <Button class="switch-btn mt-2 px-4 py-3 text-white" onClick={SwitchchainShow}>
                Register Now
              </Button> */}

              <Modal className='switchchain' show={chainshow} onHide={SwitchchainCloses} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                <Modal.Header closeButton className='border-0'>
                  <Modal.Title className=''></Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                  <h6 className='text-center'>Register Method</h6>

                  {chainid === null
                    ? (
                      <div className='d-flex align-items-center justify-content-center gap-lg-4 gap-2'>
                        <a onClick={() => SwitchNetworkSumit("DCX")}>
                          <div className='buy-btn'>
                            <div>Register with(DCX)</div>
                            <div><img src={dusd} alt='dusd' className='switch-coin' />DUSD</div>
                          </div>
                        </a>

                        <a onClick={() => SwitchNetworkSumit("BNB")}>
                          <div className='buy-btn'>
                            <div>Register with(BNB)</div>
                            <div><img src={usdt} alt='dusd' className='switch-coin' />USDT</div>
                          </div>
                        </a>
                      </div>
                    )
                    : chainid === "0x1498"
                      ? (
                        <>
                          <p>Are you sure you want to register with Referral <span className='dashboard-button-1'>ID {getReferralIdToRegister}</span> </p>
                          <button onClick={() => handleModalConfirm("DUSD")} className='buy-btn'><img src={dusd} alt='dusd' className='switch-coin' />Confirm DUSD</button>
                        </>
                      )
                      : (
                        <>
                          <p>Are you sure you want to register with Referral <span className='dashboard-button-1'>ID {getReferralIdToRegister}</span></p>
                          <button onClick={() => handleModalConfirm("USDT")} className='buy-btn'><img src={usdt} alt='usdt' className='switch-coin' />Confirm USDT</button>
                        </>
                      )}

                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center'>
                </Modal.Footer>
              </Modal>

            </div>
            <div class="col-lg-6  col-md-6 col-12">
              <div class="row">
                <div class="col-lg-9  d-flex registerationradius justify-content-end flex-column py-3 px-4">
                  <div class="row py-2">
                    <div class="col-lg-12 d-flex align-items-center">
                      <p class="fs-4 fw-bold"><BiErrorCircle className="me-1 fw-4" /></p>
                      <p class="fs-3 fw-bold">Information</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      {connectedWallet ? (
                        <p class="fs-6">Wallet Connected.</p>
                      ) : (
                        <p class="fs-6">Wallet not connected.</p>
                      )}

                      <p class="fs-6">Steps to register:</p>
                      <ul className='info-steps'>
                        <li className=''>Connect your wallet (MetaMask or D-Wallet extension).</li>
                        <li className=''>Ensure you have 15 DUSD to purchase a Level 1 Card. You can swap 15 USDT for 15 DUSD on the BNB Network via the D-ecosystem Salespad, or buy directly using your card through MetaMask's fiat payment system.</li>
                        <li className=''>Remember to purchase a small amount of $BNB and $DCX to cover the gas fees.</li>
                      </ul>
                      <p className='banner-texttwo'>
                        Purchase DUSD & DCX by clicking below
                      </p>
                      <p class="fs-6">
                        <div className='d-flex align-items-center  gap-3 flex-wrap'>
                          <div><a href={`${config.SALESPAD_DUSD_URL}`} target='_blank'><button className='info-button'>Buy DUSD</button></a></div>
                          <div><a href={`${config.SALESPAD_DUSD_URL}`} target='_blank'><button className='info-button'>Buy DCX</button></a></div>
                          <div><a ><button className='info-button' onClick={AddDchain}>Add D-Chain</button></a></div>
                        </div>
                      </p>
                      <div className='text-two'>
                        DUSD Contract Address :
                        <span className="">
                          <a
                            href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DUSD_TOKEN_ADDRESS}`}
                            target='_blank'
                            className='tutorial-link'
                            rel="noopener noreferrer"
                          >
                            {sliceAddress(config.DUSD_TOKEN_ADDRESS)}
                          </a>
                          <button onClick={handleCopy} className='bg-transparent border-0'><FaCopy className='tutorial-link ms-1' /> </button>
                        </span>
                      </div>
                      <div className='text-two'>
                        How to start PropelX Journey?
                        <a href='https://youtu.be/ZynHzHUb30g?feature=shared' target='_blank' className='tutorial-link'>Tutorial Video </a>
                      </div>
                      <p className='text-two'>
                        Contact Support :
                        <a href="mailto:info.propelx@gmail.com" className='tutorial-link'>
                          info.propelx@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>

                  {/* <div class="row m-auto">
                    <div class="col-lg-12 p-0">
                      <div class="card border-0 mt-4 bg-transparent">
                        <div class="cardimages position-relative">
                          <img src="https://img.youtube.com/vi/T6JO-HlcNpE/hqdefault.jpg" width="400" height="200" />
                        </div>
                        <div class="lastarrowicons position-absolute">
                          <BsPlayFill className="text-white" style={{ fontSize: "80px" }} />
                        </div>
                        <div class="cardiframe">
                          <iframe width="335" height="180" src="https://www.youtube.com/embed/T6JO-HlcNpE"
                            title="D-Chain - 4 steps with Mobile Device on DUSD" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                        </div>
                        <div class="card-body d-flex p-0 bg-transparent">
                          <h5 class="card-title me-3 mt-1"><AiFillMessage className="opacity-50" style={{ color: "grey" }} /></h5>
                          <p class="opacityt-50 mt-1" style={{ color: "grey" }}>Need help with registration?
                            Talk to experts in the support chat</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Registration;